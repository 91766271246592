import { Skeleton, Tag } from "@chakra-ui/react";
import TooltipTag from "../../../../Components/TooltipTag/TooltipTag";
import { useAegisVersionWithTenantVersion } from "../../../../hooks/devices.hooks";

export default function AegisVersionValue({
  deviceId,
  deviceName,
  tenantId,
  platform,
}: Readonly<{
  deviceId: string;
  deviceName: string;
  tenantId: string;
  platform: string;
}>) {
  const { isIdle, isFetching, isError, error, data } =
    useAegisVersionWithTenantVersion(tenantId, deviceId, deviceName, platform);

  let tagColor: string;
  if (!data?.deviceVersion) {
    tagColor = "red";
  } else if (!data?.tenantVersion) {
    tagColor = "gray";
  } else if (data.deviceVersion >= data.tenantVersion) {
    tagColor = "green";
  } else {
    tagColor = "yellow";
  }

  return (
    <Skeleton isLoaded={!isFetching && !isIdle}>
      {isError && (
        <TooltipTag
          tagText="Error"
          tooltipText={error.message}
          tagColorScheme="red"
        />
      )}
      {data && (
        <TooltipTag
          tagText={`${data.deviceVersion}`}
          tooltipText={`Tenant version: ${data.tenantVersion}`}
          tagColorScheme={tagColor}
        />
      )}
      {!data && !isError && <Tag colorScheme="red">Not found</Tag>}
    </Skeleton>
  );
}
