import { Box, Skeleton, HStack, Tag, Text, Tooltip } from "@chakra-ui/react";
import { useArcExpiration } from "../../../hooks/tenants.hooks";

export default function ArcSection({ tenantId }: { tenantId: string }) {
  const arcExpirationQuery = useArcExpiration(tenantId);
  const getArcColorScheme = () => {
    if (arcExpirationQuery.isError) return "red";
    if (!arcExpirationQuery.data) return "gray";
    const arcData = arcExpirationQuery.data;
    if (arcData.isExpired) return "red";
    const expDate = new Date(arcData.expirationDate);
    expDate.setDate(expDate.getDate() - 30);
    if (expDate.valueOf() - new Date().valueOf() <= 0) return "yellow";
    return "green";
  };
  return (
    <Box>
      <Text fontSize="xs" fontWeight="bold">
        Arc expiration date
      </Text>
      {!arcExpirationQuery.isError ? (
        <Skeleton isLoaded={!!arcExpirationQuery.data}>
          <HStack>
            <Tag colorScheme={getArcColorScheme()} size="sm">
              {(() => {
                let date = new Date();
                if (!!arcExpirationQuery.data) {
                  date = new Date(arcExpirationQuery.data.expirationDate);
                }
                return date.toDateString();
              })()}
            </Tag>
          </HStack>
        </Skeleton>
      ) : (
        <Tooltip label={arcExpirationQuery.error.message}>
          <Tag colorScheme={getArcColorScheme()} size="sm">
            Error
          </Tag>
        </Tooltip>
      )}
    </Box>
  );
}
