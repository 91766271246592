import {
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  FormControl,
  Input,
  Select,
  Center,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { useRegistryKeys } from "../../../../hooks/tenants.hooks";
import { updateRegistryKeys } from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  RegistryKey,
  RegKeyOperation,
  RegistryKeyValueType,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";

interface ISingleOperationFormProps {
  tenantId: string;
  onClose: () => void;
}

export default function SingleOperationForm({
  tenantId,
  onClose,
}: ISingleOperationFormProps) {
  const [operation, setOperation] = useState<string>("set");
  const [formData, setFormData] = useState<Partial<RegistryKey>>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { getInternalApiToken } = useMicrosoftToken();
  const { refetch } = useRegistryKeys(tenantId);

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsSubmitting(true);
    event.preventDefault();
    const opSpec: RegKeyOperation = {
      Operation: operation,
      ...formData,
    } as RegKeyOperation;
    const fullPath = (opSpec as any).Name
      ? `${opSpec.Path}\\${(opSpec as any).Name}`
      : opSpec.Path;
    const token = await getInternalApiToken();
    try {
      await updateRegistryKeys(token, tenantId, {
        [fullPath]: opSpec,
      });
      setFormData({});
      refetch();
      onClose();
    } catch (error) {
      alert((error as Error).message);
    }
    setIsSubmitting(false);
  };

  const handleOperationChange = (op: string) => {
    if (op !== "set") {
      // make sure to only keep the values the operation type needs so we dont
      // accidentally send a bad key operation with extra fields to automate
      const newFormData = { ...formData };
      newFormData.Type = undefined;
      newFormData.Value = undefined;
      if (op === "delete") {
        newFormData.Name = undefined;
      }
      setFormData(newFormData);
    }
    setOperation(op);
  };

  return (
    <>
      <FormLabel>Select operation</FormLabel>
      <RadioGroup onChange={handleOperationChange} value={operation}>
        <Stack direction="row">
          <Radio value="set">Set</Radio>
          <Radio value="delete">Delete</Radio>
          <Radio value="delete-value">Delete value</Radio>
        </Stack>
      </RadioGroup>
      {operation && (
        <form>
          <FormControl mt={2}>
            <FormLabel>Path</FormLabel>
            <Input
              id="Path"
              value={formData.Path || ""}
              placeholder="Enter the parent key path"
              onChange={(e) =>
                setFormData({ ...formData, Path: e.target.value })
              }
            />
            {operation !== "delete" && (
              <>
                <FormLabel>Name</FormLabel>
                <Input
                  id="Name"
                  value={formData.Name || ""}
                  placeholder="Enter the name of the key"
                  onChange={(e) =>
                    setFormData({ ...formData, Name: e.target.value })
                  }
                />
              </>
            )}
            {operation === "set" && (
              <>
                <FormLabel>Type</FormLabel>
                <Select
                  placeholder="Select value type"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      Type: e.target.value as RegistryKeyValueType,
                    })
                  }
                >
                  <option value="Binary">Binary</option>
                  <option value="DWord">DWord</option>
                  <option value="ExpandString">ExpandString</option>
                  <option value="MultiString">MultiString</option>
                  <option value="None">None</option>
                  <option value="QWord">QWord</option>
                  <option value="String">String</option>
                  <option value="Unknown">Unknown</option>
                </Select>
                <FormLabel>Value</FormLabel>
                <Input
                  id="Value"
                  value={formData.Value || ""}
                  placeholder="Enter the key value"
                  onChange={(e) =>
                    setFormData({ ...formData, Value: e.target.value })
                  }
                />
              </>
            )}
            {operation && (
              <Center mt={4}>
                <Button
                  colorScheme="blue"
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                >
                  Submit
                </Button>
              </Center>
            )}
          </FormControl>
        </form>
      )}
    </>
  );
}
