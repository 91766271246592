import { Button, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { pushMacConfigProfiles } from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";

interface IConfigProfileOperationsProps {
  tenantId: string;
}

export default function ConfigProfileOperations({
  tenantId,
}: IConfigProfileOperationsProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { getInternalApiToken } = useMicrosoftToken();

  const pushConfigProfiles = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const token = await getInternalApiToken();
      await pushMacConfigProfiles(token, tenantId);
      setIsLoading(false);
    } catch (error) {
      setError((error as Error).message || "an unknown error occurred");
      setIsLoading(false);
    }
  };

  return (
    <>
      <Flex marginY={2}>
        <Button
          colorScheme={"blue"}
          variant="outline"
          size="sm"
          isLoading={isLoading}
          onClick={pushConfigProfiles}
        >
          Upload config profiles
        </Button>
      </Flex>
      {error && <Text color="red">{error}</Text>}
    </>
  );
}
