import { Skeleton, Td, Tr } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";

function Row({ numCols }: Readonly<{ numCols: number }>) {
  return (
    <Tr>
      {[...Array(numCols)].map(() => (
        <Td key={`col-${uuidv4()}`}>
          <Skeleton height="1.5em" isLoaded={false} />
        </Td>
      ))}
    </Tr>
  );
}

export default function TableSkeleton({
  numCols,
  numRows,
}: Readonly<{
  numCols: number;
  numRows?: number;
}>) {
  return (
    <>
      {[...Array(numRows ?? 3)].map(() => (
        <Row key={`row-${uuidv4()}`} numCols={numCols} />
      ))}
    </>
  );
}
