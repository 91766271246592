import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { BsLock, BsUnlock, BsTrash } from "react-icons/bs";
import { useDevices } from "../../../../../hooks/devices.hooks";
import useMicrosoftToken from "../../../../../hooks/tokens.hooks";
import {
  toastSuccess,
  toastError,
} from "../../../../../Providers/ToastProvider";
import {
  enableDevice,
  disableDevice,
  deleteDevice,
} from "../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { Device } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import ConfirmationModal from "./ConfirmationModal";
import { DeviceAction } from "./device-header.types";

export default function DeviceActions({
  device,
  tenantId,
  onClose,
}: Readonly<{ device: Device; tenantId: string; onClose: () => void }>) {
  const { refetch } = useDevices(tenantId);
  const [isLoading, setIsLoading] = useState(false);
  const { getInternalApiToken } = useMicrosoftToken();
  const toast = useToast();
  const [action, setAction] = useState<DeviceAction | null>(null);
  const confirmModalDisclosure = useDisclosure();

  const handleUpdateDevice = useCallback(
    async (action: "enable" | "disable" | "delete") => {
      setIsLoading(true);
      try {
        const token = await getInternalApiToken();
        if (action === "enable") {
          await enableDevice(token, tenantId, device.id);
        } else if (action === "disable") {
          await disableDevice(token, tenantId, device.id);
        } else {
          await deleteDevice(token, tenantId, device.id);
        }
        refetch();
        toastSuccess(toast, `Successfully ${action}d device`);
      } catch (error) {
        console.error(error);
        toastError(toast, `Error ${action.slice(0, -1)}ing device`);
      }
      onClose();
    },
    [device.id, getInternalApiToken, onClose, refetch, tenantId, toast]
  );

  const handleButtonClick = (action: DeviceAction) => {
    setAction(action);
    confirmModalDisclosure.onOpen();
  };

  return (
    <Box>
      <ConfirmationModal
        action={action}
        disclosure={confirmModalDisclosure}
        onConfirm={handleUpdateDevice}
      />
      <Menu>
        <MenuButton
          as={Button}
          size="xs"
          variant="outline"
          colorScheme="blue"
          rightIcon={<ChevronDownIcon />}
          isLoading={isLoading}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            color="red"
            icon={device.accountEnabled ? <BsLock /> : <BsUnlock />}
            onClick={() =>
              handleButtonClick(device.accountEnabled ? "disable" : "enable")
            }
          >
            {device.accountEnabled ? "Disable" : "Enable"} device
          </MenuItem>
          <MenuItem
            color="red"
            icon={<BsTrash />}
            onClick={() => handleButtonClick("delete")}
          >
            Delete device
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
