import { DeviceAndTenantAegisVersions } from "../../types/types";
import TooltipTag from "../TooltipTag/TooltipTag";

export default function AegisVersionTag({
  versionInfo,
}: Readonly<{ versionInfo: DeviceAndTenantAegisVersions }>) {
  let tagColor: string;
  if (!versionInfo?.deviceVersion) {
    tagColor = "red";
  } else if (!versionInfo?.tenantVersion) {
    tagColor = "gray";
  } else if (versionInfo.deviceVersion >= versionInfo.tenantVersion) {
    tagColor = "green";
  } else {
    tagColor = "yellow";
  }

  return (
    <TooltipTag
      tagText={`${versionInfo.deviceVersion}`}
      tooltipText={`Tenant version: ${versionInfo.tenantVersion}`}
      tagColorScheme={tagColor}
    />
  );
}
