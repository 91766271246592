import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from "@chakra-ui/react";
import { ChangeManagementRow } from "../../services/atomus-internal-apis/atomus-internal-apis.types";

interface IConfirmModalProps {
  formData: ChangeManagementRow;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (formData: ChangeManagementRow) => void;
}

export default function GlobalChangeConfirmModal({
  formData,
  isModalOpen,
  setIsModalOpen,
  onSubmit,
}: IConfirmModalProps) {
  const handleConfirmation = () => {
    setIsModalOpen(false);
    onSubmit(formData);
  };
  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <ModalOverlay />
      <ModalContent alignItems="center">
        <ModalHeader>Are you sure you want to submit?</ModalHeader>
        <ModalBody color="red">
          This action is not reversible. If you want to edit or delete a change
          later, you will need to do it for each tenant by hand.
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleConfirmation}>
            Confirm
          </Button>
          <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
