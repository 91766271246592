import { Tag } from "@chakra-ui/react"

interface IInfoFieldProps {
  data: any
}

/**
 * @description simple function that returns data if it exists, otherwise a 
 * red tag with "unknown" as the text indicating data should exist
 * @param data data to render
 */
export default function InfoField ({ data }: IInfoFieldProps) {
  return (
    <>
      {typeof data === "number" && data}
      {data ? data : <Tag colorScheme="red">unknown</Tag>}
    </>
  )
}