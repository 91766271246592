import {
  Box,
  Button,
  HStack,
  Skeleton,
  Stat,
  StatLabel,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { JsonText } from "../../../../Components/Text/Text";
import { useTenantConfigValidation } from "../../../../hooks/tenants.hooks";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { getAppConfig } from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { AegisAppConfig } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import ConfigContentModal from "./ConfigContentModal";
import OptionalFeaturesSection from "./OptionalFeaturesSection/OptionalFeaturesSection";
import UploadDrawer from "./UploadDrawer";

interface IConfigOverviewProps {
  tenantId: string;
}

export default function ConfigOverview({
  tenantId,
}: Readonly<IConfigOverviewProps>) {
  const { getInternalApiToken } = useMicrosoftToken();
  const {
    isOpen: drawerIsOpen,
    onOpen: drawerOnOpen,
    onClose: drawerOnClose,
  } = useDisclosure();

  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  const tenantConfigQuery = useQuery<AegisAppConfig, Error>(
    [tenantId, "app-config"],
    async () => {
      const token = await getInternalApiToken();
      return await getAppConfig(token, tenantId);
    }
  );

  const configValidationQuery = useTenantConfigValidation(tenantId);

  const handleDownloadConfig = (fileName: string, data: AegisAppConfig) => {
    const elem = document.createElement("a");
    const fileData = new Blob([JSON.stringify(data, null, 4)]);
    elem.href = URL.createObjectURL(fileData);
    elem.download = fileName;
    document.body.appendChild(elem);
    elem.click();
  };

  return (
    <Box marginY={4}>
      <UploadDrawer
        tenantId={tenantId}
        isOpen={drawerIsOpen}
        onClose={drawerOnClose}
      />
      {tenantConfigQuery.data && !tenantConfigQuery.isError && (
        <ConfigContentModal
          configData={tenantConfigQuery.data}
          isOpen={modalIsOpen}
          onClose={modalOnClose}
        />
      )}
      <Text fontSize="lg" fontWeight="semibold" marginY={4}>
        App Config
      </Text>
      <HStack alignItems="flex-start" marginX={4}>
        <Stat>
          <StatLabel>Aegis Version</StatLabel>
          <Skeleton
            isLoaded={
              !tenantConfigQuery.isLoading && !tenantConfigQuery.isRefetching
            }
          >
            <Tag
              colorScheme={
                tenantConfigQuery.data?.AegisVersion ? "gray" : "red"
              }
            >
              {tenantConfigQuery.data?.AegisVersion || "unknown"}
            </Tag>
          </Skeleton>
        </Stat>
        <Stat>
          <StatLabel>Config is Valid</StatLabel>
          <Skeleton
            isLoaded={
              !configValidationQuery.isLoading &&
              !configValidationQuery.isRefetching
            }
          >
            <Tag
              colorScheme={
                configValidationQuery.data === null ? "green" : "red"
              }
            >
              {configValidationQuery.data === null ? "true" : "false"}
            </Tag>
          </Skeleton>
        </Stat>
        <OptionalFeaturesSection />
      </HStack>
      {configValidationQuery.data && (
        <Box margin={4}>
          <Text fontWeight="semibold">Config Errors</Text>
          <Text color="red">{configValidationQuery.data.message}</Text>
          {configValidationQuery.data.errors.map((err) => (
            <Box
              key={err.key}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="md"
              padding={2}
              marginY={2}
            >
              <Text fontWeight="bold">{err.key}</Text>
              <JsonText>{err.error}</JsonText>
            </Box>
          ))}
        </Box>
      )}
      <Box marginY={6} marginX={4}>
        <HStack mt={4} spacing={4} mb={4}>
          <Button
            colorScheme="blue"
            variant="outline"
            size="sm"
            onClick={modalOnOpen}
            isDisabled={!tenantConfigQuery.data || tenantConfigQuery.isError}
          >
            View tenant config
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            size="sm"
            onClick={() =>
              handleDownloadConfig(
                `aegis-${tenantId}.json`,
                tenantConfigQuery.data as AegisAppConfig
              )
            }
            isDisabled={!tenantConfigQuery.data || tenantConfigQuery.isError}
          >
            Download tenant config
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            size="sm"
            onClick={drawerOnOpen}
          >
            Upload app config
          </Button>
        </HStack>
      </Box>
    </Box>
  );
}
