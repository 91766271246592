import {
  Td,
  Tag,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Flex,
  Text,
  Spacer,
} from "@chakra-ui/react";
import { AegisUser } from "../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import CheckrSection from "./UserComplianceSections/Checkr";
import { useContext } from "react";
import { UserTableContext } from "../../../../UserTableContext";
import { useCheckrReportStatus } from "../../../../../../hooks/integrations.hooks";
import { useTenantId } from "../../../../../../hooks/utils.hooks";
import { CHECKR_COMPLETE } from "../UserActionsMenu/OnboardingEmail/constants";

export default function UserComplianceInfo({
  aegisUser,
}: Readonly<{
  aegisUser: AegisUser;
}>) {
  const { checkrConnectionStatus } = useContext(UserTableContext);
  const tenantId = useTenantId();
  const { data } = useCheckrReportStatus(tenantId, aegisUser.checkrId);
  let numTrainingsComplete = 0;
  let totalPossibleTrainings = 2;
  let trainingsDone = false;
  let handbookDone = false;
  for (const status of Object.values(aegisUser.trainingsComplete)) {
    if (status) {
      numTrainingsComplete++;
    }
  }
  if (aegisUser.trainingsComplete.dodAwareness) {
    totalPossibleTrainings = 3;
  }
  trainingsDone = numTrainingsComplete === totalPossibleTrainings;

  let handbookExpired = true;
  if (aegisUser.lastHandbookDate) {
    const lastHandbookDate = new Date(aegisUser.lastHandbookDate);
    const yearLater = lastHandbookDate.setFullYear(
      lastHandbookDate.getFullYear() + 1
    );
    if (yearLater.valueOf() > new Date().valueOf()) {
      handbookExpired = false;
    }
  }
  if (
    aegisUser.latestHandbookVersion &&
    aegisUser.latestHandbookVersion >= "v1.4" &&
    !handbookExpired
  ) {
    handbookDone = true;
  }

  const backGroundCheckCleared = () => {
    return (
      checkrConnectionStatus && data?.backgroundCheckStatus === CHECKR_COMPLETE
    );
  };

  const getTagColorScheme = () => {
    // only consider user bg check status if company connection status is enabled
    const checkrStatus = checkrConnectionStatus
      ? backGroundCheckCleared()
      : true;
    if (!handbookDone && !trainingsDone && !checkrConnectionStatus) {
      // all are incomplete
      return "red";
    } else if (!handbookDone && !trainingsDone && !checkrStatus) {
      return "red";
    } else if (!(handbookDone && trainingsDone && checkrStatus)) {
      // some are incomplete
      return "yellow";
    } else {
      return "green";
    }
  };

  return (
    <Td textAlign="center">
      <Popover trigger="click" openDelay={10} closeDelay={10}>
        <PopoverTrigger>
          <Tag cursor="pointer" colorScheme={getTagColorScheme()}>
            {numTrainingsComplete +
              (handbookDone ? 1 : 0) +
              (backGroundCheckCleared() ? 1 : 0)}{" "}
            / {totalPossibleTrainings + 1 + (checkrConnectionStatus ? 1 : 0)}
          </Tag>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Flex marginY="8px">
              <Text>Trainings:</Text>
              <Spacer />
              <Tag
                colorScheme={
                  numTrainingsComplete >= totalPossibleTrainings
                    ? "green"
                    : numTrainingsComplete > 0
                    ? "yellow"
                    : "red"
                }
              >
                {numTrainingsComplete}/{totalPossibleTrainings}
              </Tag>
            </Flex>
            <Flex marginY="8px">
              <Text>Handbook version:</Text>
              <Spacer />
              <Tag
                colorScheme={
                  !aegisUser.latestHandbookVersion
                    ? "red"
                    : aegisUser.latestHandbookVersion >= "v1.4"
                    ? "green"
                    : "yellow"
                }
              >
                {aegisUser.latestHandbookVersion || "none"}
              </Tag>
            </Flex>
            <Flex marginY="8px">
              <Text>Handbook date:</Text>
              <Spacer />
              <Tag
                colorScheme={
                  aegisUser.lastHandbookDate && !handbookExpired
                    ? "green"
                    : !aegisUser.lastHandbookDate
                    ? "red"
                    : "yellow"
                }
              >
                {aegisUser.lastHandbookDate
                  ? new Date(aegisUser.lastHandbookDate).toDateString()
                  : "n/a"}
              </Tag>
            </Flex>
            <CheckrSection aegisUser={aegisUser} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Td>
  );
}
