import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Spacer,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Tr,
} from "@chakra-ui/react";
import { useTracking } from "../../../../hooks/tracking.hooks";
import {
  HealthcheckRow,
  HealthcheckStatus,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";

interface IHealthcheckResultProps {
  result: HealthcheckRow;
  deviceName: string;
}

export default function HealthcheckResult({
  result,
  deviceName,
}: IHealthcheckResultProps) {
  const { trackHealthcheckClick } = useTracking();
  const ColorSchemeMap: Record<HealthcheckStatus, string> = {
    failure: "red",
    info: "gray",
    success: "green",
  };

  const ResultKeys = [
    "stepName_s",
    "status_s",
    "data_s",
    "TimeGenerated",
  ] as const;
  let formattedOutput = result.data_s.replaceAll("\\n", "\n");
  try {
    formattedOutput = JSON.stringify(JSON.parse(result.data_s), null, 2);
  } catch {}
  return (
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton
              onClick={() => {
                if (!isExpanded) {
                  // isExpanded is false when the user clicks to open the panel
                  trackHealthcheckClick(
                    deviceName,
                    result.stepName_s,
                    result.status_s
                  );
                }
              }}
            >
              <Box flex="1" textAlign="left">
                {result.stepName_s}
              </Box>
              {result.status_s !== "info" && (
                <>
                  <Spacer />
                  <Tag colorScheme={ColorSchemeMap[result.status_s]}>
                    {result.status_s}
                  </Tag>
                </>
              )}
            </AccordionButton>
          </h2>

          <AccordionPanel>
            <TableContainer>
              <Table>
                <Tbody>
                  {ResultKeys.map((key) => (
                    <Tr key={key}>
                      <Th>{key}</Th>
                      <Td>
                        <pre style={{ whiteSpace: "pre-wrap" }}>
                          {key === "data_s" ? formattedOutput : result[key]}
                        </pre>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}
