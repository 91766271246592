import { Td, Skeleton } from "@chakra-ui/react";
import { useEffect } from "react";
import { useAzureUserInfo } from "../../../../../hooks/users.hooks";
import TooltipTag from "../../../../../Components/TooltipTag/TooltipTag";

export default function UserAzureInfo({
  tenantId,
  cloudId,
  setError,
}: Readonly<{
  tenantId: string;
  cloudId: string;
  setError: React.Dispatch<React.SetStateAction<Error | null>>;
}>) {
  const { isFetching, error, data } = useAzureUserInfo(tenantId, cloudId);

  useEffect(() => setError(error), [error, setError]);

  return (
    <>
      <Td textAlign="center">
        <Skeleton isLoaded={!isFetching}>
          <TooltipTag
            tagText={data?.assignedLicenses.length.toString() ?? "0"}
            tooltipText={data?.assignedLicenses.sort().join(", ") ?? ""}
            tagColorScheme="gray"
            toolTipIsDisabled={!data?.assignedLicenses}
          />
        </Skeleton>
      </Td>
      <Td textAlign="center">
        <Skeleton isLoaded={!isFetching}>{data?.usageTime ?? "0"}</Skeleton>
      </Td>
    </>
  );
}
