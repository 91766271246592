import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Skeleton,
  HStack,
  Tag,
  Tooltip,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import {
  useOptionalFeatureStatus,
  useVpnSslStatus,
} from "../../../hooks/tenants.hooks";

export default function VpnSection({ tenantId }: { tenantId: string }) {
  const featureQuery = useOptionalFeatureStatus("vpn", tenantId);
  const vpnSslQuery = useVpnSslStatus(tenantId);

  useEffect(() => {
    if (featureQuery.error) {
      console.error(featureQuery.error);
    }
  }, [featureQuery.error]);

  if (!featureQuery.data?.availableForTenant) {
    return <></>;
  }

  return (
    <Box>
      <Text fontSize="xs" fontWeight="bold">
        VPN status
      </Text>
      {!vpnSslQuery.isError ? (
        <Skeleton isLoaded={!!vpnSslQuery.data}>
          <HStack>
            <Tag
              colorScheme={vpnSslQuery?.data?.sslExpired ? "red" : "green"}
              size="sm"
            >
              SSL
            </Tag>
          </HStack>
        </Skeleton>
      ) : (
        <Tooltip label={vpnSslQuery.error.message}>
          <Tag colorScheme="red" size="sm">
            <Icon as={InfoIcon} color="red" marginRight={1} />
            VPN SSL error{"  "}
          </Tag>
        </Tooltip>
      )}
    </Box>
  );
}
