import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { AegisAppConfig } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";

interface IConfigContentModalProps {
  configData: AegisAppConfig;
  isOpen: boolean;
  onClose: () => void;
}

export default function ConfigContentModal({
  configData,
  isOpen,
  onClose,
}: IConfigContentModalProps) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>App config contents</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box margin={4}>
            <pre style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(configData, null, 4)}</pre>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
