import { Stat, StatLabel, HStack, useDisclosure } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import {
  useAccountSetupAccountInfo,
  useOptionalFeatureStatus,
} from "../../../../../hooks/tenants.hooks";
import useMicrosoftToken from "../../../../../hooks/tokens.hooks";
import { useTenantId } from "../../../../../hooks/utils.hooks";
import {
  runAccountSetup,
  updateOptionalFeature,
} from "../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  OptionalFeatureAvailability,
  OptionalFeatureId,
} from "../../../../../types/api.types";
import ConfirmOptionalFeatureModal, {
  IOptionalFeatureModalConfirmProps,
} from "./ConfirmOptionalFeatureModal";
import OptionalFeature from "./OptionalFeature";

export default function OptionalFeaturesSection() {
  const tenantId = useTenantId();
  const confirmModalDisclosure = useDisclosure();
  const [modalConfirmProps, setModalConfirmProps] =
    useState<IOptionalFeatureModalConfirmProps | null>(null);
  const { data: backupSettingsData } = useOptionalFeatureStatus(
    "backup:settings",
    tenantId
  );
  const { data: vpnSettingsData } = useOptionalFeatureStatus(
    "vpn:settings",
    tenantId
  );
  const { data: accountSetupInfo, error: accountSetupError } =
    useAccountSetupAccountInfo(tenantId);
  const { getInternalApiToken } = useMicrosoftToken();

  const handleConfirmBackupSettings = useCallback(
    async (
      optionalFeatureId: OptionalFeatureId,
      optionalFeatureStatus: OptionalFeatureAvailability | undefined
    ) => {
      if (
        optionalFeatureStatus === undefined ||
        accountSetupInfo === undefined
      ) {
        return;
      }
      if (accountSetupError) {
        throw new Error(
          `account info query error: ${accountSetupError.message}`
        );
      }
      const token = await getInternalApiToken();
      if (optionalFeatureStatus.availableForTenant) {
        await updateOptionalFeature(token, tenantId, optionalFeatureId, false);
      } else {
        if (!accountSetupInfo.location) {
          throw new Error(
            "location for account setup info is undefined. Run the steps manually from the account setup tab."
          );
        }
        const res = await runAccountSetup(
          token,
          tenantId,
          { location: accountSetupInfo.location },
          [
            "assignCompanyKeyVaultRoleToAutomateSp",
            "assignArmRolesToAtomusAdminsGroup",
          ]
        );
        if (!res.success) {
          throw new Error("errors in account setup");
        }
        await updateOptionalFeature(token, tenantId, optionalFeatureId, true);
      }
    },
    [accountSetupError, accountSetupInfo, getInternalApiToken, tenantId]
  );

  return (
    <>
      <ConfirmOptionalFeatureModal
        confirmProps={modalConfirmProps}
        setConfirmProps={setModalConfirmProps}
        {...confirmModalDisclosure}
      />
      <Stat>
        <StatLabel>Optional Features:</StatLabel>
        <HStack>
          <OptionalFeature
            setModalConfirmProps={setModalConfirmProps}
            featureId="vpn"
            featureName="VPN"
            isToggleable
          />
          <OptionalFeature
            setModalConfirmProps={setModalConfirmProps}
            featureId="duo:mfa"
            featureName="Duo"
            isToggleable
          />
          <OptionalFeature
            setModalConfirmProps={setModalConfirmProps}
            featureId="training"
            featureName="Training"
            isToggleable
          />
          <OptionalFeature
            setModalConfirmProps={setModalConfirmProps}
            featureId="backup:settings"
            featureName="Backup Settings"
            isToggleable
            handleModalConfirm={() =>
              handleConfirmBackupSettings("backup:settings", backupSettingsData)
            }
          />
          <OptionalFeature
            setModalConfirmProps={setModalConfirmProps}
            featureId="vpn:settings"
            featureName="VPN Settings"
            isToggleable
            handleModalConfirm={() =>
              handleConfirmBackupSettings("vpn:settings", vpnSettingsData)
            }
          />
          <OptionalFeature
            setModalConfirmProps={setModalConfirmProps}
            featureId="vpn:rdp"
            featureName="RDP"
          />
        </HStack>
      </Stat>
    </>
  );
}
