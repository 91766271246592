import {
  Flex,
  Heading,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tag,
  useDisclosure,
} from "@chakra-ui/react";
import { useOptionalFeatureStatus } from "../../../../../../../hooks/tenants.hooks";
import { useTenantId } from "../../../../../../../hooks/utils.hooks";
import BackupSection from "./BackupSection";
import VpnSection from "./VpnSection";

export default function UserPreferences({
  username,
  displayName,
}: Readonly<{ username: string; displayName: string }>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const tenantId = useTenantId();
  const { data: backupFeatureAvailability } = useOptionalFeatureStatus(
    "backup:settings",
    tenantId
  );
  const { data: vpnFeatureAvailability } = useOptionalFeatureStatus(
    "vpn:settings",
    tenantId
  );
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent padding="12px">
          <ModalCloseButton />
          <ModalBody display="flex" flexDir="column" gap="12px">
            <Heading size="lg" fontWeight="semibold">
              User preferences
            </Heading>
            <Flex gap="8px" alignItems="center">
              <Heading size="sm" fontWeight="normal">
                Current user:
              </Heading>
              <Tag size="sm">{displayName}</Tag>
            </Flex>
            {backupFeatureAvailability?.availableForTenant && (
              <BackupSection username={username} />
            )}
            {vpnFeatureAvailability?.availableForTenant && (
              <VpnSection username={username} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <MenuItem onClick={onOpen}>User preferences</MenuItem>
    </>
  );
}
