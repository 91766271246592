import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { ApiActivityRow } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";

interface IActivityModalProps {
  disclosure: UseDisclosureReturn;
  entry: ApiActivityRow | null;
}

export default function ActivityModal({
  disclosure,
  entry,
}: IActivityModalProps) {
  const { isOpen, onClose } = disclosure;

  useEffect(() => {
    if (!entry && isOpen) {
      onClose();
    }
  }, [entry, isOpen, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Activity log details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {entry && (
            <Table>
              <Thead>
                <Th>Key</Th>
                <Th>Value</Th>
              </Thead>
              <Tbody>
                {Object.entries(entry).map(([key, value]) => (
                  <Tr key={key}>
                    <Td>{key}</Td>
                    <Td>{value}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
