import { Box, Button, Center, Flex, Progress, Text } from "@chakra-ui/react";
import { useContext } from "react";
import AccountSetupInfo from "./AccountSetupInfo";
import AccountSetupRunResult from "./AccountSetupRunResult";
import OrganizationalBrandingSection from "./OrganizationalBrandingSection";
import AccountSetupUsersSection from "./AccountSetupUsersSection";
import MacOSDefenderSection from "./MacOSDefenderSection";
import {
  useAccountSetupAccountInfo,
  useAccountSetupStatuses,
} from "../../../hooks/tenants.hooks";
import { AccountSetupContext } from "./AccountSetupContext";
import StepStatusSection from "./StepStatusSection";
import LocationTooltip from "./LocationTooltip";

interface IAccountSetupSectionProps {
  tenantId: string;
}

export default function AccountSetupSection({
  tenantId,
}: Readonly<IAccountSetupSectionProps>) {
  const { accountInfo, runResult, stepsAreRunning, runSteps } =
    useContext(AccountSetupContext);

  const {
    isFetching: infoIsFetching,
    isError: infoIsError,
    error: infoError,
  } = useAccountSetupAccountInfo(tenantId);
  const {
    isFetching: statusesAreFetching,
    isError: statusesAreError,
    error: statusesError,
    data: statuses,
  } = useAccountSetupStatuses(tenantId);

  if (infoIsError || statusesAreError) {
    return (
      <Center>
        {infoIsError && (
          <>
            <Text color="red">{infoError.message}</Text>
            <br />
          </>
        )}
        {statusesAreError && <Text color="red">{statusesError.message}</Text>}
      </Center>
    );
  }

  return (
    <Flex marginY={4} flexDir="column" gap="24px">
      {!infoIsFetching && accountInfo && <AccountSetupInfo />}
      {(!accountInfo || !statuses || infoIsFetching || statusesAreFetching) && (
        <Progress isIndeterminate borderRadius="md" />
      )}
      {!infoIsFetching &&
        !statusesAreFetching &&
        accountInfo &&
        statuses?.length === 0 && (
          <Box width="fit-content">
            <LocationTooltip>
              <Button
                variant="outline"
                colorScheme="blue"
                isLoading={stepsAreRunning}
                onClick={() => runSteps(true, [])}
                isDisabled={!accountInfo.location}
              >
                Run full Account Setup
              </Button>
            </LocationTooltip>
          </Box>
        )}
      {!infoIsFetching &&
        !statusesAreFetching &&
        accountInfo &&
        statuses &&
        statuses.length > 0 && (
          <>
            <OrganizationalBrandingSection tenantId={tenantId} />
            <AccountSetupUsersSection tenantId={tenantId} />
            <MacOSDefenderSection />
            {runResult && <AccountSetupRunResult />}
            <StepStatusSection tenantId={tenantId} />
          </>
        )}
    </Flex>
  );
}
