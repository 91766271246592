import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { ExternalAzureUser } from "../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";

interface IExternalUsersModalProps {
  isOpen: boolean;
  onClose: () => void;
  externalUsers: ExternalAzureUser[];
  setExternalUsers: React.Dispatch<React.SetStateAction<ExternalAzureUser[]>>;
}

const DEFAULT_USER: ExternalAzureUser = {
  displayName: "",
  userPrincipalName: "",
};

export default function ExternalUsersModal({
  isOpen,
  onClose,
  externalUsers,
  setExternalUsers,
}: IExternalUsersModalProps) {
  const [newExternalUser, setNewExternalUser] =
    useState<ExternalAzureUser>(DEFAULT_USER);

  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setExternalUsers([...externalUsers, newExternalUser]);
    setNewExternalUser(DEFAULT_USER);
    onClose();
  };

  const handleCancel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setNewExternalUser(DEFAULT_USER);
    onClose();
  };

  const emailIsValid = () => {
    return (
      newExternalUser.userPrincipalName.includes("@support.atomuscyber.com") ||
      newExternalUser.userPrincipalName.includes("@atomuscyber.com") ||
      newExternalUser.userPrincipalName.includes("@atomuscyber.us") ||
      newExternalUser.userPrincipalName.includes(
        "@aegisplatform.onmicrosoft.com"
      )
    );
  };

  return (
    <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>External User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                flex="1"
                marginRight={2}
                required
                value={newExternalUser.displayName}
                placeholder={`Enter the name`}
                onChange={(e) =>
                  setNewExternalUser({
                    ...newExternalUser,
                    displayName: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl isRequired marginTop={4}>
              <FormLabel>Email</FormLabel>
              <Text fontSize="sm" marginBottom={3}>
                (must be a valid Atomus domain)
              </Text>
              <Input
                required
                value={newExternalUser.userPrincipalName}
                placeholder={`Enter the email`}
                onChange={(e) =>
                  setNewExternalUser({
                    ...newExternalUser,
                    userPrincipalName: e.target.value,
                  })
                }
              />
            </FormControl>

            <Flex marginY={4} width="100%" direction="row">
              <Button
                type="submit"
                onClick={handleSubmit}
                colorScheme="blue"
                isDisabled={!emailIsValid()}
              >
                Add
              </Button>
              <Button onClick={handleCancel} variant="outline" marginLeft={2}>
                Cancel
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
