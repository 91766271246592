import {
  Td,
  Flex,
  Button,
  Tag,
  useToast,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import useMicrosoftToken from "../../../../../hooks/tokens.hooks";
import { useTracking } from "../../../../../hooks/tracking.hooks";
import { useUserIsReadyToOnboardDbDevices } from "../../../../../hooks/users.hooks";
import { toastError } from "../../../../../Providers/ToastProvider";
import { UserTypes } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import { sendEmail } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  AegisUser,
  EmailId,
} from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../../../../types/types";

export default function UserEmailInfo({
  aegisUser,
  toast,
  tenantId,
}: Readonly<{
  aegisUser: AegisUser;
  toast: ReturnType<typeof useToast>;
  tenantId: string;
}>) {
  const [sendingUserGuide, setSendingUserGuide] = useState(false);
  const [sendingCSTraining, setSendingCSTraining] = useState(false);
  const [sendingEmployeeHB, setSendingEmployeeHB] = useState(false);
  const [sendingDeviceOnboarding, setSendingDeviceOnboarding] = useState(false);
  const { getInternalApiToken } = useMicrosoftToken();
  const { trackSendEmail } = useTracking();
  const readyQuery = useUserIsReadyToOnboardDbDevices(tenantId, aegisUser.id);

  const handleSendEmail = async (emailId: EmailId) => {
    let loadingStateSetter: StateSetter<boolean>;
    if (emailId === "device-onboarding") {
      loadingStateSetter = setSendingDeviceOnboarding;
    } else if (emailId === "employee-handbook") {
      loadingStateSetter = setSendingEmployeeHB;
    } else if (emailId === "cs-training") {
      loadingStateSetter = setSendingCSTraining;
    } else {
      loadingStateSetter = setSendingUserGuide;
    }
    loadingStateSetter(true);
    try {
      const token = await getInternalApiToken();
      await sendEmail(token, tenantId, emailId, {
        username: aegisUser.username,
      });
      if (emailId === "device-onboarding") {
        aegisUser.deviceEmailSends++;
      } else if (emailId === "employee-handbook") {
        aegisUser.handbookEmailSends++;
      } else if (emailId === "cs-training") {
        aegisUser.trainingEmailSends++;
      } else {
        aegisUser.userGuideEmailSends++;
      }
      trackSendEmail(emailId, aegisUser.username, tenantId);
    } catch (error) {
      toastError(toast, error);
    }
    loadingStateSetter(false);
  };

  const getDeviceEmailBlockReason = (defaultReason: string) => {
    if (!aegisUser.active) {
      // provide no reason for inactive users to match other buttons
      return undefined;
    } else if (aegisUser.userType === UserTypes.OFFLINE) {
      return "cannot send this email to offline user";
    } else {
      return defaultReason;
    }
  };

  return (
    <>
      <Td>
        <Flex justifyContent="center">
          <Button
            size="sm"
            variant="ghost"
            colorScheme="blue"
            isDisabled={!aegisUser.active}
            isLoading={sendingUserGuide}
            onClick={async () => handleSendEmail("user-guide")}
          >
            {sendingUserGuide
              ? "Sending..."
              : aegisUser.userGuideEmailSends > 0
              ? "Send again"
              : "Send"}
          </Button>
          <Tag>{aegisUser.userGuideEmailSends}</Tag>
        </Flex>
      </Td>
      <Td>
        <Flex justifyContent="center">
          {readyQuery.error && (
            <Tooltip label={readyQuery.error.message}>
              <Tag colorScheme="red" size="sm">
                Error
              </Tag>
            </Tooltip>
          )}
          {!readyQuery.error && (
            <Tooltip
              isDisabled={readyQuery.isLoading || readyQuery.data?.isReady}
              label={
                readyQuery.data?.isReady === false
                  ? getDeviceEmailBlockReason(readyQuery.data.reason)
                  : undefined
              }
            >
              <Box>
                <Button
                  size="sm"
                  variant="ghost"
                  colorScheme="blue"
                  isDisabled={
                    !aegisUser.active || readyQuery.data?.isReady === false
                  }
                  isLoading={sendingDeviceOnboarding || readyQuery.isFetching}
                  onClick={() => handleSendEmail("device-onboarding")}
                >
                  {sendingDeviceOnboarding
                    ? "Sending..."
                    : aegisUser.deviceEmailSends > 0
                    ? "Send again"
                    : "Send"}
                </Button>
              </Box>
            </Tooltip>
          )}
          <Tag>{aegisUser.deviceEmailSends}</Tag>
        </Flex>
      </Td>
      <Td>
        <Flex justifyContent="center">
          <Button
            size="sm"
            variant="ghost"
            colorScheme="blue"
            isDisabled={!aegisUser.active}
            isLoading={sendingCSTraining}
            onClick={async () => handleSendEmail("cs-training")}
          >
            {sendingCSTraining
              ? "Sending..."
              : aegisUser.trainingEmailSends > 0
              ? "Send again"
              : "Send"}
          </Button>
          <Tag>{aegisUser.trainingEmailSends}</Tag>
        </Flex>
      </Td>
      <Td>
        <Flex justifyContent="center">
          <Button
            size="sm"
            variant="ghost"
            colorScheme="blue"
            isDisabled={!aegisUser.active}
            isLoading={sendingEmployeeHB}
            onClick={() => handleSendEmail("employee-handbook")}
          >
            {sendingEmployeeHB
              ? "Sending..."
              : aegisUser.handbookEmailSends > 0
              ? `Send again`
              : `Send`}
          </Button>
          <Tag>{aegisUser.handbookEmailSends}</Tag>
        </Flex>
      </Td>
    </>
  );
}
