export function generateRandomMsftPassword(): string {
  const lowerCaseLetters = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";

  // Generate 5 random letters
  let password = "";
  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * lowerCaseLetters.length);
    if (i === 0) {
      password += lowerCaseLetters[randomIndex].toUpperCase();
    } else {
      password += lowerCaseLetters[randomIndex];
    }
  }

  // Generate 4 random numbers
  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * numbers.length);
    password += numbers[randomIndex];
  }

  return password;
}
