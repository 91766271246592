import { Box } from "@chakra-ui/react";
import { useContext } from "react";
import { AtomusErrorMessages } from "../../../constants/errors.constants";
import AuthContext from "../../../contexts/AuthContext";
import TooltipTag from "../../TooltipTag/TooltipTag";

export default function PreferencesError({
  queryError,
  preferenceType
}: Readonly<{ queryError: Error; preferenceType: string }>) {
  const { role } = useContext(AuthContext);

  let tagText: string;
  let tooltipText: string;
  if (queryError.message !== AtomusErrorMessages.KV_NOT_FOUND) {
    tagText = `Error loading ${preferenceType} preferences`;
    tooltipText = queryError.message;
  } else if (role === "securityAdmin") {
    tagText = "Company key vault not found";
    tooltipText =
      "Run the 'assignArmRolesToAtomusAdminsGroup' and 'assignCompanyKeyVaultRoleToAutomateSp' account setup steps to create.";
  } else {
    tagText = "Feature not enabled";
    tooltipText = "Contact 'success@atomuscyber.com' to enable this feature.";
  }

  return (
    <Box width="fit-content">
      <TooltipTag
        tagText={tagText}
        tooltipText={tooltipText}
        tagColorScheme="red"
      />
    </Box>
  );
}
