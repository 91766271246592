import { Tooltip, Tag } from "@chakra-ui/react";

export default function TooltipTag({
  toolTipIsDisabled = false,
  tooltipText,
  tagColorScheme,
  tagText,
}: Readonly<{
  tagColorScheme?: string;
  tooltipText: string;
  toolTipIsDisabled?: boolean;
  tagText: string;
}>) {
  return (
    <Tooltip label={tooltipText} isDisabled={toolTipIsDisabled}>
      <Tag colorScheme={tagColorScheme} _hover={{ cursor: "pointer" }}>
        {tagText}
      </Tag>
    </Tooltip>
  );
}
