import { Box, Tooltip } from "@chakra-ui/react";
import { ReactNode, useContext } from "react";
import { AccountSetupContext } from "./AccountSetupContext";

export default function LocationTooltip({ children }: { children: ReactNode }) {
  const { accountInfo } = useContext(AccountSetupContext);
  return (
    <Tooltip
      isDisabled={Boolean(accountInfo?.location)}
      label="Location must be specified in account info"
    >
      <Box>{children}</Box>
    </Tooltip>
  );
}
