import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getCompanyBackupPreferences,
  getCompanyVpnPreferences,
  setCompanyBackupPreferences,
  setCompanyVpnPreferences,
} from "../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  CompanyBackupPreferences,
  CompanyVpnPreferences,
} from "../types/backup-preferences.types";
import useMicrosoftToken from "./tokens.hooks";

export function useCompanyBackupPreferences(tenantId: string) {
  const { getInternalApiToken } = useMicrosoftToken();
  const queryKey = [tenantId, "backup-preferences"];
  return {
    ...useQuery<CompanyBackupPreferences, Error>(queryKey, async () => {
      const token = await getInternalApiToken();
      return getCompanyBackupPreferences(token, tenantId);
    }),
    queryKey,
  };
}

export function useUpdateCompanyBackupPreferences(tenantId: string) {
  const { getInternalApiToken } = useMicrosoftToken();
  const queryClient = useQueryClient();
  return useMutation<CompanyBackupPreferences, Error, CompanyBackupPreferences>(
    {
      mutationFn: async (prefs) => {
        const token = await getInternalApiToken();
        const updatedPrefs = await setCompanyBackupPreferences(
          `Bearer ${token}`,
          tenantId,
          prefs
        );
        queryClient.setQueryData(
          [tenantId, "backup-preferences"],
          updatedPrefs
        );
        return updatedPrefs;
      },
    }
  );
}

export function useCompanyVpnPreferences(tenantId: string) {
  const { getInternalApiToken } = useMicrosoftToken();
  const queryKey = [tenantId, "vpn-preferences"];
  return {
    ...useQuery<CompanyVpnPreferences, Error>(queryKey, async () => {
      const token = await getInternalApiToken();
      return getCompanyVpnPreferences(token, tenantId);
    }),
    queryKey,
  };
}

export function useUpdateCompanyVpnPreferences(tenantId: string) {
  const { getInternalApiToken } = useMicrosoftToken();
  const queryClient = useQueryClient();
  return useMutation<CompanyVpnPreferences, Error, CompanyVpnPreferences>({
    mutationFn: async (prefs) => {
      const token = await getInternalApiToken();
      const updatedPrefs = await setCompanyVpnPreferences(
        `Bearer ${token}`,
        tenantId,
        prefs
      );
      queryClient.setQueryData([tenantId, "vpn-preferences"], updatedPrefs);
      return updatedPrefs;
    },
  });
}
