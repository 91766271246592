import { Flex } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import VpnPreferencesForm from "../../../../Components/preferences/VpnPreferencesForm/VpnPreferencesForm";
import {
  useCompanyVpnPreferences,
  useUpdateCompanyVpnPreferences,
} from "../../../../hooks/preferences.hooks";
import { useTenantId } from "../../../../hooks/utils.hooks";
import {
  CompanyVpnPreferences,
  VpnPreferences,
} from "../../../../types/backup-preferences.types";
import { omitPropertyFromObject } from "../../../../utils/utils";

export default function VpnSection() {
  const tenantId = useTenantId();
  const { data, isFetching, error, refetch } =
    useCompanyVpnPreferences(tenantId);
  const companyWidePrefs = useMemo(
    () => (data ? omitPropertyFromObject(data, "userPreferences") : undefined),
    [data]
  );
  const { mutateAsync } = useUpdateCompanyVpnPreferences(tenantId);

  const updatePrefs = useCallback(
    (values: VpnPreferences) => {
      const newPrefs: CompanyVpnPreferences = {
        ...values,
      };
      if (data?.userPreferences) {
        newPrefs.userPreferences = { ...data.userPreferences };
      }
      return mutateAsync(newPrefs);
    },
    [data?.userPreferences, mutateAsync]
  );

  return (
    <Flex flexDir="column" gap="8px">
      <VpnPreferencesForm
        queryData={companyWidePrefs}
        queryError={error}
        queryFetching={isFetching}
        reloadPrefs={() => refetch({ throwOnError: true })}
        resetConfirmMsg="This will remove all company-wide networking preferences."
        updatePrefs={updatePrefs}
        description='These preferences apply to all Aegis devices in your organization.
        If allowing RDP connections on a device, it is recommended to configure the settings
        on the user level from the "Users" tab. "Not configured" means Aegis will not
        modify the current setting on the device.'
        isUserSettings={false}
      />
    </Flex>
  );
}
