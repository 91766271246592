import { MenuItem, useDisclosure, useToast } from "@chakra-ui/react";
import { useContext } from "react";
import AuthContext from "../../../../../../contexts/AuthContext";
import useMicrosoftToken from "../../../../../../hooks/tokens.hooks";
import { toastError } from "../../../../../../Providers/ToastProvider";
import { reactivateUser } from "../../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { AegisUser } from "../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../../../../../types/types";
import { UserTableContext } from "../../../../UserTableContext";
import ConfirmDeactivateModal from "./ConfirmDeactivateModal";

export default function DeactivateUser({
  tenantId,
  user,
  setIsLoading,
}: Readonly<{
  tenantId: string;
  user: AegisUser;
  setIsLoading: StateSetter<boolean>;
}>) {
  const { role } = useContext(AuthContext);

  const { setUsersArr } = useContext(UserTableContext);

  const modalProps = useDisclosure();

  const { getInternalApiToken } = useMicrosoftToken();
  const toastProvider = useToast();

  if (role !== "securityAdmin") {
    return <></>;
  }

  const modalOnOpen = () => {
    modalProps.onOpen();
  };

  const handleReactivate = async () => {
    setIsLoading(true);
    const token = await getInternalApiToken();
    try {
      await reactivateUser(token, tenantId, user.id);
      setUsersArr((prevUsers) =>
        prevUsers.map((prevUser) =>
          prevUser.id === user.id ? { ...prevUser, active: true } : prevUser
        )
      );
    } catch (err) {
      toastError(toastProvider, err);
    }
    setIsLoading(false);
  };

  return (
    <>
      <ConfirmDeactivateModal
        user={user}
        tenantId={tenantId}
        isOpen={modalProps.isOpen}
        onClose={modalProps.onClose}
      />
      <MenuItem
        color="red"
        onClick={user.active ? modalOnOpen : handleReactivate}
      >
        {user.active ? "Deactivate" : "Reactivate"}
      </MenuItem>
    </>
  );
}
