import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Flex,
  Grid,
  GridItem,
  Link,
  Skeleton,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { BsCheck, BsX } from "react-icons/bs";
import { BackupPreferences } from "../../../types/backup-preferences.types";
import HourSelect from "../../HourSelect/HourSelect";
import { isEqual } from "lodash";
import { toastError, toastSuccess } from "../../../Providers/ToastProvider";
import ResetConfirmationModal from "../ResetConfirmationModal/ResetConfirmationModal";
import PreferencesHeader from "../PreferencesHeader/PreferencesHeader";
import PreferencesError from "../PreferencesError/PreferencesError";

const DEFAULT_START = 9;
const DEFAULT_END = 17;

export default function BackupPreferencesForm({
  queryData,
  queryFetching,
  queryError,
  updatePrefs,
  reloadPrefs,
  description,
  resetConfirmMsg,
}: Readonly<{
  queryData: BackupPreferences | undefined;
  queryFetching: boolean;
  queryError: Error | null;
  updatePrefs: (newPrefs: BackupPreferences) => Promise<BackupPreferences>;
  reloadPrefs: () => void;
  description: string;
  resetConfirmMsg: string;
}>) {
  const [values, setValues] = useState<BackupPreferences>(queryData ?? {});
  const [isSaving, setIsSaving] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const toast = useToast();
  const resetDisclosure = useDisclosure();

  useEffect(() => {
    if (queryData) {
      setValues({ ...queryData });
    }
  }, [queryData]);

  const handleSave = useCallback(
    async (newValue: BackupPreferences, isReset?: boolean) => {
      try {
        if (isReset) {
          setIsResetting(true);
        } else {
          setIsSaving(true);
        }
        if (newValue.schedule) {
          if (newValue.schedule.startHour === newValue.schedule.endHour) {
            toastError(
              toast,
              "Invalid backup schedule (start time cannot be the same as end time)"
            );
            return;
          }
        }
        await updatePrefs(newValue);
        toastSuccess(toast, "Successfully saved backup preferences");
      } catch (error) {
        reloadPrefs();
        console.error(error);
        toastError(toast, "Error saving backup preferences");
      } finally {
        if (isReset) {
          setIsResetting(false);
        } else {
          setIsSaving(false);
        }
      }
    },
    [reloadPrefs, toast, updatePrefs]
  );

  const handleCancel = () => {
    setValues(queryData ? { ...queryData } : {});
  };

  const displayReset =
    !queryFetching && queryData !== undefined && !isEqual(queryData, {});

  return (
    <>
      {displayReset && (
        <ResetConfirmationModal
          confirmMsg={resetConfirmMsg}
          disclosure={resetDisclosure}
          onConfirm={handleSave}
        />
      )}
      <Flex flexDir="column" gap="8px">
        <PreferencesHeader
          description={description}
          displayReset={displayReset}
          isResetting={isResetting}
          isSaving={isSaving}
          openResetModal={resetDisclosure.onOpen}
          queryFetching={queryFetching}
          reloadPrefs={reloadPrefs}
          headerText="Aegis Backup"
        />
        {queryError && (
          <PreferencesError queryError={queryError} preferenceType="backup" />
        )}
        {!queryError && (
          <Grid templateColumns="3fr 1fr" rowGap="8px" columnGap="32px">
            <GridItem>
              <Text>Enable backup</Text>
              <Text fontSize="xs" color="gray.500">
                Enable/disable automatic Aegis backups.
              </Text>
            </GridItem>
            <GridItem>
              <Skeleton
                isLoaded={!queryFetching}
                display="flex"
                flexDir="row-reverse"
              >
                <Switch
                  isChecked={!values.isDisabled}
                  onChange={() =>
                    setValues((prev) => {
                      if (!prev.isDisabled) {
                        return { ...prev, isDisabled: true };
                      }
                      const { isDisabled, ...rest } = prev;
                      return rest;
                    })
                  }
                  isDisabled={queryFetching || isSaving || isResetting}
                />
              </Skeleton>
            </GridItem>
            <GridItem>
              <Text>Enable backup on metered connections</Text>
              <Text fontSize="xs" color="gray.500">
                Enable/disable backups when Windows devices are connected to a
                network that is designated as "metered" (
                <Link
                  target="_blank"
                  href="https://support.microsoft.com/en-us/windows/metered-connections-in-windows-7b33928f-a144-b265-97b6-f2e95a87c408"
                  size="xs"
                  color="gray.500"
                >
                  Learn more <ExternalLinkIcon />
                </Link>
                ).
              </Text>
            </GridItem>
            <GridItem>
              <Skeleton
                isLoaded={!queryFetching}
                display="flex"
                flexDir="row-reverse"
              >
                <Switch
                  isChecked={!values.isDisabledWhenMetered}
                  onChange={() =>
                    setValues((prev) => {
                      if (!prev.isDisabledWhenMetered) {
                        return { ...prev, isDisabledWhenMetered: true };
                      }
                      const { isDisabledWhenMetered, ...rest } = prev;
                      return rest;
                    })
                  }
                  isDisabled={queryFetching || isSaving || isResetting}
                />
              </Skeleton>
            </GridItem>
            <GridItem>
              <Text>Schedule when backups can start</Text>
              <Text fontSize="xs" color="gray.500">
                If enabled, backups will only start between the specified times.
              </Text>
            </GridItem>
            <GridItem>
              <Skeleton
                isLoaded={!queryFetching}
                width="100%"
                display="flex"
                flexDir="row-reverse"
                alignItems="flex-end"
              >
                <Switch
                  isChecked={Boolean(values.schedule)}
                  onChange={() =>
                    setValues((prev) => {
                      if (!prev.schedule) {
                        return {
                          ...prev,
                          schedule: {
                            endHour: DEFAULT_END,
                            startHour: DEFAULT_START,
                          },
                        };
                      }
                      const { schedule, ...rest } = prev;
                      return rest;
                    })
                  }
                  isDisabled={queryFetching || isSaving || isResetting}
                />
              </Skeleton>
            </GridItem>
            <GridItem>
              {values.schedule?.endHour !== undefined &&
                values.schedule?.startHour !== undefined && (
                  <Collapse
                    in={!queryFetching && Boolean(values.schedule)}
                    unmountOnExit
                  >
                    <Flex gap="12px">
                      <Box>
                        <Text fontSize="sm">Start</Text>
                        <HourSelect
                          value={values.schedule.startHour}
                          size="sm"
                          setValue={(val) => {
                            setValues((prev) => {
                              if (val === undefined || !prev.schedule) {
                                return { ...prev };
                              }
                              return {
                                ...prev,
                                schedule: { ...prev.schedule, startHour: val },
                              };
                            });
                          }}
                        />
                      </Box>
                      <Box>
                        <Text fontSize="sm">End</Text>
                        <HourSelect
                          value={values.schedule.endHour}
                          size="sm"
                          setValue={(val) => {
                            setValues((prev) => {
                              if (val === undefined || !prev.schedule) {
                                return { ...prev };
                              }
                              return {
                                ...prev,
                                schedule: { ...prev.schedule, endHour: val },
                              };
                            });
                          }}
                        />
                      </Box>
                    </Flex>
                  </Collapse>
                )}
            </GridItem>
            <GridItem />
            <GridItem />
            <GridItem display="flex" flexDir="row-reverse">
              <Collapse
                in={
                  !queryFetching &&
                  (queryData === undefined
                    ? !isEqual({}, values)
                    : !isEqual(queryData, values))
                }
                unmountOnExit
              >
                <ButtonGroup>
                  <Button
                    size="xs"
                    variant="ghost"
                    rightIcon={<BsX />}
                    onClick={handleCancel}
                    isDisabled={
                      queryFetching ||
                      Boolean(queryError) ||
                      isSaving ||
                      isResetting
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    size="xs"
                    variant="ghost"
                    rightIcon={<BsCheck />}
                    colorScheme="blue"
                    onClick={() => handleSave(values)}
                    isDisabled={
                      queryFetching || Boolean(queryError) || isResetting
                    }
                    isLoading={isSaving}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Collapse>
            </GridItem>
          </Grid>
        )}
      </Flex>
    </>
  );
}
