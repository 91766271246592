import { Box } from "@chakra-ui/react";
import { useMemo } from "react";
import { UseQueryResult } from "react-query";
import {
  AppInfo,
  AppInfoResponse,
  SupportedPlatform,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { SuccessAegisResponse } from "../../../../types/api.types";
import PlatformOperations from "./PlatformOperations";
import PlatformStatus from "./PlatformStatus";

interface IPlatformOverviewProps {
  platform: Exclude<SupportedPlatform, "all">;
  releaseAppInfoQuery: UseQueryResult<AppInfoResponse, Error>;
  tenantAppInfoQuery: UseQueryResult<AppInfoResponse, Error>;
}

export default function PlatformOverview({
  platform,
  releaseAppInfoQuery,
  tenantAppInfoQuery,
}: IPlatformOverviewProps) {
  const downloadLink = useMemo(() => {
    const data = tenantAppInfoQuery.data;
    if (data && data[platform].success) {
      return (data[platform] as SuccessAegisResponse<AppInfo>).data
        .downloadLink;
    }
    return undefined;
  }, [tenantAppInfoQuery.data, platform]);
  return (
    <Box>
      <PlatformStatus
        platform={platform}
        releaseAppInfoQuery={releaseAppInfoQuery}
        tenantAppInfoQuery={tenantAppInfoQuery}
      />
      <PlatformOperations
        platform={platform}
        releaseAppInfoQuery={releaseAppInfoQuery}
        downloadLink={downloadLink}
      />
    </Box>
  );
}
