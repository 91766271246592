import { MenuItem } from "@chakra-ui/react";
import { StateSetter } from "../../../../../../types/types";
import { unmapCheckrCandidate } from "../../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import useMicrosoftToken from "../../../../../../hooks/tokens.hooks";
import { useAegisUsers } from "../../../../../../hooks/users.hooks";
import { useCheckrCandidates } from "../../../../../../hooks/integrations.hooks";

export default function CheckrOptions({
  tenantId,
  checkrId,
  setIsLoading,
}: Readonly<{
  tenantId: string;
  checkrId: string;
  setIsLoading: StateSetter<boolean>;
}>) {
  const { getInternalApiToken } = useMicrosoftToken();
  const { refetch: usersRefetch } = useAegisUsers(tenantId);
  const { refetch: checkrCandidatesRefetch } = useCheckrCandidates(tenantId);

  const handleUnmapCandidate = async () => {
    setIsLoading(true);
    const token = await getInternalApiToken();
    await unmapCheckrCandidate(token, tenantId, checkrId);
    await Promise.all([usersRefetch(), checkrCandidatesRefetch()]);
    setIsLoading(false);
  };
  return (
    <MenuItem onClick={handleUnmapCandidate}>Unmap Checkr Candidate</MenuItem>
  );
}
