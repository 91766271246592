import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { getDuoUser } from "../services/atomus-internal-apis/atomus-internal-apis.service";
import { DuoUser } from "../types/duo.types";
import { useOptionalFeatureStatus } from "./tenants.hooks";
import useMicrosoftToken from "./tokens.hooks";

export function useDuoUser(
  tenantId: string,
  username: string
): UseQueryResult<DuoUser | null> {
  const { getInternalApiToken } = useMicrosoftToken();
  const availabilityQuery = useOptionalFeatureStatus("duo:mfa", tenantId);
  return useQuery<DuoUser | null>(
    ["duo-user", username, tenantId],
    async () => {
      if (!availabilityQuery.data!.availableForTenant) {
        return null;
      }
      const token = await getInternalApiToken();
      try {
        return await getDuoUser(token, tenantId, username);
      } catch (error) {
        const axErr = error as AxiosError;
        if (axErr.isAxiosError && axErr.response?.status === 404) {
          return null;
        }
        throw error;
      }
    },
    { enabled: !!availabilityQuery.data }
  );
}
