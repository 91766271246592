import { SortDirection } from "../types/utils.types";

export function sortObjectsByKey<T extends Record<string, any>>(
  data: T[],
  key: keyof T,
  direction: SortDirection = "asc"
): T[] {
  const modifier = direction === "asc" ? 1 : -1;
  const newData = [...data].sort(
    (a, b) =>
      modifier *
      `${a[key]}`
        .toLowerCase()
        .localeCompare(`${b[key]}`.toLowerCase(), "en", { numeric: true })
  );
  return newData;
}

export function sortObjectsByConverter<T extends Record<string, any>>(
  data: T[],
  direction: SortDirection,
  converter: (item: T) => string
): T[] {
  const modifier = direction === "asc" ? 1 : -1;
  return [...data].sort(
    (a, b) =>
      modifier *
      converter(a)
        .toLowerCase()
        .localeCompare(converter(b).toLowerCase(), "en", { numeric: true })
  );
}
