import { Skeleton, Tag, Td } from "@chakra-ui/react";
import TooltipTag from "../../../../Components/TooltipTag/TooltipTag";
import { useDeviceAppRecommendations } from "../../../../hooks/devices.hooks";

export default function AppRecommendationsValue({
  aadDeviceId,
  tenantId,
}: Readonly<{ aadDeviceId: string; tenantId: string }>) {
  const { isLoading, data, error } = useDeviceAppRecommendations(
    tenantId,
    aadDeviceId
  );

  return (
    <Td>
      {isLoading && (
        <Skeleton width="fit-content">
          <Tag>Not applicable</Tag>
        </Skeleton>
      )}
      {data === null && (
        <TooltipTag
          tagText="Not found"
          tooltipText="This likely means the device has not been enrolled in defender"
          tagColorScheme="red"
        />
      )}
      {error && (
        <TooltipTag
          tagText="Error"
          tooltipText={error.message}
          tagColorScheme="red"
        />
      )}
      {data && (
        <TooltipTag
          tagColorScheme={data.length === 0 ? "green" : "yellow"}
          tagText={`${data.length}`}
          tooltipText="Click for details"
          toolTipIsDisabled={data.length === 0}
        />
      )}
    </Td>
  );
}
