import { CopyIcon } from "@chakra-ui/icons";
import {
  Text,
  Table,
  Button,
  Icon,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import {
  toastSuccess,
  toastError,
} from "../../../../../../Providers/ToastProvider";
import { ExternalUserInvitationReponse } from "../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";

interface IInvitedUsersInfoSectionProps {
  invitationResults: ExternalUserInvitationReponse[];
}

export default function InvitedUsersInfoSection({
  invitationResults,
}: Readonly<IInvitedUsersInfoSectionProps>) {
  const toastProvider = useToast();

  const handleCopyToClipboard = async (copyText: string) => {
    try {
      await navigator.clipboard.writeText(copyText);
      // Read clipboard text
      const clipText = await navigator.clipboard.readText();
      const editorElement: HTMLElement | null =
        document.querySelector(".editor");
      if (editorElement) {
        editorElement.innerText += clipText;
      }
      toastSuccess(toastProvider, "Copied Redeem URL to clipboard");
    } catch (error) {
      toastError(toastProvider, error);
    }
  };

  return (
    <>
      <Text marginTop={3} fontSize="xl" fontWeight="semibold">
        Sent Invitations
      </Text>
      <Text marginTop={3} fontSize="md" marginBottom={2} as="i">
        Invited users will receive an email invitaton. Be sure to share the
        redeem URLs below if any mailboxes are not accessible.
      </Text>
      <Table
        marginBottom={4}
        __css={{ "table-layout": "fixed", width: "100%" }}
      >
        <Thead>
          <Th>Name</Th>
          <Th>Email</Th>
          <Th>Redeem URL</Th>
          <Th></Th>
        </Thead>
        <Tbody>
          {invitationResults.map((user) => (
            <Tr key={user.invitedUserEmailAddress}>
              <Td>{user.invitedUserDisplayName}</Td>
              <Td>{user.invitedUserEmailAddress}</Td>
              <Td>
                <Text
                  as="span"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  display="inline-block"
                  maxWidth="300px"
                >
                  {user.inviteRedeemUrl}
                </Text>
              </Td>
              <Td>
                <Button
                  size="sm"
                  variant="ghost"
                  mt={2}
                  onClick={() => handleCopyToClipboard(user.inviteRedeemUrl)}
                >
                  <Icon as={CopyIcon} />
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}
