import {
  Box,
  forwardRef,
  Icon,
  TableColumnHeaderProps,
  Th,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { BsTriangleFill } from "react-icons/bs";
import { SortDirection } from "../../types/types";

export default forwardRef<
  TableColumnHeaderProps & {
    sortFunc: () => void;
    children?: ReactNode;
    sortDir: SortDirection | null;
    isSorted: boolean;
  },
  "th"
>((props, ref) => {
  const { sortFunc, children, sortDir, isSorted, ...thProps } = props;
  return (
    <Th ref={ref} {...thProps}>
      <Box
        _hover={{ cursor: "pointer" }}
        onClick={sortFunc}
        width="100%"
        userSelect="none"
      >
        {children}
        {isSorted && " "}
        {isSorted && (
          <Icon
            as={BsTriangleFill}
            transform={sortDir === "desc" ? "rotate(0.5turn)" : undefined}
          />
        )}
      </Box>
    </Th>
  );
});
