import {
  Center,
  Progress,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import SortableTh from "../../../../Components/SortableTh/SortableTh";
import { useAegisUsers } from "../../../../hooks/users.hooks";
import { SortDirection } from "../../../../types/types";
import { sortStrings } from "../../../../utils/utils";
import { UserTableContext } from "../../UserTableContext";
import { UserRow } from "../UserTable/UserRow/UserRow";
import {
  useCheckrCandidates,
  useCheckrConnectionStatus,
} from "../../../../hooks/integrations.hooks";

interface IUsersSectionProps {
  tenantId: string;
}

type SortKey = "displayName" | "email";

export default function UsersTable({ tenantId }: Readonly<IUsersSectionProps>) {
  const { isFetching, isError, error, data } = useAegisUsers(tenantId);
  const {
    usersArr,
    setUsersArr,
    setCheckrCandidates,
    setCheckrCandidatesIsLoading,
    setCheckrConnectionStatus,
  } = useContext(UserTableContext);
  const [sortDir, setSortDir] = useState<SortDirection>("asc");
  const [sortKey, setSortKey] = useState<SortKey | null>(null);

  const { data: checkrCandidatesData, isFetching: checkrCandidatesIsLoading } =
    useCheckrCandidates(tenantId);

  const { data: checkrConnectionStatus } = useCheckrConnectionStatus(tenantId);

  useEffect(() => {
    if (checkrConnectionStatus) {
      setCheckrConnectionStatus(checkrConnectionStatus);
    }
  }, [setCheckrConnectionStatus, checkrConnectionStatus]);

  useEffect(() => {
    setCheckrCandidatesIsLoading(checkrCandidatesIsLoading);
    if (checkrCandidatesData) {
      setCheckrCandidates(checkrCandidatesData);
    }
  }, [
    checkrCandidatesData,
    checkrCandidatesIsLoading,
    setCheckrCandidates,
    setCheckrCandidatesIsLoading,
  ]);

  const sortUsers = (key: SortKey) => {
    const newSortDir = key === sortKey && sortDir === "asc" ? "desc" : "asc";
    const newArr = [...usersArr].sort((a, b) =>
      sortStrings(a[key], b[key], newSortDir)
    );
    setSortDir(newSortDir);
    setSortKey(key);
    setUsersArr(newArr);
  };

  useEffect(() => {
    if (data) {
      setUsersArr([...data]);
    }
  }, [data, setUsersArr]);

  if (isFetching) {
    return <Progress isIndeterminate borderRadius="md" />;
  }

  if (isError) {
    return <Center>{error.message}</Center>;
  }
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <SortableTh
            isSorted={sortKey === "displayName"}
            sortDir={sortDir}
            sortFunc={() => sortUsers("displayName")}
          >
            Display Name
          </SortableTh>
          <SortableTh
            isSorted={sortKey === "email"}
            sortDir={sortDir}
            sortFunc={() => sortUsers("email")}
          >
            Email
          </SortableTh>
          <Th textAlign="center">License Count</Th>
          <Th textAlign="center">Usage Time</Th>
          <Th textAlign="center">Compliance</Th>
          <Th textAlign="center">Device MFA</Th>
          <Th textAlign="center">User Guide</Th>
          <Th textAlign="center">Device Onboarding</Th>
          <Th textAlign="center">Training</Th>
          <Th textAlign="center">Employee HB</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {usersArr.length > 0 &&
          usersArr.map((user) => (
            <UserRow aegisUser={user} tenantId={tenantId} key={user.id} />
          ))}
      </Tbody>
    </Table>
  );
}
