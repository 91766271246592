import { Flex } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import BackupPreferencesForm from "../../../../Components/preferences/BackupPreferencesForm/BackupPreferencesForm";
import {
  useCompanyBackupPreferences,
  useUpdateCompanyBackupPreferences,
} from "../../../../hooks/preferences.hooks";
import { useTenantId } from "../../../../hooks/utils.hooks";
import {
  BackupPreferences,
  CompanyBackupPreferences,
} from "../../../../types/backup-preferences.types";
import { omitPropertyFromObject } from "../../../../utils/utils";

export default function BackupSection() {
  const tenantId = useTenantId();
  const { data, isFetching, error, refetch } =
    useCompanyBackupPreferences(tenantId);
  const [companyWidePrefs, setCompanyWidePrefs] = useState<
    BackupPreferences | undefined
  >(data ? omitPropertyFromObject(data, "userPreferences") : undefined);
  const { mutateAsync } = useUpdateCompanyBackupPreferences(tenantId);

  useEffect(() => {
    if (data) {
      setCompanyWidePrefs(omitPropertyFromObject(data, "userPreferences"));
    } else {
      setCompanyWidePrefs(undefined);
    }
  }, [data]);

  const updatePrefs = useCallback(
    (values: BackupPreferences) => {
      const newPrefs: CompanyBackupPreferences = {
        ...values,
      };
      if (data?.userPreferences) {
        newPrefs.userPreferences = { ...data.userPreferences };
      }
      return mutateAsync(newPrefs);
    },
    [data?.userPreferences, mutateAsync]
  );

  return (
    <Flex flexDir="column" gap="8px">
      <BackupPreferencesForm
        queryData={companyWidePrefs}
        queryError={error}
        queryFetching={isFetching}
        updatePrefs={updatePrefs}
        reloadPrefs={() => refetch({ throwOnError: true })}
        description='These preferences apply to all Aegis devices in your organization.
          Preferences can be overriden on a per-user basis from the "Users" tab.
          Users will always be able to manually run backups from the Aegis app
          regardless of these preferences.'
        resetConfirmMsg="This will remove all company-wide backup preferences."
      />
    </Flex>
  );
}
