import {
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useAtomusGroupsStatus } from "../../../../hooks/groups.hooks";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { useExternalAtomusUsers } from "../../../../hooks/users.hooks";
import { toastError } from "../../../../Providers/ToastProvider";
import { addGroupUser } from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  ExternalAzureUserWithId,
  ExternalUserInvitationReponse,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import ExternalUsersSection from "./ExternalUsersSection";
import { GroupMgmtContext } from "./GroupMgmtContext";
import GroupSection from "./GroupSection";

interface IGroupMgmtProps {
  tenantId: string;
}

export default function GroupMgmtSection({
  tenantId,
}: Readonly<IGroupMgmtProps>) {
  const { getInternalApiToken } = useMicrosoftToken();
  const toast = useToast();

  const { data, isFetching, isError, error } = useAtomusGroupsStatus(tenantId);
  const {
    unassignedUsers,
    setUnassignedUsers,
    buttonsLoading,
    setButtonsLoading,
    adminGroupUsers,
    setAdminGroupUsers,
    readerGroupUsers,
    setReaderGroupUsers,
  } = useContext(GroupMgmtContext);
  const allUsersQuery = useExternalAtomusUsers(tenantId);
  const [invitationResults, setInvitationResults] =
    useState<ExternalUserInvitationReponse[]>();

  useEffect(() => {
    if (allUsersQuery.data && allUsersQuery.isFetchedAfterMount) {
      setUnassignedUsers(allUsersQuery.data);
    }
  }, [
    allUsersQuery.data,
    allUsersQuery.isFetchedAfterMount,
    setUnassignedUsers,
  ]);

  const handleAddUser = async (
    groupId: string,
    user: ExternalAzureUserWithId
  ) => {
    try {
      setButtonsLoading(true);
      const token = await getInternalApiToken();
      await addGroupUser(token, tenantId, groupId, user.id);
      setUnassignedUsers((prevUsers) =>
        prevUsers.filter((unassignedUser) => unassignedUser.id !== user.id)
      );
      if (data?.adminGroupId === groupId) {
        setAdminGroupUsers((prevUsers) => [...prevUsers, user]);
      } else if (data?.readerGroupId === groupId) {
        setReaderGroupUsers((prevUsers) => [...prevUsers, user]);
      }
      setButtonsLoading(false);
    } catch (err) {
      setButtonsLoading(false);
      toastError(toast, err);
    }
  };

  if (isFetching || allUsersQuery.isFetching) {
    return <Progress isIndeterminate borderRadius="md" />;
  }

  if (isError) {
    return <Center>{error.message}</Center>;
  }

  return (
    <Flex flexDir="column">
      <ExternalUsersSection
        tenantId={tenantId}
        refetchFunc={allUsersQuery.refetch}
        invitationResults={invitationResults}
        setInvitationResults={setInvitationResults}
      />
      <Text fontSize="xl" fontWeight="semibold" marginBottom={4}>
        Unassigned Users
      </Text>
      {isFetching ? (
        <Progress isIndeterminate borderRadius="md" />
      ) : (
        <Table
          variant="simple"
          marginBottom={4}
          width="100%"
          __css={{ "table-layout": "fixed" }}
        >
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>UPN</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {unassignedUsers.map((user) => (
              <Tr key={user.id}>
                <Td>{user.displayName}</Td>
                <Td>
                  <Text
                    as="span"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    display="inline-block"
                    maxWidth="400px"
                  >
                    {user.userPrincipalName}
                  </Text>
                </Td>
                <Td>
                  <Menu>
                    <MenuButton
                      as={Button}
                      width={20}
                      colorScheme="blue"
                      variant="ghost"
                      size="sm"
                      isLoading={buttonsLoading}
                      disabled={!data?.adminGroupId || buttonsLoading}
                    >
                      Assign
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        isDisabled={buttonsLoading}
                        onClick={() => handleAddUser(data?.adminGroupId!, user)}
                      >
                        Add to Admin Group
                      </MenuItem>
                      {data?.readerGroupId !== null && (
                        <MenuItem
                          isDisabled={buttonsLoading}
                          onClick={() =>
                            handleAddUser(data?.readerGroupId!, user)
                          }
                        >
                          Add to Readers Group
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      {data?.adminGroupId ? (
        <GroupSection
          name="Admin Group"
          tenantId={tenantId}
          groupId={data?.adminGroupId}
          assignedUsers={adminGroupUsers}
          setAssignedUsers={setAdminGroupUsers}
        />
      ) : (
        <>
          <Text fontSize="xl" fontWeight="semibold" marginBottom={2}>
            Admin Group
          </Text>
          <Tag colorScheme="red" width={100}>
            {" "}
            Not created!
          </Tag>
        </>
      )}
      {data?.readerGroupId ? (
        <GroupSection
          name="Readers Group"
          tenantId={tenantId}
          groupId={data?.readerGroupId}
          assignedUsers={readerGroupUsers}
          setAssignedUsers={setReaderGroupUsers}
        />
      ) : (
        <>
          <Text fontSize="xl" fontWeight="semibold" marginBottom={2}>
            Reader Group
          </Text>
          <Tag colorScheme="red" width={100}>
            {" "}
            Not created!
          </Tag>
        </>
      )}
    </Flex>
  );
}
