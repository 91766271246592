import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Collapse,
  Flex,
  Grid,
  GridItem,
  Link,
  Select,
  Skeleton,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { isEqual } from "lodash";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { BsX, BsCheck } from "react-icons/bs";
import { toastError, toastSuccess } from "../../../Providers/ToastProvider";
import { VpnPreferences } from "../../../types/backup-preferences.types";
import PreferencesError from "../PreferencesError/PreferencesError";
import PreferencesHeader from "../PreferencesHeader/PreferencesHeader";
import ResetConfirmationModal from "../ResetConfirmationModal/ResetConfirmationModal";

export default function VpnPreferencesForm({
  description,
  queryData,
  queryError,
  queryFetching,
  reloadPrefs,
  resetConfirmMsg,
  updatePrefs,
  isUserSettings,
}: Readonly<{
  queryData: VpnPreferences | undefined;
  queryFetching: boolean;
  queryError: Error | null;
  updatePrefs: (newPrefs: VpnPreferences) => Promise<VpnPreferences>;
  reloadPrefs: () => void;
  description: string;
  resetConfirmMsg: string;
  isUserSettings: boolean;
}>) {
  const [isSaving, setIsSaving] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [useCompanySettings, setUseCompanySettings] = useState(
    isUserSettings &&
      !queryData?.rdpConnectionStatus &&
      !queryData?.unauthenticatedNetworkConnectionsStatus
  );
  const [values, setValues] = useState<VpnPreferences>(
    queryData ?? (isUserSettings && !useCompanySettings)
      ? {
          rdpConnectionStatus: "not-configured",
          unauthenticatedNetworkConnectionsStatus: "not-configured",
        }
      : {}
  );
  const toast = useToast();
  const resetDisclosure = useDisclosure();

  useEffect(() => {
    if (queryData) {
      setValues({ ...queryData });
      if (isUserSettings) {
        setUseCompanySettings(
          !queryData.rdpConnectionStatus &&
            !queryData.unauthenticatedNetworkConnectionsStatus
        );
      }
    }
  }, [isUserSettings, queryData]);

  const handleSave = useCallback(
    async (newValue: VpnPreferences, isReset?: boolean) => {
      try {
        if (isReset) {
          setIsResetting(true);
        } else {
          setIsSaving(true);
        }
        await updatePrefs(newValue);
        toastSuccess(toast, "Successfully saved networking preferences");
      } catch (error) {
        reloadPrefs();
        console.error(error);
        toastError(toast, "Error saving networking preferences");
      } finally {
        if (isReset) {
          setIsResetting(false);
        } else {
          setIsSaving(false);
        }
      }
    },
    [reloadPrefs, toast, updatePrefs]
  );

  const handleCancel = useCallback(() => {
    const newValue = queryData ? { ...queryData } : {};
    setValues(newValue);
    if (isUserSettings) {
      setUseCompanySettings(
        !newValue.rdpConnectionStatus &&
          !newValue.unauthenticatedNetworkConnectionsStatus
      );
    }
  }, [isUserSettings, queryData]);

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!isUserSettings) {
        return;
      }
      setUseCompanySettings(event.target.checked);
      if (event.target.checked) {
        setValues({});
      } else if (
        !values.rdpConnectionStatus &&
        !values.unauthenticatedNetworkConnectionsStatus
      ) {
        setValues({
          rdpConnectionStatus: "not-configured",
          unauthenticatedNetworkConnectionsStatus: "not-configured",
        });
      }
    },
    [
      isUserSettings,
      values.rdpConnectionStatus,
      values.unauthenticatedNetworkConnectionsStatus,
    ]
  );

  const displayReset =
    !queryFetching && queryData !== undefined && !isEqual(queryData, {});
  return (
    <>
      {displayReset && (
        <ResetConfirmationModal
          confirmMsg={resetConfirmMsg}
          disclosure={resetDisclosure}
          onConfirm={handleSave}
        />
      )}
      <Flex flexDir="column" gap="8px">
        <PreferencesHeader
          description={description}
          displayReset={displayReset}
          headerText="Networking"
          isResetting={isResetting}
          isSaving={isSaving}
          openResetModal={resetDisclosure.onOpen}
          queryFetching={queryFetching}
          reloadPrefs={reloadPrefs}
        />
        {queryError && (
          <PreferencesError preferenceType="network" queryError={queryError} />
        )}
        {!queryError && (
          <Grid templateColumns="3fr 1fr" rowGap="8px" columnGap="32px">
            {isUserSettings && (
              <>
                <GridItem>
                  <Checkbox
                    isChecked={
                      useCompanySettings ||
                      (!values.rdpConnectionStatus &&
                        !values.unauthenticatedNetworkConnectionsStatus)
                    }
                    onChange={handleCheckboxChange}
                  >
                    Use company-wide settings
                  </Checkbox>
                </GridItem>
                <GridItem />
              </>
            )}
            <GridItem>
              <Text>Incoming RDP connections</Text>
              <Text fontSize="xs" color="gray.500">
                Enable/disable incoming RDP connections on Aegis devices.
              </Text>
            </GridItem>
            <GridItem>
              <Skeleton
                isLoaded={!queryFetching}
                display="flex"
                flexDir="row-reverse"
              >
                <Select
                  width="max-content"
                  isDisabled={isUserSettings && useCompanySettings}
                  size="xs"
                  value={
                    !values.rdpConnectionStatus
                      ? ""
                      : values.rdpConnectionStatus
                  }
                  defaultValue="not-configured"
                  onChange={(event) =>
                    setValues((prev) => {
                      if (
                        !event.target.value ||
                        (event.target.value === "not-configured" &&
                          !isUserSettings)
                      ) {
                        const { rdpConnectionStatus, ...rest } = prev;
                        return rest;
                      }
                      return {
                        ...prev,
                        rdpConnectionStatus: event.target.value as
                          | "allow"
                          | "block"
                          | "not-configured",
                      };
                    })
                  }
                >
                  <option value="not-configured">Not configured</option>
                  <option value="allow">Allow</option>
                  <option value="block">Block</option>
                </Select>
              </Skeleton>
            </GridItem>
            <GridItem>
              <Text>Unauthenticated network connections</Text>
              <Text fontSize="xs" color="gray.500">
                When RDP is enabled, configure whether or not to allow
                unauthenticated network connections (
                <Link
                  target="_blank"
                  href="https://learn.microsoft.com/en-us/previous-versions/windows/it-pro/windows-server-2008-R2-and-2008/cc732713(v=ws.11)"
                  size="xs"
                  color="gray.500"
                >
                  Learn more <ExternalLinkIcon />
                </Link>
                ).
              </Text>
            </GridItem>
            <GridItem>
              <Skeleton
                isLoaded={!queryFetching}
                display="flex"
                flexDir="row-reverse"
              >
                <Select
                  width="max-content"
                  isDisabled={isUserSettings && useCompanySettings}
                  size="xs"
                  value={
                    !values.unauthenticatedNetworkConnectionsStatus
                      ? ""
                      : values.unauthenticatedNetworkConnectionsStatus
                  }
                  defaultValue="not-configured"
                  onChange={(event) =>
                    setValues((prev) => {
                      if (
                        !event.target.value ||
                        (event.target.value === "not-configured" &&
                          !isUserSettings)
                      ) {
                        const {
                          unauthenticatedNetworkConnectionsStatus,
                          ...rest
                        } = prev;
                        return rest;
                      }
                      return {
                        ...prev,
                        unauthenticatedNetworkConnectionsStatus: event.target
                          .value as "allow" | "block",
                      };
                    })
                  }
                >
                  <option value="not-configured">Not configured</option>
                  <option value="allow">Allow</option>
                  <option value="block">Block</option>
                </Select>
              </Skeleton>
            </GridItem>
            <GridItem />
            <GridItem>
              <Collapse
                in={
                  !queryFetching &&
                  (queryData === undefined
                    ? !isEqual({}, values)
                    : !isEqual(queryData, values))
                }
                unmountOnExit
              >
                <ButtonGroup>
                  <Button
                    size="xs"
                    variant="ghost"
                    rightIcon={<BsX />}
                    onClick={handleCancel}
                    isDisabled={
                      queryFetching ||
                      Boolean(queryError) ||
                      isSaving ||
                      isResetting
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    size="xs"
                    variant="ghost"
                    rightIcon={<BsCheck />}
                    colorScheme="blue"
                    onClick={() => handleSave(values)}
                    isDisabled={
                      queryFetching || Boolean(queryError) || isResetting
                    }
                    isLoading={isSaving}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Collapse>
            </GridItem>
          </Grid>
        )}
      </Flex>
    </>
  );
}
