import {
  Button,
  ButtonGroup,
  Flex,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useRegistryKeys } from "../../hooks/tenants.hooks";
import RegKeyOperationModal from "./RegKeyOperationModal";

interface IRegKeyOperationsProps {
  tenantId: string;
}

export default function RegKeyOperations({ tenantId }: IRegKeyOperationsProps) {
  const [isBulk, setIsBulk] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = (isBulk: boolean) => {
    setIsBulk(isBulk);
    onOpen();
  };

  const { refetch } = useRegistryKeys(tenantId);

  return (
    <>
      <RegKeyOperationModal
        isOpen={isOpen}
        onClose={onClose}
        isBulk={isBulk}
        tenantId={tenantId}
      />
      <Flex align="center">
        <ButtonGroup colorScheme="blue" marginY={2} size="sm" variant="outline">
          <Button onClick={() => handleClick(false)}>Add key operation</Button>
          <Button onClick={() => handleClick(true)}>
            Add multiple key operations
          </Button>
        </ButtonGroup>
        <Spacer />
        <Button
          size="sm"
          colorScheme="blue"
          variant="ghost"
          onClick={() => refetch({ throwOnError: true })}
        >
          Refresh
        </Button>
      </Flex>
    </>
  );
}
