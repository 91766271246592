import { UseToastOptions } from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { ErrorAegisResponse } from "../types/api.types";

export function getApiError(error: any): string {
  if (axios.isAxiosError(error)) {
    return error.message;
  }
  const aegisError = error as AxiosError<ErrorAegisResponse<any>>;
  if (isErrorAegisResponse(aegisError)) {
    return aegisError?.error;
  }
  if (!error) {
    return "Network error.";
  }
  return error;
}

export function isErrorAegisResponse(
  data: any
): data is ErrorAegisResponse<any> {
  return data && data.isAegisResponse && data.success === false;
}

export function toastSuccess(
  toast: (options?: UseToastOptions) => void,
  successMessage: string
) {
  toast({
    position: "top-right",
    description: successMessage,
    status: "success",
    duration: 3000,
    isClosable: true,
  });
}

export function toastInfo(
  toast: (options?: UseToastOptions) => void,
  infoMessage: string
) {
  toast({
    position: "top-right",
    description: infoMessage,
    status: "info",
    duration: 3000,
    isClosable: true,
  });
}

export function toastError(
  toast: (options?: UseToastOptions) => void,
  error: any
) {
  const errorMsg = getApiError(error);
  toast({
    title: "Error",
    position: "top-right",
    description: `${errorMsg}`,
    status: "warning",
    duration: 10000,
    isClosable: true,
  });
}
