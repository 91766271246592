import { Tag, Td, Tooltip, Tr } from "@chakra-ui/react";
import {
  DeviceTrustTypes,
  IntuneDeviceOs,
  INTUNE_MDM_APP_ID,
} from "../../../../constants/microsoft.constants";
import { Device } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { isExpectedTrustType } from "../../../../utils/devices.utils";

function trustTypeTooltip(deviceOs: string, trustType?: string): string {
  if (deviceOs === IntuneDeviceOs.WINDOWS) {
    return `Expected ${DeviceTrustTypes.AZURE_AD}, found ${trustType}`;
  }
  if (deviceOs === IntuneDeviceOs.MAC) {
    return `Expected ${DeviceTrustTypes.AZURE_AD} or ${DeviceTrustTypes.WORKPLACE}, found ${trustType}`;
  }
  return `Unsupported operating system: ${deviceOs}`;
}

export default function TableRow({ device }: Readonly<{ device: Device }>) {
  const correctlyJoined = isExpectedTrustType(device);
  const correctMdmAppId = device.mdmAppId === INTUNE_MDM_APP_ID;
  return (
    <Tr>
      <Td>{device.displayName}</Td>
      <Td>
        {device.registeredUsers
          ? device.registeredUsers
              .map((user) => user.userPrincipalName)
              .join(", ")
          : "Unknown"}
      </Td>
      <Td>{device.operatingSystem}</Td>
      <Td>
        <Tooltip
          isDisabled={correctlyJoined}
          label={trustTypeTooltip(device.operatingSystem, device.trustType)}
        >
          <Tag colorScheme={correctlyJoined ? "green" : "red"}>
            {correctlyJoined ? "Yes" : "No"}
          </Tag>
        </Tooltip>
      </Td>
      <Td>
        <Tooltip
          isDisabled={correctMdmAppId}
          label={`Expected Intune's MDM app id, found: ${device.mdmAppId}`}
        >
          <Tag colorScheme={correctMdmAppId ? "green" : "red"}>
            {correctMdmAppId ? "Yes" : "No"}
          </Tag>
        </Tooltip>
      </Td>
      <Td>{`${device.enrollmentType}`}</Td>
    </Tr>
  );
}
