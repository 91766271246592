import { createContext } from "react";
import { DashboardRole } from "../../services/atomus-internal-apis/atomus-internal-apis.types";

interface IAuthContext {
  isGov: boolean;
  setIsGov: React.Dispatch<React.SetStateAction<boolean>>;
  role: DashboardRole;
  setRole: React.Dispatch<React.SetStateAction<DashboardRole>>;
}

const defaultValue: IAuthContext = {
  isGov: true,
  setIsGov: () => {
    throw new Error("You must wrap your component in an AuthProvider");
  },
  role: "none",
  setRole: () => {
    throw new Error("You must wrap your component in an AuthProvider");
  },
};

export default createContext(defaultValue);
