import { useQuery, UseQueryResult } from "react-query";
import {
  getGroupRoles,
  getGroupStatuses,
  getGroupUsers,
} from "../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  AtomusGroupsStatuses,
  ExternalAzureUserWithId,
  GroupRoles,
} from "../services/atomus-internal-apis/atomus-internal-apis.types";
import useMicrosoftToken from "./tokens.hooks";

export function useAtomusGroupsStatus(
  tenantId: string
): UseQueryResult<AtomusGroupsStatuses, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const groupsQuery = useQuery<AtomusGroupsStatuses, Error>(
    ["groups", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return getGroupStatuses(token, tenantId);
    }
  );
  return groupsQuery;
}

export function useGroupRoles(
  tenantId: string,
  groupId: string
): UseQueryResult<GroupRoles, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const groupsQuery = useQuery<GroupRoles, Error>(
    ["grouproles", tenantId, groupId],
    async () => {
      const token = await getInternalApiToken();
      return getGroupRoles(token, tenantId, groupId);
    }
  );
  return groupsQuery;
}

export function useAtomusGroupUsers(
  tenantId: string,
  groupId: string
): UseQueryResult<ExternalAzureUserWithId[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const groupUsersQuery = useQuery<ExternalAzureUserWithId[], Error>(
    ["groupusers", groupId],
    async () => {
      const token = await getInternalApiToken();
      return getGroupUsers(token, tenantId, groupId);
    },
    { refetchOnMount: true }
  );
  return groupUsersQuery;
}
