import {
  Flex,
  Heading,
  Spacer,
  ButtonGroup,
  Button,
  Text,
} from "@chakra-ui/react";
import { BsArrowCounterclockwise, BsArrowRepeat } from "react-icons/bs";

export default function BackupPreferencesHeader({
  displayReset,
  isResetting,
  isSaving,
  queryFetching,
  reloadPrefs,
  description,
  openResetModal,
  headerText
}: Readonly<{
  displayReset: boolean;
  queryFetching: boolean;
  isSaving: boolean;
  reloadPrefs: () => void;
  isResetting: boolean;
  description: string;
  openResetModal: () => void;
  headerText: string
}>) {
  return (
    <>
      <Flex>
        <Heading size="md" fontWeight="normal">
          {headerText}
        </Heading>
        <Spacer />
        <ButtonGroup>
          {displayReset && (
            <Button
              size="xs"
              variant="ghost"
              rightIcon={<BsArrowCounterclockwise />}
              isDisabled={queryFetching || isSaving}
              colorScheme="red"
              onClick={openResetModal}
              isLoading={isResetting}
            >
              Reset to default
            </Button>
          )}
          <Button
            size="xs"
            variant="ghost"
            onClick={reloadPrefs}
            rightIcon={<BsArrowRepeat />}
            isDisabled={queryFetching || isSaving || isResetting}
          >
            Refresh
          </Button>
        </ButtonGroup>
      </Flex>
      <Text color="gray.500" fontSize="sm">
        {description}
      </Text>
    </>
  );
}
