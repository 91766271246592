import { Flex } from "@chakra-ui/react";
import React, { useCallback } from "react";
import VpnPreferencesForm from "../../../../../../../Components/preferences/VpnPreferencesForm/VpnPreferencesForm";
import {
  useCompanyVpnPreferences,
  useUpdateCompanyVpnPreferences,
} from "../../../../../../../hooks/preferences.hooks";
import { useTenantId } from "../../../../../../../hooks/utils.hooks";
import { VpnPreferences } from "../../../../../../../types/backup-preferences.types";

export default function VpnSection({
  username,
}: Readonly<{ username: string }>) {
  const tenantId = useTenantId();
  const { data, isFetching, error, refetch } =
    useCompanyVpnPreferences(tenantId);
  const { mutateAsync } = useUpdateCompanyVpnPreferences(tenantId);

  const updatePrefs = useCallback(
    (values: VpnPreferences) => {
      const newUserPrefs = data?.userPreferences
        ? { ...data?.userPreferences }
        : {};
      newUserPrefs[username] = values;
      return mutateAsync({ ...data, userPreferences: newUserPrefs });
    },
    [data, mutateAsync, username]
  );

  return (
    <Flex flexDir="column" gap="8px">
      <VpnPreferencesForm
        queryData={data?.userPreferences?.[username]}
        queryError={error}
        queryFetching={isFetching}
        updatePrefs={updatePrefs}
        reloadPrefs={() => refetch({ throwOnError: true })}
        description='These preferences will only apply to the specified user. They will override company-wide preferences. "Not configured" means Aegis will not modify the current setting on the device.'
        resetConfirmMsg="The user will now use the company-wide settings."
        isUserSettings={true}
      />
    </Flex>
  );
}
