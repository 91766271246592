import {
  Button,
  Checkbox,
  Collapse,
  Flex,
  Heading,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tag,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsEnvelope } from "react-icons/bs";
import LoadingBar from "../../../../../../../Components/LoadingBar";
import TooltipTag from "../../../../../../../Components/TooltipTag/TooltipTag";
import useMicrosoftToken from "../../../../../../../hooks/tokens.hooks";
import { useUserOnboardingCheckResult } from "../../../../../../../hooks/users.hooks";
import { useTenantId } from "../../../../../../../hooks/utils.hooks";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../Providers/ToastProvider";
import { sendEmail } from "../../../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { DEFAULT_SELECTION } from "./constants";
import OnboardingOptionCheckbox from "./OnboardingOptionCheckbox";
import { OnboardingOption } from "./types";

export default function OnboardingEmail({
  email,
  username,
  userId,
}: Readonly<{ email: string; username: string; userId: string }>) {
  const tenantId = useTenantId();
  const { getInternalApiToken } = useMicrosoftToken();
  const toast = useToast();
  const [macIsChecked, setMacIsChecked] = useState(false);
  const [windowsIsChecked, setWindowsIsChecked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<
    Record<OnboardingOption, boolean>
  >({ ...DEFAULT_SELECTION });
  const [isSending, setIsSending] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, error } = useUserOnboardingCheckResult(
    tenantId,
    userId
  );

  useEffect(() => {
    setMacIsChecked(false);
    setWindowsIsChecked(false);
    setSelectedOptions({ ...DEFAULT_SELECTION });
  }, [isOpen]);

  useEffect(() => {
    if (!windowsIsChecked) {
      setSelectedOptions((prev) => ({
        ...prev,
        windowsAppOnly: false,
        windowsPartition: false,
        windowsSwitchUser: false,
      }));
    }
  }, [windowsIsChecked]);

  useEffect(() => {
    if (!macIsChecked) {
      setSelectedOptions((prev) => ({
        ...prev,
        macNewDevice: false,
        macPartition: false,
      }));
    }
  }, [macIsChecked]);

  const handleSendEmail = async () => {
    setIsSending(true);
    try {
      const token = await getInternalApiToken();
      await sendEmail(token, tenantId, "custom-device-onboarding", {
        ...selectedOptions,
        username,
      });
      toastSuccess(toast, "Successfully sent email");
      onClose();
    } catch (error) {
      console.error(error);
      toastError(
        toast,
        `Error sending email: ${
          (error as Error).message ?? "an unknown error occurred"
        }`
      );
    }
    setIsSending(false);
  };

  const buttonIsDisabled = () => {
    if (Object.values(selectedOptions).every((value) => value === false)) {
      return true;
    }
    if (!macIsChecked && !windowsIsChecked) {
      return false;
    }
    if (
      macIsChecked &&
      !selectedOptions.macNewDevice &&
      !selectedOptions.macPartition
    ) {
      return true;
    }
    if (
      windowsIsChecked &&
      !selectedOptions.windowsAppOnly &&
      !selectedOptions.windowsPartition &&
      !selectedOptions.windowsSwitchUser &&
      !selectedOptions.windowsNewDevice
    ) {
      return true;
    }
    return false;
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent padding="12px">
          <ModalCloseButton />
          <ModalBody display="flex" flexDir="column" gap="12px">
            <Heading size="lg" fontWeight="semibold">
              Send device onboarding instructions
            </Heading>
            <Flex gap="8px" alignItems="center">
              <Heading size="md" fontWeight="normal">
                Send onboarding instructions to:
              </Heading>
              <Tag size="md">{email}</Tag>
            </Flex>
            <Heading size="md" fontWeight="normal">
              With the following OS option(s):
            </Heading>
            {error && (
              <TooltipTag
                tagText="Error running onboarding checks"
                tooltipText={error.message}
                tagColorScheme="red"
              />
            )}
            {isLoading && <LoadingBar />}
            {data && (
              <Flex flexDir="column" gap="4px">
                <Checkbox
                  isChecked={windowsIsChecked}
                  onChange={() => setWindowsIsChecked(!windowsIsChecked)}
                >
                  Windows
                </Checkbox>
                <Collapse in={windowsIsChecked} unmountOnExit>
                  <Flex flexDir="column" gap="4px" paddingLeft="24px">
                    <OnboardingOptionCheckbox
                      description="Partition"
                      onboardingCheckResult={data}
                      onboardingOption="windowsPartition"
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                    <OnboardingOptionCheckbox
                      description="Switch user"
                      onboardingCheckResult={data}
                      onboardingOption="windowsSwitchUser"
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                    <OnboardingOptionCheckbox
                      description="New device"
                      onboardingCheckResult={data}
                      onboardingOption="windowsNewDevice"
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                    <OnboardingOptionCheckbox
                      description="App install only"
                      onboardingCheckResult={data}
                      onboardingOption="windowsAppOnly"
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                  </Flex>
                </Collapse>
                <Checkbox
                  isChecked={macIsChecked}
                  onChange={() => setMacIsChecked(!macIsChecked)}
                >
                  Mac
                </Checkbox>
                <Collapse in={macIsChecked} unmountOnExit>
                  <Flex flexDir="column" gap="4px" paddingLeft="24px">
                    <OnboardingOptionCheckbox
                      description="Partition"
                      onboardingCheckResult={data}
                      onboardingOption="macPartition"
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                    <OnboardingOptionCheckbox
                      description="New device"
                      onboardingCheckResult={data}
                      onboardingOption="macNewDevice"
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                  </Flex>
                </Collapse>
                <OnboardingOptionCheckbox
                  description="Ubuntu (v20.04 and v22.04 supported)"
                  onboardingCheckResult={data}
                  onboardingOption="linux"
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                />
                <OnboardingOptionCheckbox
                  description="iOS"
                  onboardingCheckResult={data}
                  onboardingOption="iOS"
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                />
                <OnboardingOptionCheckbox
                  description="Android"
                  onboardingCheckResult={data}
                  onboardingOption="android"
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                />
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex flexDir="row" width="100%" justifyContent="center">
              <Button
                colorScheme="blue"
                rightIcon={<BsEnvelope />}
                isDisabled={buttonIsDisabled()}
                onClick={handleSendEmail}
                isLoading={isSending}
              >
                Send
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <MenuItem onClick={onOpen}>Send custom onboarding instructions</MenuItem>
    </>
  );
}
