export const DeviceOSValues = {
  Mac: "MacOS",
  Windows: "Windows",
  Linux: "Linux",
  Android: "Android",
  iOS: "iOS",
};

export type DeviceOS = keyof typeof DeviceOSValues;

export type SuccessAegisResponse<T> = {
  success: true;
  code: number;
  data: T;
};

export type ErrorAegisResponse<T> = {
  success: false;
  code: number;
  error: string;
  data: T;
};

/**
 * @description various optional feature ids a tenant can subscribe to
 */
export type OptionalFeatureId =
  | "vpn:rdp"
  | "training"
  | "vpn"
  | "duo:mfa"
  | "backup:settings"
  | "vpn:settings";

/**
 * @description information about optional feature availability for a specified principal
 */
export interface OptionalFeatureAvailability {
  availableForDevice: boolean;
  availableForUser: boolean;
  availableForTenant: boolean;
}

export type ReadyToOnboardCheckId =
  // check that the user is active and not offline-user type
  | "userIsActive"
  // check that the user row has a cloud id
  | "userHasCloudId"
  // check that the user exists in azure
  | "azUserFound"
  // check that the user has microsoft license assigned
  | "userHasLicense"
  // check that the azure company group exists
  | "companyGroupFound"
  // check that the user is a member of the company group
  | "userIsInCompanyGroup"
  // check that the atomus aegis app has been granted admin consent
  | "aegisAppConsentGranted"
  // check that the user's duo user exists (if duo enabled)
  | "duoUserExists"
  // check that the user has the app config data reader role for the app config
  | "userHasAppConfigReader"
  // check that the user key vault exists
  | "userKeyVaultExists"
  // check that the user has apple creds
  | "userHasAppleCreds"
  // check that the user has microsoft credentials
  | "userHasMsftCreds";

export type OnboardingCheckResult = Partial<
  Record<ReadyToOnboardCheckId, string>
>;
