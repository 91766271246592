import { FormLabel, Textarea, Center, Button } from "@chakra-ui/react";
import { useState } from "react";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { useRegistryKeys } from "../../../../hooks/tenants.hooks";
import { updateRegistryKeys } from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { RegKeyConfiguration } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";

interface IMultiOperationFormProps {
  tenantId: string;
  onClose: () => void;
}

export default function MultiOperationForm({
  tenantId,
  onClose,
}: IMultiOperationFormProps) {
  const [text, setText] = useState<string>("");
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { refetch } = useRegistryKeys(tenantId);

  const { getInternalApiToken } = useMicrosoftToken();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    try {
      JSON.parse(e.target.value);
      setIsInvalid(false);
    } catch {
      setIsInvalid(true);
    }
    setText(e.target.value);
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const keyConfig = JSON.parse(text) as RegKeyConfiguration;
      const token = await getInternalApiToken();
      await updateRegistryKeys(token, tenantId, keyConfig);
      refetch();
      onClose();
    } catch (error) {
      alert((error as Error).message);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <FormLabel>Value</FormLabel>
      <Textarea
        resize="vertical"
        value={text}
        onChange={handleChange}
        placeholder="Enter reg key operations as they would appear in the config"
        isInvalid={isInvalid}
      />
      <Center mt={4}>
        <Button
          colorScheme="blue"
          isDisabled={isInvalid}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Center>
    </>
  );
}
