import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ReactNode, SetStateAction, useEffect, useState } from "react";
import { MsalAuthConfigs } from "../constants/auth.constants";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { NO_REFETCH_QUERY } from "../constants/reactquery.constants";
import AuthContext from "../contexts/AuthContext";
import { DashboardRole } from "../services/atomus-internal-apis/atomus-internal-apis.types";
import { getDashboardRole } from "../services/atomus-internal-apis/atomus-internal-apis.service";

const queryClient = new QueryClient({
  defaultOptions: { queries: { ...NO_REFETCH_QUERY, retry: false } },
});

const GOV_INSTANCE = new PublicClientApplication(MsalAuthConfigs.gov);
const COMMERCIAL_INSTANCE = new PublicClientApplication(
  MsalAuthConfigs.commercial
);

export default function AuthProvider({ children }: { children: ReactNode }) {
  const [instance, setInstance] =
    useState<PublicClientApplication>(GOV_INSTANCE);
  const [isGov, _setIsGov] = useState<boolean>(true);
  const [role, setRole] = useState<DashboardRole>("none");

  useEffect(() => {
    const { pathname } = new URL(window.location.href);
    const account = instance.getActiveAccount();
    if (role === "none" && pathname !== "/login" && account) {
      const navToLogin = () => {
        instance.setActiveAccount(null);
        const location = new URL(window.location.href);
        location.pathname = "/login";
        window.location.href = location.toString();
      };
      if (!account) {
        navToLogin();
        return;
      }
      getDashboardRole(account.username)
        .then((newRole) => {
          if (newRole === "none") {
            navToLogin();
            return;
          }
          setRole(newRole);
        })
        .catch((error) => {
          console.error(error);
          navToLogin();
        });
    }
  }, [role, instance]);

  const setIsGov = (target: SetStateAction<boolean>) => {
    if (target !== isGov) {
      queryClient.clear();
      queryClient.removeQueries();
      sessionStorage.removeItem("current-internals-token");
      _setIsGov(target);
      setInstance(target ? GOV_INSTANCE : COMMERCIAL_INSTANCE);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isGov,
        setIsGov,
        role,
        setRole,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <MsalProvider instance={instance}>{children}</MsalProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </AuthContext.Provider>
  );
}
