import { forwardRef, SelectProps, Select } from "@chakra-ui/react";

const VALUES = [...Array(24)].map((_, idx) => {
  let label: string;
  if (idx < 11) {
    label = `${idx + 1}AM`;
  } else if (idx === 11) {
    label = `${idx + 1}PM`;
  } else if (idx === 23) {
    label = `${idx - 11}AM`;
  } else {
    label = `${idx - 11}PM`;
  }
  return {
    label,
    value: idx + 1,
  };
});

export default forwardRef<
  SelectProps & {
    value: number;
    setValue: (newValue: number) => void;
  },
  "select"
>((props, ref) => {
  const { value, setValue, ...selectProps } = props;

  return (
    <Select
      ref={ref}
      {...selectProps}
      value={value}
      onChange={(event) => {
        const num = parseInt(event.target.value);
        if (isNaN(num)) {
          console.error("invalid value in hour select");
          return;
        }
        setValue(num);
      }}
      placeholder="Select a time"
    >
      {VALUES.map((spec) => (
        <option key={spec.label} value={spec.value}>
          {spec.label}
        </option>
      ))}
    </Select>
  );
});
