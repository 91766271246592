import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { uploadAppConfig } from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";

interface IUploadDrawerProps {
  tenantId: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function UploadDrawer({
  tenantId,
  isOpen,
  onClose,
}: IUploadDrawerProps) {
  const [file, setFile] = useState<File | null | undefined>(null);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { getInternalApiToken } = useMicrosoftToken();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (!file?.name.endsWith(".json")) {
      setFile(null);
      setError("Must be a .json file");
    } else {
      setFile(file);
    }
  };

  const handleSubmit = async () => {
    if (!file) {
      setError("Unable to get file");
      return;
    }
    setIsSubmitting(true);
    let fileData: Record<string, any>;
    try {
      fileData = JSON.parse(await file.text());
    } catch (error) {
      setError(`Unable to parse file data: ${(error as Error).message}`);
      setIsSubmitting(false);
      return;
    }
    try {
      await uploadAppConfig(await getInternalApiToken(), tenantId, fileData);
    } catch (error) {
      setError(`Error uploading file data: ${(error as Error).message}`);
    }
    setIsSubmitting(false);
    onClose();
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Upload app config</DrawerHeader>
          <DrawerBody>
            <Box>
              <input
                type="file"
                onChange={handleFileChange}
                onClick={() => setError(null)}
              />
              <Button
                colorScheme="blue"
                size="sm"
                marginY={4}
                isDisabled={!file}
                onClick={handleSubmit}
                isLoading={isSubmitting}
              >
                Upload
              </Button>
              {error && <Text color="red">{error}</Text>}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
