import { InfoIcon } from "@chakra-ui/icons";
import {
  StatGroup,
  Stat,
  StatLabel,
  Text,
  Icon,
  Select,
  Box,
} from "@chakra-ui/react";
import { useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { AccountInfo } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { AccountSetupContext } from "./AccountSetupContext";

const AzLocations = {
  COMMERCIAL: ["westus", "centralus", "eastus"],
  GOV: ["usgovarizona", "usgovvirginia"],
} as const;

export default function AccountSetupInfo() {
  const { accountInfo, setAccountInfo } = useContext(AccountSetupContext);
  const { isGov } = useContext(AuthContext);

  if (!accountInfo) {
    return <></>;
  }

  return (
    <Box>
      <Text fontSize="lg" fontWeight="semibold">
        Account setup info
      </Text>
      <StatGroup>
        {Object.keys(accountInfo).map((val) =>
          val === "location" ? undefined : (
            <Stat key={val}>
              <StatLabel>{val}</StatLabel>
              <Text fontSize="sm">{accountInfo[val as keyof AccountInfo]}</Text>
            </Stat>
          )
        )}
        <Stat key="location">
          <StatLabel>
            {!accountInfo.location && (
              <>
                <Icon as={InfoIcon} color="red" />{" "}
              </>
            )}
            location
          </StatLabel>
          <Select
            value={accountInfo.location}
            placeholder="Select an Azure location"
            size="sm"
            onChange={(event) =>
              setAccountInfo({ ...accountInfo, location: event.target.value })
            }
          >
            {(isGov ? AzLocations.GOV : AzLocations.COMMERCIAL).map(
              (location) => (
                <option value={location} key={location}>
                  {location}
                </option>
              )
            )}
          </Select>
        </Stat>
      </StatGroup>
    </Box>
  );
}
