import { CheckIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Skeleton,
  Tooltip,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext, useState } from "react";
import { useCompanyImages } from "../../../hooks/tenants.hooks";
import useMicrosoftToken from "../../../hooks/tokens.hooks";
import { toastSuccess } from "../../../Providers/ToastProvider";
import {
  uploadLogo,
  uploadLoginBackground,
} from "../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { AccountSetupContext } from "./AccountSetupContext";
import LocationTooltip from "./LocationTooltip";

export default function OrganizationalBrandingSection({
  tenantId,
}: Readonly<{
  tenantId: string;
}>) {
  const [backgroundImageLoading, setBackgroundImageLoading] = useState(false);
  const [logoImageLoading, setLogoImageLoading] = useState(false);
  const [logoPayload, setLogoPayload] = useState<File | undefined>();
  const [backgroundPayload, setBackgroundPayload] = useState<
    File | undefined
  >();
  const [useDefaultBackground, setUseDefaultBackground] = useState(true);
  const { accountInfo } = useContext(AccountSetupContext);

  const { getInternalApiToken } = useMicrosoftToken();
  const ToastProvider = useToast();

  const { data, isLoading, isError, error, refetch } =
    useCompanyImages(tenantId);

  const checkAndSetImage = async (
    file: File,
    imageSet: (value: React.SetStateAction<File | undefined>) => void,
    maxSizeKb: number
  ) => {
    const ALLOWED_EXTS = [".png", ".jpg", ".jpeg"];
    const fileExt = RegExp(/\.[^.]*$/).exec(file?.name)?.[0];
    if (!fileExt) {
      throw new Error(
        "Unable to determine file type. Please check the file name and extension."
      );
    }
    let fileExtOK = false;
    for (const ext of ALLOWED_EXTS) {
      if (fileExt?.toLowerCase().includes(ext)) {
        fileExtOK = true;
      }
    }
    if (!fileExtOK) {
      throw new Error("File type not permitted. Uploads can be images only.");
    }
    const fileSizeKb = file.size / 1024;
    if (fileSizeKb > maxSizeKb) {
      throw new Error(`File exceeds size limit of ${maxSizeKb} kb`);
    }
    imageSet(file);
  };

  const handleLogoFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event.target.files?.item(0);
      if (!file) {
        throw new Error("Unable to read logo file");
      }
      const maxKb = 50;
      await checkAndSetImage(file, setLogoPayload, maxKb);
    } catch (error) {
      event.target.value = "";
      alert((error as Error).message);
    }
  };

  const handleBackgroundFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event.target.files?.item(0);
      if (!file) {
        throw new Error("Unable to read background image file");
      }
      const maxKb = 300;
      await checkAndSetImage(file, setBackgroundPayload, maxKb);
    } catch (error) {
      event.target.value = "";
      alert((error as Error).message);
    }
  };

  const handleLogoImageUpload = async () => {
    try {
      setLogoImageLoading(true);
      const token = await getInternalApiToken();
      if (logoPayload) {
        await uploadLogo(token, tenantId, logoPayload);
      } else {
        throw new Error("No logo image uploaded");
      }
      setLogoImageLoading(false);
      refetch();
      toastSuccess(ToastProvider, "Company logo uploaded");
    } catch (error) {
      alert((error as Error).message);
      setLogoImageLoading(false);
    }
  };

  const handleBackgroundFileUpload = async () => {
    try {
      setBackgroundImageLoading(true);
      const token = await getInternalApiToken();
      if (useDefaultBackground) {
        const defaultAtomusBackgroundUrl =
          "https://uhgodyxkkusljmqgamhq.supabase.co/storage/v1/object/public/atomus-assets/atomus-login-background.png";
        const response = await axios.get(defaultAtomusBackgroundUrl, {
          responseType: "arraybuffer",
        });
        const file = new File([response.data], "atomus-login-background.png", {
          type: response.headers["content-type"],
        });
        await uploadLoginBackground(token, tenantId, file);
      } else if (backgroundPayload) {
        await uploadLoginBackground(token, tenantId, backgroundPayload);
      } else {
        throw new Error("No background image uploaded");
      }
      setBackgroundImageLoading(false);
      await refetch();
      toastSuccess(ToastProvider, "Login background set");
    } catch (error) {
      alert((error as Error).message);
      setBackgroundImageLoading(false);
    }
  };

  const handleCheckboxChange = () => {
    setUseDefaultBackground(!useDefaultBackground);
  };

  return (
    <Flex alignItems="baseline" flexDirection="column">
      <Text fontSize="lg" fontWeight="semibold" marginBottom="4px">
        Microsoft Organization Branding
      </Text>
      <Flex alignItems="center" flexDirection="row">
        <Box>
          <Flex alignItems="baseline" flexDirection="row">
            <Text fontWeight="semibold" fontSize="md">
              Company Logo
            </Text>
            <Skeleton marginLeft={2} isLoaded={!isLoading}>
              {data?.logo && (
                <Tooltip label="Company logo is set">
                  <Icon as={CheckIcon} color="green" />
                </Tooltip>
              )}
              {data?.logo === false && (
                <Tooltip label="Company logo is not set">
                  <Icon as={InfoIcon} color="gray" />
                </Tooltip>
              )}
              {isError && (
                <Tooltip label={error.message}>
                  <Icon as={InfoIcon} color="red" />
                </Tooltip>
              )}
            </Skeleton>
          </Flex>
          <Flex alignItems="flex-end">
            <LocationTooltip>
              <Button
                colorScheme="blue"
                onClick={handleLogoImageUpload}
                isLoading={logoImageLoading}
                marginTop={2}
                isDisabled={isLoading || !accountInfo?.location}
                size="sm"
              >
                Set image
              </Button>
            </LocationTooltip>
            <Box marginLeft={10}>
              <input type="file" onChange={handleLogoFileChange} />
            </Box>
          </Flex>
        </Box>
        <Box>
          <Flex alignItems="baseline" flexDirection="row">
            <Flex alignItems="center">
              <Text fontWeight="semibold" fontSize="md">
                Login Background
              </Text>
              <Text marginLeft={2} marginRight={2}>
                (Use default Atomus background?{" "}
              </Text>
              <Checkbox
                isChecked={useDefaultBackground}
                onChange={handleCheckboxChange}
                size="md"
                marginTop={1}
              />
              <Text>)</Text>
            </Flex>
            <Skeleton marginLeft={2} isLoaded={!isLoading}>
              {data?.loginBackground && (
                <Tooltip label="Login background is set">
                  <Icon as={CheckIcon} color="green" />
                </Tooltip>
              )}
              {data?.loginBackground === false && (
                <Tooltip label="Login background is not set">
                  <Icon as={InfoIcon} color="gray" />
                </Tooltip>
              )}
              {isError && (
                <Tooltip label={error.message}>
                  <Icon as={InfoIcon} color="red" />
                </Tooltip>
              )}
            </Skeleton>
          </Flex>
          <Flex alignItems="flex-end">
            <LocationTooltip>
              <Button
                border="1px"
                colorScheme="blue"
                onClick={handleBackgroundFileUpload}
                isLoading={backgroundImageLoading}
                isDisabled={isLoading || !accountInfo?.location}
                marginTop={2}
                size="sm"
              >
                Set image
              </Button>
            </LocationTooltip>

            {!useDefaultBackground && (
              <Box marginLeft={10}>
                <input type="file" onChange={handleBackgroundFileChange} />
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}
