import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";
import Navbar from "../Navbar";

export default function PageContainer({ children }: { children?: ReactNode }) {
  return (
    <Box>
      <Navbar />
      <Box mt={8} marginX={8}>
        {children}
      </Box>
    </Box>
  );
}
