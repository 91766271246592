import { CopyIcon, DownloadIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, useToast } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";
import { SpModalContext } from "../../../../contexts/SpModal";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { toastSuccess } from "../../../../Providers/ToastProvider";
import { SupportedPlatforms } from "../../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import {
  getAppConfig,
  pushIntuneApp,
  pushLatestApp,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  AppInfoResponse,
  SupportedPlatform,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";

const ReleaseLinks = {
  darwin:
    "https://aegisproductionstorage.blob.core.windows.net/current-version-darwin/Aegis-Setup.dmg",
  win32:
    "https://aegisproductionstorage.blob.core.windows.net/current-version-win32/Aegis-Setup.exe",
  linux:
    "https://aegisproductionstorage.blob.core.windows.net/current-version-linux/Aegis-Setup.deb",
} as const;

interface IPlatformOperationsProps {
  platform: Exclude<SupportedPlatform, "all">;
  releaseAppInfoQuery: UseQueryResult<AppInfoResponse, Error>;
  downloadLink?: string;
}

export default function PlatformOperations({
  platform,
  releaseAppInfoQuery,
  downloadLink,
}: IPlatformOperationsProps) {
  const tenantId = useParams().tenantId as string;
  const { setTenantId, setPlatform, onOpen } = useContext(SpModalContext);
  const { getInternalApiToken } = useMicrosoftToken();

  const [pvIsLoading, setPvIsLoading] = useState<boolean>(false);
  const [intuneIsLoading, setIntuneIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const handleUpdateSp = () => {
    setTenantId(tenantId);
    setPlatform(platform);
    onOpen();
  };

  const handlePushVersion = async () => {
    setPvIsLoading(true);
    const token = await getInternalApiToken();
    const version = releaseAppInfoQuery.data![platform].data.version;
    try {
      await pushLatestApp(token, tenantId, platform, version);
      setPvIsLoading(false);
      await getAppConfig(token, tenantId);
    } catch (error) {
      setPvIsLoading(false);
      alert((error as Error).message);
    }
  };

  const handlePushIntune = async () => {
    setIntuneIsLoading(true);
    const token = await getInternalApiToken();
    try {
      const status = await pushIntuneApp(token, tenantId, platform);
      if (status.result !== "error") {
        alert(`Successfully ${status.result} intune app`);
      } else {
        alert(`Received status ${status.result}`);
      }
    } catch (error) {
      alert((error as Error).message);
    }
    setIntuneIsLoading(false);
  };
  return (
    <Box marginY={6} marginX={4}>
      <HStack mt={4} spacing={4} mb={4}>
        <Button
          colorScheme="blue"
          variant="outline"
          size="sm"
          isLoading={pvIsLoading}
          onClick={handlePushVersion}
        >
          Push latest version
        </Button>
        <Button
          colorScheme="blue"
          variant="outline"
          size="sm"
          onClick={handleUpdateSp}
        >
          Update staging percentage
        </Button>
        {downloadLink && (
          <>
            <Button
              leftIcon={<DownloadIcon />}
              colorScheme="blue"
              variant="outline"
              size="sm"
              isLoading={downloadLink === undefined}
              onClick={() => window.open(downloadLink, "_blank")}
            >
              Download
            </Button>
            <Button
              leftIcon={<CopyIcon />}
              colorScheme="blue"
              variant="outline"
              size="sm"
              onClick={async () => {
                await navigator.clipboard.writeText(ReleaseLinks[platform]);
                toastSuccess(toast, "Copied release download link");
              }}
            >
              Copy release download link
            </Button>
            <Button
              leftIcon={<CopyIcon />}
              colorScheme="blue"
              variant="outline"
              size="sm"
              isLoading={downloadLink === undefined}
              onClick={async () => {
                await navigator.clipboard.writeText(downloadLink as string);
                toastSuccess(toast, "Copied tenant download link");
              }}
            >
              Copy tenant download link
            </Button>
          </>
        )}
        {platform === SupportedPlatforms.WIN && (
          <Button
            colorScheme="blue"
            variant="outline"
            size="sm"
            isLoading={intuneIsLoading}
            onClick={handlePushIntune}
          >
            Push Intune app
          </Button>
        )}
      </HStack>
    </Box>
  );
}
