import { InfoIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Td,
  Tooltip,
  Icon,
  Flex,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  toastSuccess,
  toastError,
} from "../../../../../Providers/ToastProvider";
import { AegisUser } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";

export default function UserBasicInfo({
  aegisUser,
  error,
  toast,
}: Readonly<{
  aegisUser: AegisUser;
  error: Error | null;
  toast: ReturnType<typeof useToast>;
}>) {
  const handleCopyToClipboard = async (copyText: string) => {
    try {
      await navigator.clipboard.writeText(copyText);
      // Read clipboard text
      const clipText = await navigator.clipboard.readText();
      const editorElement: HTMLElement | null =
        document.querySelector(".editor");
      if (editorElement) {
        editorElement.innerText += clipText;
      }
      toastSuccess(toast, "Copied to clipboard");
    } catch (error) {
      toastError(toast, error);
    }
  };
  return (
    <>
      <Td>
        <Flex alignItems="center" gap="4px">
          <Tooltip
            label={`${aegisUser.displayName} ${
              (!aegisUser.active && "(inactive)") || ""
            }`}
          >
            <Text
              as="span"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              display="inline-block"
              maxWidth="120px"
              cursor="pointer"
              color={aegisUser.active ? "black" : "gray.400"}
            >
              {aegisUser.displayName}
            </Text>
          </Tooltip>
          {error && (
            <Tooltip label={error.message}>
              <Icon as={InfoIcon} color="red" />
            </Tooltip>
          )}
        </Flex>
      </Td>
      <Td>
        <Flex align="center">
          <Tooltip
            label={`${aegisUser.email} ${
              (!aegisUser.active && "(inactive)") || ""
            }`}
          >
            <Text
              as="span"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              display="inline-block"
              maxWidth="120px"
              cursor="pointer"
              color={aegisUser.active ? "black" : "gray.400"}
            >
              {aegisUser.email}
            </Text>
          </Tooltip>
          <Button
            size="sm"
            ml={2}
            onClick={() => handleCopyToClipboard(aegisUser.email)}
          >
            <Icon as={CopyIcon} />
          </Button>
        </Flex>
      </Td>
    </>
  );
}
