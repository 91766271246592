import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { AccountSetupUser } from "../services/atomus-internal-apis/atomus-internal-apis.types";
import { generateRandomMsftPassword } from "../utils/account-setup.utils";
import { DeviceOS, DeviceOSValues } from "../types/api.types";
import Papa from "papaparse";

const EXPECTED_COLS = [
  "firstName",
  "lastName",
  "email",
  "Windows",
  "Mac",
  "Linux",
  "iOS",
  "Android",
] as const;

interface UsersCsvRow {
  firstName: string;
  lastName: string;
  username?: string;
  email: string;
  Windows: string;
  Mac: string;
  Linux: string;
  iOS: string;
  Android: string;
}

function validateColumnLine(line: string[]): void {
  if (line.length !== EXPECTED_COLS.length) {
    throw new Error(
      `Number of columns is not the expected amount of ${EXPECTED_COLS.length}`
    );
  }
  for (let idx = 0; idx < line.length; idx++) {
    if (line[idx] !== EXPECTED_COLS[idx]) {
      throw new Error(
        `Invalid column at position ${idx}: expected "${EXPECTED_COLS[idx]}", found ${line[idx]}`
      );
    }
  }
}

function convertCsvData(
  csvData: UsersCsvRow[],
  skipTempPass: boolean
): AccountSetupUser[] {
  const newUsers: AccountSetupUser[] = [];
  for (const row of csvData) {
    const username = (row.username || row.email?.split("@")[0])
      ?.toLowerCase()
      .trim();
    const user: AccountSetupUser = {
      devices: [],
      email: row.email?.toLowerCase().trim(),
      firstName: row.firstName.trim(),
      lastName: row.lastName.trim(),
      msftTempPass: skipTempPass ? "N/A" : generateRandomMsftPassword(),
      numberOfDevices: 0,
      phone: "",
      username: username,
      userType: "user",
    };
    for (const os of Object.keys(DeviceOSValues) as DeviceOS[]) {
      if (row[os]) {
        user.devices.push(DeviceOSValues[os]);
        user.numberOfDevices++;
      }
    }
    newUsers.push(user);
  }
  return newUsers;
}

export function useParseUsersCsv(msftDomain: string, skipTempPass = false) {
  const toast = useToast();

  const parseUsers = useCallback(
    async (file: File) => {
      let newUsers: AccountSetupUser[] = [];
      await new Promise<void>((resolve, reject) => {
        // callback when papa-parse successfully parses the csv
        const handleComplete = (results: Papa.ParseResult<UsersCsvRow>) => {
          if (!results.meta.fields) {
            toast({
              title: "Error parsing CSV",
              description: "Header names were not returned",
              status: "error",
              position: "top-right",
            });
            reject(new Error("Header names were not returned"));
            return;
          }
          try {
            // confirm headers are correct
            validateColumnLine(results.meta.fields);
            newUsers = convertCsvData(results.data, skipTempPass);
            toast({
              title: "Successfully uploaded users",
              description: "Make sure to validate all fields before continuing",
              status: "success",
              position: "top-right",
            });
            resolve();
          } catch (error) {
            toast({
              title: "Error parsing CSV",
              description:
                (error as Error).message || "An unknown error occurred",
              status: "error",
              position: "top-right",
            });
            reject(error);
          }
        };

        // callback when papa-parse encounters an error while parsing the csv
        const handleError = (error: Error) => {
          toast({
            title: "Error parsing CSV",
            description: `The parser encountered an error: ${error.message}`,
            status: "error",
            position: "top-right",
          });
          reject(error);
        };

        Papa.parse<UsersCsvRow, File>(file, {
          header: true,
          delimiter: ",",
          complete: handleComplete,
          error: handleError,
          skipEmptyLines: true,
        });
      });
      return newUsers;
    },
    [skipTempPass, toast]
  );

  return { parseUsers };
}
