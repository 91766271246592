import { ReadyToOnboardCheckId } from "../../../../../../../types/api.types";
import { OnboardingOption } from "./types";

const APP_REQUIRED_CHECKS: ReadyToOnboardCheckId[] = [
  "aegisAppConsentGranted",
  "azUserFound",
  "companyGroupFound",
  "duoUserExists",
  "userHasAppConfigReader",
  "userHasCloudId",
  "userHasLicense",
  "userIsActive",
  "userIsInCompanyGroup",
  "userKeyVaultExists",
];

export const RequiredOnboardingChecks: Record<
  OnboardingOption,
  ReadyToOnboardCheckId[]
> = {
  android: ["azUserFound", "userHasCloudId", "userHasLicense", "userIsActive"],
  iOS: ["azUserFound", "userHasCloudId", "userHasLicense", "userIsActive"],
  linux: APP_REQUIRED_CHECKS,
  macNewDevice: [...APP_REQUIRED_CHECKS, "userHasAppleCreds"],
  macPartition: [...APP_REQUIRED_CHECKS, "userHasAppleCreds"],
  windowsAppOnly: APP_REQUIRED_CHECKS,
  windowsNewDevice: APP_REQUIRED_CHECKS,
  windowsPartition: APP_REQUIRED_CHECKS,
  windowsSwitchUser: APP_REQUIRED_CHECKS,
};

export const DEFAULT_SELECTION: Record<OnboardingOption, boolean> = {
  macPartition: false,
  macNewDevice: false,
  windowsPartition: false,
  windowsAppOnly: false,
  windowsSwitchUser: false,
  windowsNewDevice: false,
  iOS: false,
  android: false,
  linux: false,
} as const;

export const CHECKR_COMPLETE = "complete";
