import { Box, Flex } from "@chakra-ui/react";
import PageContainer from "../../Components/PageContainer";
import GlobalChangeMgmtBody from "./GlobalChangeMgmtBody";
import GlobalChangeMgmtHeader from "./GlobalChangeMgmtHeader";

export default function GlobalChangeMgmtPage() {
  return (
    <PageContainer>
      <Flex justifyContent="center" alignItems="center" height="70vh">
        <Box width="6xl">
          <GlobalChangeMgmtHeader />
          <Box mt={6}>
            <GlobalChangeMgmtBody />
          </Box>
        </Box>
      </Flex>
    </PageContainer>
  );
}
