import { Box, Center, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import { ApiActivityRow } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";
import {
  BsPlusCircleFill,
  BsFillTrash3Fill,
  BsQuestionCircleFill,
} from "react-icons/bs";
import { useCallback } from "react";

interface IActivityEntryProps {
  entry: ApiActivityRow;
  setModalEntry: React.Dispatch<React.SetStateAction<ApiActivityRow | null>>;
  modalOnOpen: () => void;
}

const iconMap = (method: string) => {
  if (method === "PUT" || method === "POST" || method === "PATCH") {
    return { color: "green.400", iconComponent: BsPlusCircleFill };
  } else if (method === "DELETE") {
    return { color: "yellow.400", iconComponent: BsFillTrash3Fill };
  } else {
    return { color: "red.400", iconComponent: BsQuestionCircleFill };
  }
};

export default function ActivityEntry({
  entry,
  setModalEntry,
  modalOnOpen,
}: IActivityEntryProps) {
  const iconInfo = iconMap(entry.method_s);
  const openModal = useCallback(() => {
    setModalEntry(entry);
    modalOnOpen();
  }, [entry, setModalEntry, modalOnOpen]);
  return (
    <Box
      marginY={2}
      padding={2}
      borderRadius="md"
      _hover={{ cursor: "pointer", backgroundColor: "blackAlpha.200" }}
      onClick={openModal}
    >
      <Flex gap={2} alignItems="start">
        <Box paddingTop={1}>
          <Icon as={iconInfo.iconComponent} color={iconInfo.color} />
          <Center marginY={1}>
            <Divider orientation="vertical" height="50px" />
          </Center>
        </Box>
        <Box marginBottom={6}>
          <Flex gap={2}>
            <Text>
              <strong>{entry.displayName_s || "Unknown"}</strong> executed{" "}
              {entry.method_s} {entry.path_s}
            </Text>
          </Flex>
          <Text color="gray.500">
            on {new Date(entry.TimeGenerated).toString()}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
