import { useMsal } from "@azure/msal-react";
import { ReactNode, useContext, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import AuthContext from "./contexts/AuthContext";
import GlobalChangeMgmtPage from "./Pages/GlobalChangeMgmtPage";
import LoginPage from "./Pages/LoginPage";
import MsalRedirectPage from "./Pages/MsalRedirectPage/MsalRedirectPage";
import SecurityPage from "./Pages/SecurityPage";
import TenantOverviewPage from "./Pages/TenantOverviewPage";
import TenantsListPage from "./Pages/TenantsListPage";

export default function App() {
  const { setIsGov } = useContext(AuthContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const cloudInstance = searchParams.get("cloudInstance");
    if (cloudInstance) {
      setIsGov(cloudInstance === "gov");
    }
  }, [setIsGov]);

  const AuthTemplate = ({ children }: { children: ReactNode }) => {
    const { instance } = useMsal();
    const location = useLocation();
    if (location.pathname === "/msal-redirect") {
      return <></>;
    }
    if (instance.getActiveAccount()) {
      return <>{children}</>;
    }
    return <></>;
  };

  const UnAuthTemplate = ({ children }: { children: ReactNode }) => {
    const { instance } = useMsal();
    const location = useLocation();
    if (location.pathname === "/msal-redirect") {
      return <></>;
    }
    if (instance.getActiveAccount()) {
      return <></>;
    }
    return <>{children}</>;
  };

  return (
    <>
      <BrowserRouter>
        <AuthTemplate>
          <Routes>
            <Route path="/security" element={<SecurityPage />} />
            <Route path="/tenants" element={<TenantsListPage />} />
            <Route path="/change-mgmt" element={<GlobalChangeMgmtPage />} />
            <Route path="/tenants/:tenantId" element={<TenantOverviewPage />} />
            {/* REVIEW: what to do here if already logged in */}
            <Route path="/msal-redirect" element={<MsalRedirectPage />} />
            <Route path="*" element={<Navigate to="/tenants" />} />
          </Routes>
        </AuthTemplate>
        <UnAuthTemplate>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/msal-redirect" element={<MsalRedirectPage />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </UnAuthTemplate>
      </BrowserRouter>
    </>
  );
}
