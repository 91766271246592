import { Flex, Text } from "@chakra-ui/react";

export default function GlobalChangeMgmtHeader() {
  return (
    <Flex flexDirection="column" alignItems="center" ml={4}>
      <Text fontSize="xl" fontWeight="semibold" textAlign="center">
        Add new global change request
      </Text>
      <Text color="red" mt={2} textAlign="center">
        Use this tool cautiously! This will post to all tenants that you have
        access to.
      </Text>
    </Flex>
  );
}
