import { Flex, Box, Spacer, Button, Text, useToast } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { Buffer } from "buffer";
import { toastError } from "../../../Providers/ToastProvider";
import { AccountSetupContext } from "./AccountSetupContext";
import LocationTooltip from "./LocationTooltip";

const DEFENDER_STEP_IDS = [
  "assignMacOSDefenderLobAppToCompanyGroup",
  "assignMacOSDefenderOnboardingProfileToCompanyGroup",
  "assignMacOSDefenderSystemExtensionsProfileToCompanyGroup",
  "assignMacOSDefenderFDAProfileToCompanyGroup",
  "assignMacOSDefenderNetworkFilterToCompanyGroup",
  "assignMacOSDefenderNotificationsProfileToCompanyGroup",
  "assignMacOSDefenderBackgroundServicesProfileToCompanyGroup",
];

export default function MacOSDefenderSection() {
  const { runSteps, stepsAreRunning, accountInfo, setDefenderOnboardingPayload, defenderOnboardingPayload } =
    useContext(AccountSetupContext);
  const [defenderRunning, setDefenderRunning] = useState(false);
  const toast = useToast();

  const handleDefenderFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event.target.files?.item(0);
      if (
        !file?.name.endsWith(".xml") &&
        !file?.name.endsWith(".mobileconfig")
      ) {
        throw new Error("file extension must be '.xml' or '.mobileconfig'");
      } else {
        setDefenderOnboardingPayload(
          Buffer.from(await file.text()).toString("base64")
        );
      }
    } catch (error) {
      toastError(
        toast,
        `Error uploading defender file: ${(error as Error).message}`
      );
    }
  };

  const handleRunDefenderClick = async () => {
    if (!defenderOnboardingPayload) {
      return;
    }
    setDefenderRunning(true);
    await runSteps(false, DEFENDER_STEP_IDS);
    setDefenderRunning(false);
  };

  return (
    <Box marginY={2}>
      <Text fontSize="lg" fontWeight="semibold">
        Defender ATP MacOS Onboarding Payload
      </Text>
      <Flex marginY={2} alignItems="flex-end">
        <Box>
          <Text marginBottom="4px" fontWeight="semibold">
            Upload onboarding package
          </Text>
          <input type="file" onChange={handleDefenderFileChange} />
        </Box>
        <Spacer />
        <LocationTooltip>
          <Button
            colorScheme="blue"
            variant="ghost"
            size="sm"
            isLoading={defenderRunning}
            isDisabled={stepsAreRunning || !accountInfo?.location}
            onClick={handleRunDefenderClick}
          >
            Run MacOS Defender Setup
          </Button>
        </LocationTooltip>
      </Flex>
    </Box>
  );
}
