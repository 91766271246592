import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Flex,
  Spacer,
  Button,
  Table,
  Thead,
  Th,
  useDisclosure,
  Tbody,
  Tr,
  Td,
  Divider,
  useToast,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import useMicrosoftToken from "../../../../../hooks/tokens.hooks";
import {
  toastError,
  toastSuccess,
} from "../../../../../Providers/ToastProvider";
import { createExternalUsers } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  ExternalAzureUser,
  ExternalAzureUserWithId,
  ExternalUserInvitationReponse,
} from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import ExternalUsersModal from "./AddExternalUsersModal";
import InvitedUsersInfoSection from "./InvitedUsersInfoSection";

interface IExternalUsersSectionProps {
  tenantId: string;
  refetchFunc: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ExternalAzureUserWithId[], Error>>;
  invitationResults: ExternalUserInvitationReponse[] | undefined;
  setInvitationResults: React.Dispatch<
    React.SetStateAction<ExternalUserInvitationReponse[] | undefined>
  >;
}

export default function ExternalUsersSection({
  tenantId,
  refetchFunc,
  invitationResults,
  setInvitationResults,
}: Readonly<IExternalUsersSectionProps>) {
  const [externalUsers, setExternalUsers] = useState<ExternalAzureUser[]>([]);
  const [isContentVisible, setContentVisible] = useState<boolean>(false);
  const [sendButtonLoading, setSendButtonLoading] = useState<boolean>(false);
  const externalUsersModalProps = useDisclosure();

  const { getInternalApiToken } = useMicrosoftToken();
  const toastProvider = useToast();

  const handleToggleContent = () => {
    setContentVisible((prevState) => !prevState);
  };

  const removeExternalUser = (idx: number) => {
    const newArr = [...externalUsers];
    newArr.splice(idx, 1);
    setExternalUsers(newArr);
  };

  const handleSendInvitations = async () => {
    setSendButtonLoading(true);
    try {
      const token = await getInternalApiToken();
      const results = await createExternalUsers(token, tenantId, externalUsers);
      setInvitationResults([...(invitationResults ?? []), ...results]);
      setContentVisible(true);
      setExternalUsers([]);
      toastSuccess(toastProvider, "Invitations sent!");
      refetchFunc();
    } catch (err) {
      toastError(toastProvider, err);
    }
    setSendButtonLoading(false);
  };

  return (
    <>
      <ExternalUsersModal
        isOpen={externalUsersModalProps.isOpen}
        onClose={externalUsersModalProps.onClose}
        externalUsers={externalUsers}
        setExternalUsers={setExternalUsers}
      />
      <Flex alignItems="baseline" flexDir="row" marginBottom={4}>
        <Text marginTop={3} fontSize="xl" fontWeight="semibold">
          Invitations
        </Text>
        <Flex
          margin={4}
          alignItems="center"
          cursor="pointer"
          onClick={handleToggleContent}
          alignSelf="flex-end"
        >
          <Icon
            as={isContentVisible ? ChevronUpIcon : ChevronDownIcon}
            boxSize={6}
            color="blue.500"
          />
        </Flex>
        <Spacer />
      </Flex>
      {isContentVisible && (
        <>
          {externalUsers.length > 0 ? (
            <Table
              marginY={2}
              __css={{ "table-layout": "fixed", width: "75%" }}
            >
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {externalUsers.map((user, idx) => (
                  <Tr key={user.userPrincipalName}>
                    <Td>{user.displayName}</Td>
                    <Td>{user.userPrincipalName}</Td>
                    <Td>
                      <Button
                        size="sm"
                        colorScheme="red"
                        variant="ghost"
                        onClick={() => removeExternalUser(idx)}
                      >
                        Remove
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Divider marginY={2} />
          )}
          <Flex marginBottom={8}>
            <Button
              isDisabled={externalUsers.length === 0}
              colorScheme="blue"
              isLoading={sendButtonLoading}
              onClick={handleSendInvitations}
            >
              Send Invitations
            </Button>
            <Button
              variant="ghost"
              colorScheme="blue"
              onClick={externalUsersModalProps.onOpen}
              marginLeft={2}
            >
              Add user
            </Button>
          </Flex>
        </>
      )}
      {invitationResults && (
        <InvitedUsersInfoSection invitationResults={invitationResults} />
      )}
    </>
  );
}
