import {
  Box,
  Button,
  Table,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { GetStepResult } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";

interface IAccountSetupTableProps {
  buttonText: string;
  steps: Record<string, GetStepResult>;
  buttonsAreDisabled: boolean;
  buttonFunction: (stepStatus: [string, GetStepResult]) => void;
}

const TAG_COLOR_SCHEMES: Record<GetStepResult, string> = {
  complete: "green",
  error: "red",
  incomplete: "orange",
} as const;

export default function AccountSetupTable({
  buttonText,
  steps,
  buttonsAreDisabled,
  buttonFunction,
}: IAccountSetupTableProps) {
  const statusSort = (a: GetStepResult, b: GetStepResult) => {
    if (a === b) {
      return 0;
    }
    if (a === "error") {
      return -1;
    }
    if (b === "error") {
      return 1;
    }
    if (a === "incomplete") {
      return -1;
    }
    return 1;
  };

  return (
    <Box>
      <Table>
        <Thead>
          <Tr>
            <Th>{buttonText}</Th>
            <Th>Step ID</Th>
            <Th>Step status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(steps)
            .sort((a, b) => {
              if (a[1] === b[1]) {
                return a[0].toLowerCase().localeCompare(b[0].toLowerCase());
              }

              return statusSort(a[1], b[1]);
            })
            .map((stepStatus, idx) => (
              <Tr key={idx}>
                <Td>
                  <Button
                    size="sm"
                    variant="ghost"
                    colorScheme="blue"
                    isDisabled={buttonsAreDisabled}
                    onClick={() => buttonFunction(stepStatus)}
                  >
                    {buttonText}
                  </Button>
                </Td>
                <Td>{stepStatus[0]}</Td>
                <Td>
                  <Tag colorScheme={TAG_COLOR_SCHEMES[stepStatus[1]]}>
                    {stepStatus[1]}
                  </Tag>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Box>
  );
}
