import {
  Box,
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import useMicrosoftToken from "../../../../../../hooks/tokens.hooks";
import { toastError } from "../../../../../../Providers/ToastProvider";
import { deactivateUser } from "../../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { AegisUser } from "../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { UserTableContext } from "../../../../UserTableContext";

interface IConfirmDeactivateModal {
  user: AegisUser;
  tenantId: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function ConfirmDeactivateModal({
  user,
  tenantId,
  isOpen,
  onClose,
}: Readonly<IConfirmDeactivateModal>) {
  const [isLoading, setIsLoading] = useState(false);
  const { setUsersArr } = useContext(UserTableContext);

  const { getInternalApiToken } = useMicrosoftToken();
  const toastProvider = useToast();

  const handleConfirmation = async () => {
    setIsLoading(true);
    const token = await getInternalApiToken();
    try {
      await deactivateUser(token, tenantId, user.id);
      setUsersArr((prevUsers) =>
        prevUsers.map((prevUser) =>
          prevUser.id === user.id ? { ...prevUser, active: false } : prevUser
        )
      );
    } catch (err) {
      toastError(toastProvider, err);
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4} align="center" margin={3}>
            <Box>
              <Heading size="md">{`Mark ${user.displayName} inactive?`}</Heading>
            </Box>
            <Center>
              <Stack direction="row" spacing={4}>
                <Button
                  colorScheme="red"
                  onClick={handleConfirmation}
                  isLoading={isLoading}
                >
                  Confirm
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
              </Stack>
            </Center>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
