import { Skeleton, Tag, Td } from "@chakra-ui/react";
import { useDuoUser } from "../../../../../hooks/duo.hooks";
import { useOptionalFeatureStatus } from "../../../../../hooks/tenants.hooks";

export default function UserDeviceMfaInfo({
  tenantId,
  username,
}: Readonly<{
  tenantId: string;
  username: string;
}>) {
  const availabilityQuery = useOptionalFeatureStatus("duo:mfa", tenantId);
  const { data, error, isFetching } = useDuoUser(tenantId, username);

  let tagStr: string;
  let tagColor: string;
  if (!availabilityQuery.data?.availableForTenant) {
    tagStr = "N/A";
    tagColor = "gray";
  } else if (error) {
    tagStr = "error";
    tagColor = "red";
  } else if (!data) {
    tagStr = "not found";
    tagColor = "red";
  } else if (!data.is_enrolled) {
    tagStr = "not enrolled";
    tagColor = "yellow";
  } else {
    tagStr = "enrolled";
    tagColor = "green";
  }

  return (
    <Td textAlign="center">
      <Skeleton isLoaded={!isFetching && !availabilityQuery.isFetching}>
        <Tag colorScheme={tagColor}>{tagStr}</Tag>
      </Skeleton>
    </Td>
  );
}
