import { UseQueryResult, useQuery } from "react-query";
import {
  getCheckrCandidates,
  getCheckrConnectionStatus,
  getCheckrStatus,
} from "../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  CheckrCandidate,
  UserCheckrStatusRow,
} from "../services/atomus-internal-apis/atomus-internal-apis.types";
import useMicrosoftToken from "./tokens.hooks";

export function useCheckrConnectionStatus(
  tenantId: string
): UseQueryResult<boolean, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const connectionQuery = useQuery<boolean, Error>(
    ["checkr-connection", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return getCheckrConnectionStatus(token, tenantId);
    }
  );
  return connectionQuery;
}

export function useCheckrCandidates(
  tenantId: string
): UseQueryResult<CheckrCandidate[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const candidatesQuery = useQuery<CheckrCandidate[], Error>(
    ["checkr-candidates", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return getCheckrCandidates(token, tenantId);
    }
  );
  return candidatesQuery;
}

export function useCheckrReportStatus(
  tenantId: string,
  checkrId: string | null
): UseQueryResult<UserCheckrStatusRow | null, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const reportsQuery = useQuery<UserCheckrStatusRow | null, Error>(
    ["checkr-report", checkrId],
    async () => {
      if (checkrId) {
        const token = await getInternalApiToken();
        return getCheckrStatus(token, tenantId, checkrId);
      } else {
        return null;
      }
    }
  );
  return reportsQuery;
}
