import {
  Button,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {
  createContext,
  Dispatch,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import useMicrosoftToken from "../../hooks/tokens.hooks";
import { updateStagingPercentage } from "../../services/atomus-internal-apis/atomus-internal-apis.service";
import { SupportedPlatform } from "../../services/atomus-internal-apis/atomus-internal-apis.types";

interface ISpModalContext {
  tenantId: string | null;
  setTenantId: Dispatch<SetStateAction<string | null>>;
  platform: SupportedPlatform | null;
  setPlatform: Dispatch<SetStateAction<SupportedPlatform | null>>;
  onOpen: () => void;
}

const defaultValue: ISpModalContext = {
  tenantId: null,
  setTenantId: () => {
    throw new Error("You must wrap your component in an SpModalProvider");
  },
  platform: null,
  setPlatform: () => {
    throw new Error("You must wrap your component in an SpModalProvider");
  },
  onOpen: () => {
    throw new Error("You must wrap your component in an SpModalProvider");
  },
};

export const SpModalContext = createContext<ISpModalContext>(defaultValue);

interface ISpModalProviderProps {
  children: ReactNode;
}

export default function TenantUpdateOperationsProvider({
  children,
}: ISpModalProviderProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  // context states
  const [tenantId, setTenantId] = useState<string | null>(null);
  const [platform, setPlatform] = useState<SupportedPlatform | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newPercent, setNewPercent] = useState<number>();

  const { getInternalApiToken } = useMicrosoftToken();

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    setIsLoading(true);
    event.preventDefault();
    if (!tenantId || !platform) {
      // should never happen
      alert(
        "Error: tenantId or platform is undefined please reload and try again."
      );
      return;
    }
    if (newPercent === undefined || isNaN(newPercent)) {
      alert("User must enter a value.");
    } else if (newPercent < 0 || newPercent > 100) {
      alert("New staging percentage must be between 0 and 100.");
    } else {
      try {
        const token = await getInternalApiToken();
        await updateStagingPercentage(token, tenantId, newPercent, platform);
        handleClose();
      } catch (error) {
        alert((error as Error).message);
      }
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setTenantId(null);
    setPlatform(null);
    setIsLoading(false);
    setNewPercent(NaN);
    onClose();
  };

  return (
    <SpModalContext.Provider
      value={{
        tenantId,
        setTenantId,
        platform,
        setPlatform,
        onOpen,
      }}
    >
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update staging percentage</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <FormControl mb={2}>
                <Input
                  id="newPercent"
                  type="number"
                  value={newPercent || ""}
                  placeholder="Enter new staging percentage (0-100)"
                  onChange={(e) => setNewPercent(parseInt(e.target.value))}
                />
              </FormControl>
              <Button
                type="submit"
                onClick={handleSubmit}
                mt={4}
                colorScheme="blue"
                isLoading={isLoading}
              >
                Submit
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      {children}
    </SpModalContext.Provider>
  );
}
