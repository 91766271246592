import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import PageContainer from "../../Components/PageContainer";
import RegKeysSection from "./RegKeysSection";

export default function SecurityPage() {

  return (
    <PageContainer>
      <Tabs variant="enclosed" colorScheme="blue">
        <TabList>
          <Tab>Registry Keys</Tab>
        </TabList>
        <TabPanels>
          <TabPanel><RegKeysSection /></TabPanel>
        </TabPanels>
      </Tabs>
    </PageContainer>
  );
}
