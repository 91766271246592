import { useMsal } from "@azure/msal-react";
import { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import { HealthcheckStatus } from "../services/atomus-internal-apis/atomus-internal-apis.types";
import { trackEvent } from "../services/atomus-internal-apis/logging/logging.service";
import useMicrosoftToken from "./tokens.hooks";

interface TrackParams {
  properties?: object;
  context?: object;
}

export function useTracking() {
  const authContext = useContext(AuthContext);
  const { tenantId } = useParams();
  const { getInternalApiToken } = useMicrosoftToken();
  const { instance } = useMsal();

  const track = useCallback(
    async (eventName: string, params: TrackParams) => {
      try {
        if (authContext.role !== "companyAdmin" || !tenantId) {
          return;
        }
        const account = instance.getActiveAccount();
        if (!account?.username) {
          return;
        }
        const token = await getInternalApiToken();
        await trackEvent(
          token,
          tenantId,
          account.username,
          eventName,
          params.properties,
          params.context
        );
      } catch (error) {}
    },
    [tenantId, getInternalApiToken, authContext.role, instance]
  );

  return {
    trackTabClick: (tab: string, childTab?: string) =>
      track("tab-clicked", { properties: { tab, childTab } }),
    trackDeviceClick: (deviceName: string, deviceUsers: string) =>
      track("device-clicked", { properties: { deviceName, deviceUsers } }),
    trackHealthcheckClick: (
      deviceName: string,
      stepName: string,
      stepStatus: HealthcheckStatus
    ) =>
      track("healthcheck-clicked", {
        properties: { deviceName, stepName, stepStatus },
      }),
    trackSendEmail: (emailType: string, sentTo: string, tenantId: string) =>
      track("send-email", { properties: { emailType, sentTo, tenantId } }),
  };
}
