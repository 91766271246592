import { createContext, ReactNode, useState, useMemo } from "react";
import {
  AegisUser,
  CheckrCandidate,
} from "../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../types/types";

export interface IUserTableContext {
  usersArr: AegisUser[];
  setUsersArr: StateSetter<AegisUser[]>;
  checkrCandidates: CheckrCandidate[];
  setCheckrCandidates: StateSetter<CheckrCandidate[]>;
  checkrCandidatesIsLoading: boolean;
  setCheckrCandidatesIsLoading: StateSetter<boolean>;
  checkrConnectionStatus: boolean;
  setCheckrConnectionStatus: StateSetter<boolean>;
}

const defaultError = () => {
  throw new Error("you must wrap your component in an UserTableContext");
};

const defaultValue: IUserTableContext = {
  usersArr: [],
  setUsersArr: defaultError,
  checkrCandidates: [],
  setCheckrCandidates: defaultError,
  checkrCandidatesIsLoading: false,
  setCheckrCandidatesIsLoading: defaultError,
  checkrConnectionStatus: false,
  setCheckrConnectionStatus: defaultError,
};

export const UserTableContext = createContext(defaultValue);

export const UserTableContextProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [usersArr, setUsersArr] = useState<AegisUser[]>([]);
  const [checkrCandidates, setCheckrCandidates] = useState<CheckrCandidate[]>(
    []
  );
  const [checkrCandidatesIsLoading, setCheckrCandidatesIsLoading] =
    useState<boolean>(false);
  const [checkrConnectionStatus, setCheckrConnectionStatus] =
    useState<boolean>(false);

  const contextValue = useMemo(() => {
    return {
      usersArr,
      setUsersArr,
      checkrCandidates,
      setCheckrCandidates,
      checkrCandidatesIsLoading,
      setCheckrCandidatesIsLoading,
      checkrConnectionStatus,
      setCheckrConnectionStatus,
    };
  }, [
    usersArr,
    setUsersArr,
    checkrCandidates,
    setCheckrCandidates,
    checkrCandidatesIsLoading,
    setCheckrCandidatesIsLoading,
    checkrConnectionStatus,
    setCheckrConnectionStatus,
  ]);

  return (
    <UserTableContext.Provider value={contextValue}>
      {children}
    </UserTableContext.Provider>
  );
};
