import { Navigate, useSearchParams } from "react-router-dom";

export default function MsalRedirectPage() {
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");
  if (state) {
    return <Navigate to={state} />;
  }
  return <Navigate to="/tenants" />;
}
