import { Td } from "@chakra-ui/react";

export default function UserNoAzureInfo() {
  return (
    <>
      <Td></Td>
      <Td></Td>
    </>
  );
}
