import {
  Grid,
  GridItem,
  Flex,
  Spacer,
  Button,
  Center,
  Text,
  Progress,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
  useAccountSetupAccountInfo,
  useAccountSetupStatuses,
} from "../../../hooks/tenants.hooks";
import { GetStepResult } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { AccountSetupContext } from "./AccountSetupContext";
import AccountSetupTable from "./AccountSetupTable";
import LocationTooltip from "./LocationTooltip";

export default function StepStatusSection({ tenantId }: { tenantId: string }) {
  const { accountInfo, stepsAreRunning, runSteps } =
    useContext(AccountSetupContext);
  const { refetch: refetchInfo } = useAccountSetupAccountInfo(tenantId);
  const {
    isFetching,
    data,
    refetch: refetchStatuses,
  } = useAccountSetupStatuses(tenantId);
  const [stepObj, setStepObj] = useState<Record<string, GetStepResult>>({});
  const [stepsToRun, setStepsToRun] = useState<Record<string, GetStepResult>>(
    {}
  );
  const [runningFull, setRunningFull] = useState(false);
  const [runningSelected, setRunningSelected] = useState(false);

  useEffect(() => {
    if (data) {
      const steps = data.reduce(
        (prev, stepStatus) => ({ ...prev, [stepStatus[0]]: stepStatus[1] }),
        {} as Record<string, GetStepResult>
      );
      setStepObj(steps);
      setStepsToRun({});
    }
  }, [data]);

  if (!accountInfo) {
    return <></>;
  }

  if (isFetching) {
    return <Progress isIndeterminate borderRadius="md" />;
  }

  const handleRefresh = () => {
    refetchInfo({ throwOnError: true });
    refetchStatuses({ throwOnError: true });
  };

  const addStep = (stepStatus: [string, GetStepResult]) => {
    setStepObj((prev) => {
      const next = { ...prev };
      delete next[stepStatus[0]];
      return next;
    });
    setStepsToRun((prev) => {
      const next = { ...prev };
      next[stepStatus[0]] = stepStatus[1];
      return next;
    });
  };

  const removeStep = (stepStatus: [string, GetStepResult]) => {
    setStepsToRun((prev) => {
      const next = { ...prev };
      delete next[stepStatus[0]];
      return next;
    });
    setStepObj((prev) => {
      const next = { ...prev };
      next[stepStatus[0]] = stepStatus[1];
      return next;
    });
  };

  return (
    <Grid
      templateAreas={`"steps stepsToRun"
                         "steps whitespace"`}
      gap={3}
      marginY={4}
    >
      <GridItem
        area="steps"
        border="1px solid"
        borderColor="gray.400"
        borderRadius="md"
        padding={2}
      >
        <Flex alignItems="baseline">
          <Text fontSize="lg" fontWeight="semibold">
            Account setup steps
          </Text>
          <Spacer />
          <Button
            colorScheme="blue"
            variant="ghost"
            size="sm"
            isDisabled={stepsAreRunning}
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </Flex>
        <AccountSetupTable
          buttonText="Run"
          steps={stepObj}
          buttonsAreDisabled={stepsAreRunning}
          buttonFunction={addStep}
        />
      </GridItem>
      <GridItem
        area="stepsToRun"
        border="1px solid"
        borderColor="gray.400"
        borderRadius="md"
        padding={2}
      >
        <Flex alignItems="baseline">
          <Text fontSize="lg" fontWeight="semibold">
            Steps to run
          </Text>
          <Spacer />
          <LocationTooltip>
            <Button
              colorScheme="blue"
              size="sm"
              variant="ghost"
              isDisabled={stepsAreRunning || !accountInfo.location}
              isLoading={runningFull}
              onClick={async () => {
                setRunningFull(true);
                await runSteps(true, []);
                setRunningFull(false);
              }}
            >
              Run full setup
            </Button>
          </LocationTooltip>
        </Flex>
        <AccountSetupTable
          buttonText="Remove"
          steps={stepsToRun}
          buttonsAreDisabled={stepsAreRunning}
          buttonFunction={removeStep}
        />
        {Object.keys(stepsToRun).length > 0 && (
          <Center marginY={2}>
            <LocationTooltip>
              <Button
                colorScheme="blue"
                isDisabled={stepsAreRunning || !accountInfo.location}
                isLoading={runningSelected}
                onClick={async () => {
                  setRunningSelected(true);
                  await runSteps(false, Object.keys(stepsToRun));
                  setRunningSelected(false);
                }}
              >
                Run selected steps
              </Button>
            </LocationTooltip>
          </Center>
        )}
      </GridItem>
    </Grid>
  );
}
