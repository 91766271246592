import { Box, Skeleton, HStack, Tag, Text } from "@chakra-ui/react";
import { useDnsStatuses } from "../../../hooks/tenants.hooks";

export default function DnsSection({ tenantId }: { tenantId: string }) {
  const { data, isError, error } = useDnsStatuses(tenantId);
  return (
    <Box>
      <Text fontSize="xs" fontWeight="bold">
        DNS security
      </Text>
      {!isError ? (
        <Skeleton isLoaded={!!data} mb={1}>
          <HStack mb={1}>
            <Tag colorScheme={data?.dmarc === 1 ? "green" : "red"} size="sm">
              DMARC
            </Tag>
            <Tag colorScheme={data?.rms === 1 ? "green" : "red"} size="sm">
              RMS
            </Tag>
            <Tag colorScheme={data?.spf === 1 ? "green" : "red"} size="sm">
              SPF
            </Tag>
            <Tag colorScheme={data?.cname === 1 ? "green" : "red"} size="sm">
              CNAME
            </Tag>
            <Tag colorScheme={data?.mx === 1 ? "green" : "red"} size="sm">
              MX
            </Tag>
          </HStack>
        </Skeleton>
      ) : (
        <Text color="red" mt={1}>
          DNS error: {error.message}
        </Text>
      )}
    </Box>
  );
}
