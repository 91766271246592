import { useMsal } from "@azure/msal-react";
import { ExternalLinkIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { MsalEnvironments } from "../../../../constants/auth.constants";
import { MsBaseUrls } from "../../../../constants/microsoft.constants";
import AuthContext from "../../../../contexts/AuthContext";
import { useTenantOnboarding } from "../../../../hooks/tenants.hooks";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { getSubscriptionId } from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { navigateToAdminConsent } from "../../../../utils/utils";

interface ITenantLinkMenuProps {
  tenantId: string;
}

export default function TenantLinkMenu({
  tenantId,
}: Readonly<ITenantLinkMenuProps>) {
  const { getInternalApiToken } = useMicrosoftToken();
  const { instance } = useMsal();
  const { isGov } = useContext(AuthContext);

  const baseUrl = useMemo(
    () =>
      instance.getActiveAccount()?.environment === MsalEnvironments.GOV
        ? MsBaseUrls.gov.AZ_PORTAL
        : MsBaseUrls.commercial.AZ_PORTAL,
    [instance]
  );

  const subscriptionUrlQuery = useQuery<string, Error>(
    [tenantId, "subscription-id"],
    async () => {
      const token = await getInternalApiToken();
      const subId = (await getSubscriptionId(token, tenantId)).subscriptionId;
      return `${baseUrl}/#@${tenantId}/resource/subscriptions/${subId}`;
    }
  );

  const onboardingQuery = useTenantOnboarding(tenantId);

  const openLink = (link: string, target = "_blank") => {
    window.open(link, target);
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="tenant-link-menu"
        icon={<HamburgerIcon />}
        variant="ghost"
        size="sm"
      />
      <MenuList>
        <MenuItem
          icon={<ExternalLinkIcon />}
          onClick={() =>
            openLink(
              `${baseUrl}/${tenantId}/#view/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/~/SignIns`
            )
          }
        >
          View sign-in logs
        </MenuItem>
        <MenuItem
          icon={<ExternalLinkIcon />}
          onClick={() => navigateToAdminConsent(tenantId, isGov)}
        >
          Admin consent page
        </MenuItem>
        {subscriptionUrlQuery.data && (
          <MenuItem
            icon={<ExternalLinkIcon />}
            onClick={() => openLink(subscriptionUrlQuery.data)}
          >
            View subscription
          </MenuItem>
        )}
        {onboardingQuery.data?.planLink && (
          <MenuItem
            icon={<ExternalLinkIcon />}
            onClick={() => openLink(onboardingQuery.data!.planLink!)}
          >
            Onboarding plan
          </MenuItem>
        )}
        <MenuItem
          icon={<ExternalLinkIcon />}
          onClick={() => {
            const baseUrl = isGov
              ? "https://intune.microsoft.us"
              : "https://intune.microsoft.com";
            openLink(`${baseUrl}/${tenantId}`);
          }}
        >
          Intune portal
        </MenuItem>
        {!isGov && (
          <MenuItem
            icon={<ExternalLinkIcon />}
            onClick={() =>
              openLink(`https://mto.security.microsoft.com?tid=${tenantId}`)
            }
          >
            Security portal
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}
