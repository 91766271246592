import { useQuery, UseQueryResult } from "react-query";
import { Invoice } from "@azure/arm-billing";
import useMicrosoftToken from "./tokens.hooks";
import { getInvoices } from "../services/atomus-internal-apis/atomus-internal-apis.service";

export function useInvoices(
  tenantId: string
): UseQueryResult<Invoice[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<Invoice[], Error>(["invoices", tenantId], async () => {
    const token = await getInternalApiToken();
    return getInvoices(token, tenantId);
  });
}
