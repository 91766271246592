import { Flex } from "@chakra-ui/react";
import { useCallback } from "react";
import BackupPreferencesForm from "../../../../../../../Components/preferences/BackupPreferencesForm/BackupPreferencesForm";
import {
  useCompanyBackupPreferences,
  useUpdateCompanyBackupPreferences,
} from "../../../../../../../hooks/preferences.hooks";
import { useTenantId } from "../../../../../../../hooks/utils.hooks";
import { BackupPreferences } from "../../../../../../../types/backup-preferences.types";

export default function BackupSection({
  username,
}: Readonly<{ username: string }>) {
  const tenantId = useTenantId();
  const { data, isFetching, error, refetch } =
    useCompanyBackupPreferences(tenantId);

  const { mutateAsync } = useUpdateCompanyBackupPreferences(tenantId);

  const updatePrefs = useCallback(
    (values: BackupPreferences) => {
      const newUserPrefs = data?.userPreferences
        ? { ...data?.userPreferences }
        : {};
      newUserPrefs[username] = values;
      return mutateAsync({ ...data, userPreferences: newUserPrefs });
    },
    [data, mutateAsync, username]
  );

  return (
    <Flex flexDir="column" gap="8px">
      <BackupPreferencesForm
        queryData={data?.userPreferences?.[username]}
        queryError={error}
        queryFetching={isFetching}
        updatePrefs={updatePrefs}
        reloadPrefs={() => refetch({ throwOnError: true })}
        description="These preferences will only apply to the specified user. They will
          override company-wide preferences. Users will always be able to
          manually run backups from the Aegis app regardless of these
          preferences."
        resetConfirmMsg="The user will now use the company-wide settings."
      />
    </Flex>
  );
}
