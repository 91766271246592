import RegKeysTable from "../../../Components/RegKeysTable";
import { Text } from "@chakra-ui/react"
import RegKeyOperations from "../../../Components/RegKeyOperations";

export default function RegKeysSection() {
  return (
    <>
      <Text fontSize="xl" fontWeight="semibold" mb={2}>
        Global Registry Keys
      </Text>
      <RegKeyOperations tenantId="global" />
      <RegKeysTable tenantId="global" />
    </>
  );
}
