import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  chakra,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { Column, useSortBy, useTable } from "react-table";
import useMicrosoftToken from "../../../hooks/tokens.hooks";
import { useInvoices } from "../../../hooks/invoices.hooks";
import { getInvoiceDownloadUrl } from "../../../services/microsoft-apis.service";

interface IInvoicesSectionProps {
  tenantId: string;
}

interface IInvoiceTableRow {
  name: string;
  totalAmount: number;
  downloadUrl: string;
}

export default function InvoicesSection({ tenantId }: IInvoicesSectionProps) {
  const invoicesQuery = useInvoices(tenantId);

  const data = useMemo<IInvoiceTableRow[]>(
    () =>
      invoicesQuery.data?.map((invoice) => ({
        name: invoice.name as string,
        totalAmount: invoice.totalAmount?.value as number,
        downloadUrl: invoice.documents?.at(0)?.url as string,
      })) || [],
    [invoicesQuery.data]
  );

  const columns = useMemo(
    (): Column<IInvoiceTableRow>[] => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Total amount",
        accessor: "totalAmount",
        Cell: (cellInfo) => <Text>${cellInfo.cell.value}</Text>,
      },
      {
        Header: "Download",
        accessor: "downloadUrl",
        Cell: (cellInfo) => <DownloadButton downloadUrl={cellInfo.value} />,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data, columns }, useSortBy);

  if (invoicesQuery.isLoading || invoicesQuery.isRefetching) {
    return <Progress isIndeterminate borderRadius="md" />;
  }

  if (invoicesQuery.isError) {
    return (
      <Center>
        <Text color="red">{invoicesQuery.error.message}</Text>
      </Center>
    );
  }

  return (
    <>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <chakra.span pl={4}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
        <Tbody></Tbody>
      </Table>
    </>
  );
}

function DownloadButton({ downloadUrl }: { downloadUrl: string }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getArmToken } = useMicrosoftToken();

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const token = await getArmToken();
      const url = await getInvoiceDownloadUrl(token, downloadUrl);
      window.open(url, "_self");
      setIsLoading(false);
    } catch (error) {
      alert((error as Error).message);
      setIsLoading(false);
    }
  };
  return (
    <Button size="sm" onClick={handleClick} isLoading={isLoading}>
      Download
    </Button>
  );
}
