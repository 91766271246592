import { useQuery, UseQueryResult } from "react-query";
import {
  getAegisUsers,
  getAzureUserInfo,
  getExternalAtomusUsers,
  getOnboardingCheckResult,
  getUserReadyToOnboardDbDevices,
} from "../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  AegisUser,
  AzureUserInfo,
  ExternalAzureUserWithId,
  UserReadyForOnboardingRes,
} from "../services/atomus-internal-apis/atomus-internal-apis.types";
import { OnboardingCheckResult } from "../types/api.types";
import useMicrosoftToken from "./tokens.hooks";

export function useAegisUsers(
  tenantId: string
): UseQueryResult<AegisUser[], Error> {
  const usersQuery = useQuery<AegisUser[], Error>(
    ["users", tenantId],
    async () => {
      return getAegisUsers(tenantId);
    }
  );
  return usersQuery;
}

export function useAzureUserInfo(
  tenantId: string,
  userId: string
): UseQueryResult<AzureUserInfo, Error> {
  const { data: userData } = useAegisUsers(tenantId);
  const { getInternalApiToken } = useMicrosoftToken();
  const usersQuery = useQuery<AzureUserInfo, Error>(
    ["msftUsers", tenantId, userId],
    async () => {
      const token = await getInternalApiToken();
      const res = await getAzureUserInfo(token, tenantId, userId);
      return res;
    },
    { enabled: Boolean(userData) }
  );
  return usersQuery;
}

export function useUserIsReadyToOnboardDbDevices(
  tenantId: string,
  userId: string
): UseQueryResult<UserReadyForOnboardingRes, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<UserReadyForOnboardingRes, Error>(
    ["ready-to-onboard-db-devices", userId, tenantId],
    async () => {
      const token = await getInternalApiToken();
      return getUserReadyToOnboardDbDevices(token, tenantId, userId);
    }
  );
}

export function useUserOnboardingCheckResult(
  tenantId: string,
  userId: string
): UseQueryResult<OnboardingCheckResult, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<OnboardingCheckResult, Error>(
    ["onboarding-check-results", userId, tenantId],
    async () => {
      const token = await getInternalApiToken();
      return getOnboardingCheckResult(token, tenantId, userId);
    }
  );
}

export function useExternalAtomusUsers(
  tenantId: string
): UseQueryResult<ExternalAzureUserWithId[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const usersQuery = useQuery<ExternalAzureUserWithId[], Error>(
    ["externalAtomusUsers", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return await getExternalAtomusUsers(token, tenantId);
    },
    { refetchOnMount: true }
  );
  return usersQuery;
}
