import { sendPost } from "../atomus-internal-apis.service";

export async function identifyUser(
  token: string,
  tenantId: string,
  email: string
): Promise<void> {
  try {
    const path = `/tenants/${tenantId}/logging/dashboard/identify`;
    const body = { email };
    await sendPost(path, token, body);
  } catch (error) {
    // REVIEW: do we want to do something here?
  }
}

export async function trackEvent(
  token: string,
  tenantId: string,
  email: string,
  eventName: string,
  eventProperties?: object,
  eventContext?: object
): Promise<void> {
  try {
    const path = `/tenants/${tenantId}/logging/dashboard/event`;
    const body = { email, eventName, eventProperties, eventContext };
    await sendPost(path, token, body);
  } catch (error) {
    // REVIEW: do we want to do something here?
  }
}
