import { AuthRequiredTenant } from "../../services/atomus-internal-apis/atomus-internal-apis.types";
import {
  AzureCloudInstance,
  PublicClientApplication,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Td, Tr } from "@chakra-ui/react";
import { TokenScopes } from "../../services/atomus-internal-apis/atomus-internal-apis.constants";

interface ITenantAuthLinkProps {
  tenant: AuthRequiredTenant;
  refetchTenants: () => void;
}

export default function TenantAuthLink({
  tenant,
  refetchTenants,
}: ITenantAuthLinkProps) {
  const { instance } = useMsal();

  const handleAuth = async () => {
    const config = instance.getConfiguration();
    config.auth.azureCloudOptions.tenant = tenant.tenantId;
    const client = new PublicClientApplication(config);
    client
      .acquireTokenPopup({
        scopes:
          config.auth.azureCloudOptions.azureCloudInstance ===
          AzureCloudInstance.AzureUsGovernment
            ? [...TokenScopes.InternalApi.GOV]
            : [...TokenScopes.InternalApi.COMMERCIAL],
      })
      .then(async (res) => {
        sessionStorage.setItem("current-internals-token", res.accessToken);
        refetchTenants();
      });
  };
  return (
    <Tr
      _hover={{ cursor: "pointer", background: "gray.200" }}
      onClick={handleAuth}
    >
      <Td>{tenant.companyName}</Td>
      <Td>{tenant.tenantId}</Td>
    </Tr>
  );
}
