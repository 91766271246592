import { Box, Text } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { AppInfoResponse } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";
import SpModalProvider from "../../../contexts/SpModal";
import PlatformOverview from "./PlatformOverview";
import ConfigOverview from "./ConfigOverview";
import useMicrosoftToken from "../../../hooks/tokens.hooks";
import { SupportedPlatforms } from "../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import { getTenantAppInfo } from "../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { useTenantAppInfo } from "../../../hooks/tenants.hooks";

interface IUpdatesSectionProps {
  tenantId: string;
}

export default function UpdatesSection({ tenantId }: IUpdatesSectionProps) {
  const { getInternalApiToken } = useMicrosoftToken();

  const releaseAppInfo = useQuery<AppInfoResponse, Error>(
    ["release-app-info", SupportedPlatforms.ALL],
    async () => {
      const token = await getInternalApiToken();
      return getTenantAppInfo(token, "global", SupportedPlatforms.ALL);
    }
  );

  const tenantAppInfo = useTenantAppInfo(tenantId);

  return (
    <>
      <SpModalProvider>
        <Box marginY={4}>
          {releaseAppInfo.isError && (
            <Text color="red">
              Error getting release app info: {releaseAppInfo.error.message}
            </Text>
          )}
          {tenantAppInfo.isError && (
            <Text color="red">
              Error getting tenant app info: {tenantAppInfo.error.message}
            </Text>
          )}
          {!releaseAppInfo.isError && !tenantAppInfo.isError && (
            <>
              <ConfigOverview tenantId={tenantId} />
              <PlatformOverview
                platform={SupportedPlatforms.WIN}
                releaseAppInfoQuery={releaseAppInfo}
                tenantAppInfoQuery={tenantAppInfo}
              />
              <PlatformOverview
                platform={SupportedPlatforms.MAC}
                releaseAppInfoQuery={releaseAppInfo}
                tenantAppInfoQuery={tenantAppInfo}
              />
              <PlatformOverview
                platform={SupportedPlatforms.LINUX}
                releaseAppInfoQuery={releaseAppInfo}
                tenantAppInfoQuery={tenantAppInfo}
              />
            </>
          )}
        </Box>
      </SpModalProvider>
    </>
  );
}
