import {
  OnboardingCheckResult,
} from "../../../../../../../types/api.types";
import { RequiredOnboardingChecks } from "./constants";
import { OnboardingOption } from "./types";

export function getDisabledReasons(
  onboardingOption: OnboardingOption,
  checkResult: OnboardingCheckResult
): string | null {
  const requiredChecks = RequiredOnboardingChecks[onboardingOption];
  const failingChecks: string[] = [];
  for (const check of requiredChecks) {
    if (checkResult[check]) {
      failingChecks.push(checkResult[check]!);
    }
  }
  return failingChecks.length === 0 ? null : failingChecks.join(", ");
}
