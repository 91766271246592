import {
  Progress,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useRegistryKeys } from "../../hooks/tenants.hooks";

/**
 * @param tenantId id of the tenant to fetch the keys for. Can also equal the
 * special key 'global' to fetch the global configuration
 */
interface IRegKeysTableProps {
  tenantId: string;
}

export default function RegKeysTable({
  tenantId,
}: Readonly<IRegKeysTableProps>) {
  const sortOps = (a: any, b: any, key: string) => {
    a = a[1];
    b = b[1];
    if (a[key] && b[key]) {
      if (a[key] > b[key]) {
        return 1;
      }
      if (a[key] < b[key]) {
        return -1;
      }
      return 0;
    }
    if (a[key]) {
      return 1;
    }
    if (b[key]) {
      return -1;
    }
    return 0;
  };

  const keysQuery = useRegistryKeys(tenantId);

  if (keysQuery.isLoading || keysQuery.isRefetching) {
    return <Progress isIndeterminate borderRadius="md" />;
  }

  if (keysQuery.isError) {
    return <Text color="red">{keysQuery.error.message}</Text>;
  }

  return (
    <TableContainer marginY={2}>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Operation</Th>
            <Th>Path</Th>
            <Th>Name</Th>
            <Th>Type</Th>
            <Th>Value</Th>
          </Tr>
        </Thead>
        <Tbody>
          {keysQuery.data &&
            Object.entries(keysQuery.data)
              .sort((a, b) => sortOps(a, b, "Operation"))
              .reverse()
              .map((val) => {
                return (
                  <Tr key={val[0]}>
                    <Td>
                      <Text>{val[1].Operation}</Text>
                    </Td>
                    <Td>{val[1].Path}</Td>
                    <Td>
                      <Text>{(val[1] as any).Name || null}</Text>
                    </Td>
                    <Td>
                      <Text>{(val[1] as any).Type || null}</Text>
                    </Td>
                    <Td>
                      <Text>{(val[1] as any).Value || null}</Text>
                    </Td>
                  </Tr>
                );
              })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
