import {
  Button,
  Center,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDevices } from "../../../hooks/devices.hooks";
import { useTracking } from "../../../hooks/tracking.hooks";
import { IntuneMobileDeviceTypes } from "../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import DeviceDetailsModal from "./DeviceDetailsModal";
import DevicesTable from "./DevicesTable";
import DevicesToReviewTable from "./UnenrolledDevicesTable/DevicesToReviewTable";

interface IDevicesSectionProps {
  tenantId: string;
}

export default function DevicesSection({
  tenantId,
}: Readonly<IDevicesSectionProps>) {
  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();
  const [modalDeviceId, setModalDeviceId] = useState<string | null>(null);

  const { isLoading, isRefetching, isError, error, data, refetch } =
    useDevices(tenantId);
  const { trackTabClick } = useTracking();

  if (isLoading || isRefetching) {
    return <Progress isIndeterminate borderRadius="md" />;
  }

  if (isError) {
    return <Center>{error.message}</Center>;
  }

  return (
    <>
      <DeviceDetailsModal
        isOpen={modalIsOpen}
        onClose={modalOnClose}
        deviceId={modalDeviceId}
        tenantId={tenantId}
      />
      {data && (
        <Tabs variant="enclosed" colorScheme="blue" isLazy>
          <TabList>
            <Tab onClick={() => trackTabClick("Devices", "Atomus devices")}>
              Atomus devices
            </Tab>
            <Tab onClick={() => trackTabClick("Devices", "Non-Atomus devices")}>
              Non-Atomus devices
            </Tab>
            <Tab onClick={() => trackTabClick("Devices", "Disabled devices")}>
              Disabled devices
            </Tab>
            <Tab onClick={() => trackTabClick("Devices", "Mobile devices")}>
              Mobile devices
            </Tab>
            <Tab onClick={() => trackTabClick("Devices", "Devices to review")}>
              Devices to review
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {/* Atomus Devices */}
              <DevicesTable
                tenantId={tenantId}
                devices={data.filter(
                  (device) =>
                    device.accountEnabled &&
                    (device.operatingSystem === "Windows" ||
                      device.operatingSystem === "MacMDM" ||
                      device.operatingSystem === "Linux") && // Linux only exists here because currently only fetched from db (must be Atomus device)
                    device.registeredUsers?.find((user) => user.existsInDB && user.active)
                )}
                modalOnOpen={modalOnOpen}
                setModalDeviceId={setModalDeviceId}
              />
            </TabPanel>
            <TabPanel>
              {/* Non-Atomus Devices */}
              <DevicesTable
                tenantId={tenantId}
                devices={data.filter(
                  (device) =>
                    device.accountEnabled &&
                    (device.operatingSystem === "Windows" ||
                      device.operatingSystem === "MacMDM") &&
                    !device.registeredUsers?.find((user) => user.existsInDB && user.active)
                )}
                modalOnOpen={modalOnOpen}
                setModalDeviceId={setModalDeviceId}
              />
            </TabPanel>
            <TabPanel>
              {/* Disabled Devices */}
              <DevicesTable
                tenantId={tenantId}
                devices={data.filter(
                  (device) =>
                    !device.accountEnabled &&
                    (device.operatingSystem === "Windows" ||
                      device.operatingSystem === "MacMDM")
                )}
                modalOnOpen={modalOnOpen}
                setModalDeviceId={setModalDeviceId}
              />
            </TabPanel>
            <TabPanel>
              {/* Mobile Devices */}
              <DevicesTable
                tenantId={tenantId}
                devices={data.filter((device) =>
                  IntuneMobileDeviceTypes.includes(
                    device.operatingSystem.toLowerCase()
                  )
                )}
                modalOnOpen={modalOnOpen}
                setModalDeviceId={setModalDeviceId}
                mobileSection={true}
              />
            </TabPanel>
            <TabPanel>
              <DevicesToReviewTable tenantId={tenantId} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
      <Center mt={8}>
        <Button
          onClick={() => {
            refetch({ throwOnError: true });
          }}
          colorScheme="blue"
          variant="ghost"
        >
          Refresh
        </Button>
      </Center>
    </>
  );
}
