import { Skeleton, Tag } from "@chakra-ui/react";
import { useCallback } from "react";
import {
  useAccountSetupAccountInfo,
  useOptionalFeatureStatus,
} from "../../../../../hooks/tenants.hooks";
import useMicrosoftToken from "../../../../../hooks/tokens.hooks";
import { useTenantId } from "../../../../../hooks/utils.hooks";
import { updateOptionalFeature } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { OptionalFeatureId } from "../../../../../types/api.types";
import { StateSetter } from "../../../../../types/types";
import { IOptionalFeatureModalConfirmProps } from "./ConfirmOptionalFeatureModal";

export default function OptionalFeature({
  featureId,
  isToggleable,
  handleModalConfirm,
  setModalConfirmProps,
  featureName,
}: Readonly<{
  featureId: OptionalFeatureId;
  isToggleable?: boolean;
  handleModalConfirm?: () => Promise<void>;
  setModalConfirmProps: StateSetter<IOptionalFeatureModalConfirmProps | null>;
  featureName: string;
}>) {
  const tenantId = useTenantId();
  const { isFetching: accountInfoFetching } =
    useAccountSetupAccountInfo(tenantId);
  const { data, isFetching, refetch } = useOptionalFeatureStatus(
    featureId,
    tenantId
  );
  const { getInternalApiToken } = useMicrosoftToken();

  const defaultModalConfirm = useCallback(async () => {
    const token = await getInternalApiToken();
    await updateOptionalFeature(
      token,
      tenantId,
      featureId,
      !data?.availableForTenant
    );
  }, [data, featureId, getInternalApiToken, tenantId]);

  return (
    <Skeleton
      isLoaded={
        featureId === "backup:settings"
          ? !isFetching && !accountInfoFetching
          : !isFetching
      }
      _hover={{ cursor: isToggleable ? "pointer" : "no-drop" }}
    >
      <Tag
        colorScheme={data?.availableForTenant ? "green" : "gray"}
        onClick={
          isToggleable
            ? () =>
                setModalConfirmProps({
                  currentlyEnabled: !!data?.availableForTenant,
                  featureId,
                  handleConfirm: handleModalConfirm ?? defaultModalConfirm,
                  refetch: () => refetch({ throwOnError: true }),
                })
            : undefined
        }
      >
        {featureName}
      </Tag>
    </Skeleton>
  );
}
