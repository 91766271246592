import { useEffect, useState } from "react";
import {
  Flex,
  Spacer,
  Text,
  Input,
  Checkbox,
  Button,
  Tag,
} from "@chakra-ui/react";
import GlobalChangeConfirmModal from "./GlobalChangeConfirmModal";
import { useNavigate } from "react-router-dom";
import {
  ChangeManagementRow,
  TenantData,
} from "../../services/atomus-internal-apis/atomus-internal-apis.types";
import useMicrosoftToken, {
  useSharePointToken,
} from "../../hooks/tokens.hooks";
import {
  listTenants,
  postGlobalChangeListItem,
} from "../../services/atomus-internal-apis/atomus-internal-apis.service";

export default function GlobalChangeMgmtBody() {
  const [formError, setFormError] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spAccessToken, setSpAccessToken] = useState<string>("");
  const [tenantList, setTenantList] = useState<TenantData[]>();
  const { getSpToken } = useSharePointToken();
  const { getInternalApiToken } = useMicrosoftToken();
  const navigate = useNavigate();
  const ATOMUS_TENANT_ID = "c9cb3fdc-ac8e-4ef9-98db-0cd8e52392d0";

  // default date to today:
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  // formatting fields for the new change form
  const [formData, setFormData] = useState({
    title: "",
    date: `${year}-${month}-${day}`,
    assigneeId: "",
    approverId: "",
    description: "",
    securityImpactAnalysis: null,
    businessRiskAnalysis: null,
  });

  useEffect(() => {
    const getToken = async () => {
      setSpAccessToken(await getSpToken());
    };
    getToken();
    const fetchAndSetTenants = async () => {
      const token = await getInternalApiToken();
      const tenants = await listTenants(token);
      const nonAtomusTenants = tenants.success.filter(
        (tenant) => tenant.tenantId !== ATOMUS_TENANT_ID
      );
      setTenantList(nonAtomusTenants);
    };
    fetchAndSetTenants();
  }, [getInternalApiToken, getSpToken]);

  useEffect(() => {
    setFormError("");
  }, []);

  const onSubmit = async (formData: ChangeManagementRow) => {
    try {
      if (formData.title.length === 0 || formData.description.length === 0) {
        throw new Error("Title and Description are required");
      } else if (
        formData.title.length > 255 ||
        formData.description.length > 255
      ) {
        throw new Error(
          "Title and description must be no longer than 255 characters each"
        );
      }
      // checking that tenantList is filled
      if (spAccessToken && tenantList) {
        const payload = {
          tenants: tenantList,
          data: formData,
        };
        await postGlobalChangeListItem(spAccessToken, payload);
        navigate("/tenants");
      }
    } catch (error) {
      setFormError(String(error));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: fieldValue }));
  };

  const handleSubmit = () => {
    setIsModalOpen(true);
  };

  return (
    <Flex direction="column">
      <Flex alignItems="center" mt={4}>
        <Text mr={2}>Title:</Text>
        <Input
          name="title"
          value={formData.title}
          onChange={handleChange}
          flex="1"
          placeholder="i.e. Review and assign privileged and non-privileged users separation"
        />
      </Flex>
      <Flex alignItems="center" mt={4}>
        <Text mr={2}>Date:</Text>
        <Input
          name="date"
          value={formData.date}
          onChange={handleChange}
          flex="1"
          type="date"
        />
      </Flex>
      <Flex alignItems="center" mt={4}>
        <Text mr={2}>Description:</Text>
        <Input
          name="description"
          value={formData.description}
          onChange={handleChange}
          flex="1"
          placeholder="i.e. We will work with the company admin to enforce user account separation for privileged and non-privileged users."
        />
      </Flex>
      <Flex alignItems="center" mt={4}>
        <Text mr={2}>Security Impact Analysis:</Text>
        <Checkbox
          name="securityImpactAnalysis"
          checked={formData.securityImpactAnalysis === null ? false : true}
          onChange={handleChange}
        />
      </Flex>
      <Flex alignItems="center" mt={4}>
        <Text mr={2}>Business Risk Analysis:</Text>
        <Checkbox
          name="businessRiskAnalysis"
          checked={formData.businessRiskAnalysis === null ? false : true}
          onChange={handleChange}
        />
      </Flex>
      {formError?.length > 0 && (
        <Flex alignItems="center" mt={4}>
          <Tag colorScheme="red">{formError}</Tag>
        </Flex>
      )}

      <Spacer />
      <Flex mt={6}>
        <Button colorScheme="blue" mr={4} onClick={handleSubmit}>
          Submit
        </Button>
        <Button variant="ghost" onClick={() => navigate("/tenants")}>
          Cancel
        </Button>
      </Flex>
      <GlobalChangeConfirmModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSubmit={onSubmit}
        formData={formData}
      />
    </Flex>
  );
}
