import axios from "axios";

export async function getInvoiceDownloadUrl(
  token: string,
  invoiceDocumentUrl: string
): Promise<string> {
  const reqParams = { headers: { Authorization: token } };
  const locationRes = await axios.post(invoiceDocumentUrl, {}, reqParams);
  if (!locationRes.headers.location) {
    throw new Error("Did not receive download url in headers");
  }
  if (locationRes.headers["retry-after"]) {
    await new Promise((resolve) =>
      setTimeout(resolve, 1000 * parseInt(locationRes.headers["retry-after"]))
    );
  }
  const downloadUrl = await axios.get(locationRes.headers.location, reqParams);
  if (downloadUrl.status === 200) {
    return downloadUrl.data.url as string;
  } else {
    throw new Error(
      "Could not get download link after waiting. Please try again"
    );
  }
}
