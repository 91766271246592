import { Box, Button, Center, Flex, Tag, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  AccountSetupInfoLog,
  StepResult,
} from "../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { AccountSetupContext } from "./AccountSetupContext";

function getTagColorScheme(
  status: StepResult,
  errMsg?: string,
  info?: AccountSetupInfoLog[]
): string {
  if (status === "error" || errMsg) {
    return "red";
  }
  if (info) {
    return "orange";
  }
  return "green";
}

export default function AccountSetupRunResult() {
  const { runResult, setRunResult } = useContext(AccountSetupContext);

  if (!runResult) {
    return <></>;
  }

  return (
    <Flex
      flexDir="column"
      width="100%"
      borderColor="gray.400"
      borderWidth="1px"
      borderRadius="md"
      padding="8px"
      marginY="16px"
      gap="8px"
    >
      <Text fontSize="lg" fontWeight="semibold">
        Account setup result
      </Text>
      {runResult.info.precheck && (
        <Flex flexDir="column" gap="8px">
          <Tag colorScheme="orange" width="fit-content">
            Precheck Info Logs
          </Tag>
          {runResult.info.precheck.map((entry) => (
            <Box key={uuidv4()}>
              {entry.message && <Text>{entry.message}</Text>}
              {entry.data && (
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  {JSON.stringify(entry.data, null, 4)}
                </pre>
              )}
            </Box>
          ))}
        </Flex>
      )}
      <Flex flexDir="column" borderRadius="md" gap="8px">
        {Object.entries(runResult.log).map(([key, value]) => (
          <Flex flexDir="column" gap="8px" key={key}>
            <Flex gap="8px" alignItems="center">
              <Text fontWeight="semibold">{key}:</Text>
              <Tag
                colorScheme={getTagColorScheme(
                  value!.result,
                  runResult.errors[key],
                  runResult.info[key]
                )}
              >
                {value?.result}
              </Tag>
            </Flex>
            <Box marginLeft="8px">
              {runResult.errors[key] && (
                <Text color="red">Error: {runResult.errors[key]}</Text>
              )}
              {runResult.info[key]?.map((entry) => (
                <Box key={uuidv4()}>
                  {entry.message && <Text>{entry.message}</Text>}
                  {entry.data && (
                    <pre style={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(entry.data, null, 4)}
                    </pre>
                  )}
                </Box>
              ))}
              {value!.trace.length > 0 && (
                <Box>
                  <Text>Trace: {value!.trace.join(", ")}</Text>
                </Box>
              )}
            </Box>
          </Flex>
        ))}
      </Flex>
      <Center>
        <Button colorScheme="blue" onClick={() => setRunResult(null)}>
          Close
        </Button>
      </Center>
    </Flex>
  );
}
