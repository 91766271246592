import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Td,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../contexts/AuthContext";
import { useOptionalFeatureStatus } from "../../../../../../hooks/tenants.hooks";
import { AegisUser } from "../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import DeactivateUser from "./DeactivateUser";
import OnboardingEmail from "./OnboardingEmail/OnboardingEmail";
import UserPreferences from "./UserPreferences/UserPreferences";
import CheckrOptions from "./CheckrActions";
import { UserTableContext } from "../../../../UserTableContext";

export default function UserActionsMenu({
  tenantId,
  user,
}: Readonly<{ tenantId: string; user: AegisUser }>) {
  const { data: backupSettingsAvailability } = useOptionalFeatureStatus(
    "backup:settings",
    tenantId
  );
  const { data: vpnFeatureAvailability } = useOptionalFeatureStatus(
    "vpn:settings",
    tenantId
  );
  const { role } = useContext(AuthContext);
  const { checkrConnectionStatus } = useContext(UserTableContext);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (role === "companyAdmin") {
      if (
        !user.active ||
        (!backupSettingsAvailability?.availableForTenant &&
          !vpnFeatureAvailability?.availableForTenant &&
          !checkrConnectionStatus)
      ) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    } else {
      setShowMenu(true);
    }
  }, [
    backupSettingsAvailability?.availableForTenant,
    checkrConnectionStatus,
    role,
    user.active,
    vpnFeatureAvailability?.availableForTenant,
  ]);

  if (!showMenu) {
    return <Td></Td>;
  }

  return (
    <Td>
      <Flex>
        <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <MenuButton
            as={IconButton}
            disabled={isLoading}
            variant="ghost"
            aria-label="Options"
            icon={<HamburgerIcon />}
            onClick={() => setIsOpen(!isOpen)}
          />
          <MenuList>
            {user.active && user.checkrId && (
              <CheckrOptions
                tenantId={tenantId}
                checkrId={user.checkrId}
                setIsLoading={setIsLoading}
              />
            )}

            {user.active && (
              <OnboardingEmail
                email={user.email}
                username={user.username}
                userId={user.id}
              />
            )}
            {user.active &&
              (backupSettingsAvailability?.availableForTenant ||
                vpnFeatureAvailability?.availableForTenant) && (
                <UserPreferences
                  username={user.username}
                  displayName={user.displayName}
                />
              )}
            {role === "securityAdmin" && (
              <DeactivateUser
                tenantId={tenantId}
                user={user}
                setIsLoading={setIsLoading}
              />
            )}
          </MenuList>
        </Menu>
      </Flex>
    </Td>
  );
}
