import {
  Box,
  Flex,
  Spacer,
  Button,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Divider,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { toastInfo } from "../../../Providers/ToastProvider";
import { DEFAULT_USER } from "../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import { createAegisUser } from "../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { AccountSetupContext } from "./AccountSetupContext";
import AccountSetupUsersModal from "./AccountSetupUsersModal";
import LocationTooltip from "./LocationTooltip";
import UserImportModal from "./UserImportModal";

export default function AccountSetupUsersSection({
  tenantId,
}: Readonly<{
  tenantId: string;
}>) {
  const { users, setUsers, accountInfo, stepsAreRunning, runSteps } =
    useContext(AccountSetupContext);
  const [runningUserCreation, setRunningUserCreation] = useState(false);
  const importDisclosure = useDisclosure();
  const usersModalProps = useDisclosure();
  const toast = useToast();

  if (!accountInfo) {
    return <></>;
  }

  const removeUser = (idx: number) => {
    const newArr = [...users];
    newArr.splice(idx, 1);
    setUsers(newArr);
  };

  const handleUserCreationClick = async () => {
    try {
      setRunningUserCreation(true);
      for (const user of users) {
        const dbRes = await createAegisUser(tenantId, user);
        if (!dbRes) {
          toastInfo(
            toast,
            `${user.firstName} ${user.lastName} already exists in database: updating in db and running Azure creation steps`
          );
        }
      }
      await runSteps(false, [
        "createUsers",
        "assignUsersToCompanySubGroup",
        "assignUsersToCompanyGroup",
        "createUserKeyVaults",
        "createDuoUsersIfEnabled",
      ]);
      setRunningUserCreation(false);
    } catch (error) {
      setRunningUserCreation(false);
      throw error;
    }
  };

  const usersOnOpen = () => {
    if (users.length === 0) {
      setUsers([DEFAULT_USER]);
    }
    usersModalProps.onOpen();
  };

  const usersModalOnClose = () => {
    if (
      users.length === 1 &&
      JSON.stringify(users.at(0)) === JSON.stringify(DEFAULT_USER)
    ) {
      setUsers([]);
    }
    usersModalProps.onClose();
  };

  return (
    <>
      <AccountSetupUsersModal
        isOpen={usersModalProps.isOpen}
        onClose={usersModalOnClose}
      />
      <UserImportModal
        disclosure={importDisclosure}
        msftDomain={accountInfo?.companyDomain}
        setUsers={setUsers}
      />
      <Box>
        <Flex alignItems="baseline" gap="8px">
          <Text fontSize="lg" fontWeight="semibold">
            Account setup users
          </Text>
          <Spacer />
          <Button
            size="sm"
            variant="ghost"
            colorScheme="blue"
            onClick={usersOnOpen}
          >
            Add/Edit/Remove Users
          </Button>
          <Button
            size="sm"
            variant="ghost"
            colorScheme="blue"
            onClick={importDisclosure.onOpen}
          >
            Import users from CSV
          </Button>
          <LocationTooltip>
            <Button
              colorScheme="blue"
              variant="ghost"
              size="sm"
              isLoading={runningUserCreation}
              isDisabled={stepsAreRunning || !accountInfo.location}
              onClick={handleUserCreationClick}
            >
              Run all user creation steps
            </Button>
          </LocationTooltip>
        </Flex>

        {users.length > 0 ? (
          <Table marginY={2}>
            <Thead>
              <Tr>
                <Th>User Type</Th>
                <Th>Name</Th>
                <Th>Username</Th>
                <Th>Password</Th>
                <Th>Devices</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user, idx) => (
                <Tr key={user.email}>
                  <Td>{user.userType}</Td>
                  <Td>{`${user.firstName} ${user.lastName}`}</Td>
                  <Td>{user.username}</Td>
                  <Td>{user.msftTempPass}</Td>
                  <Td>{user.devices.join(", ")}</Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="red"
                      variant="ghost"
                      onClick={() => removeUser(idx)}
                    >
                      Remove
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <>
            <Text>None</Text>
            <Divider marginY={2} />
          </>
        )}
      </Box>
    </>
  );
}
