import { useToast } from "@chakra-ui/react";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  useAccountSetupAccountInfo,
  useAccountSetupStatuses,
} from "../../../hooks/tenants.hooks";
import useMicrosoftToken from "../../../hooks/tokens.hooks";
import { toastError, toastSuccess } from "../../../Providers/ToastProvider";
import { runAccountSetup } from "../../../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  AccountInfoWithOptionalLocation,
  AccountSetupResult,
  AccountSetupUser,
} from "../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../../types/types";

export interface IAccountSetupContext {
  accountInfo: AccountInfoWithOptionalLocation | null;
  setAccountInfo: StateSetter<AccountInfoWithOptionalLocation | null>;
  users: AccountSetupUser[];
  setUsers: StateSetter<AccountSetupUser[]>;
  stepsAreRunning: boolean;
  setStepsAreRunning: StateSetter<boolean>;
  runResult: AccountSetupResult | null;
  setRunResult: StateSetter<AccountSetupResult | null>;
  runError: string | null;
  setRunError: StateSetter<string | null>;
  runSteps: (full: boolean, stepIds: string[]) => Promise<void>;
  defenderOnboardingPayload: string | null;
  setDefenderOnboardingPayload: StateSetter<string | null>;
}

const defaultError = () => {
  throw new Error(
    "you must wrap your component in an AccountSetupContextProvider"
  );
};

const defaultValue: IAccountSetupContext = {
  accountInfo: null,
  setAccountInfo: defaultError,
  users: [],
  setUsers: defaultError,
  stepsAreRunning: false,
  setStepsAreRunning: defaultError,
  runResult: null,
  setRunResult: defaultError,
  runError: null,
  setRunError: defaultError,
  runSteps: defaultError,
  defenderOnboardingPayload: null,
  setDefenderOnboardingPayload: defaultError,
};

export const AccountSetupContext = createContext(defaultValue);

export function AccountSetupContextProvider({
  children,
  tenantId,
}: Readonly<{
  tenantId: string;
  children?: ReactNode;
}>) {
  const [accountInfo, setAccountInfo] =
    useState<AccountInfoWithOptionalLocation | null>(null);
  const [users, setUsers] = useState<AccountSetupUser[]>([]);
  const [stepsAreRunning, setStepsAreRunning] = useState(false);
  const [runResult, setRunResult] = useState<AccountSetupResult | null>(null);
  const [runError, setRunError] = useState<string | null>(null);
  const [defenderOnboardingPayload, setDefenderOnboardingPayload] = useState<
    string | null
  >(null);
  const toast = useToast();
  const { data } = useAccountSetupAccountInfo(tenantId);
  const { refetch: refetchStatuses } = useAccountSetupStatuses(tenantId);
  const { refetch: refetchInfo } = useAccountSetupAccountInfo(tenantId);

  const { getInternalApiToken } = useMicrosoftToken();

  useEffect(() => {
    if (data) {
      setAccountInfo(data);
    }
  }, [data]);

  const runSteps = useCallback(
    async (full = false, stepIds: string[] = []): Promise<void> => {
      if (!accountInfo?.location) {
        return;
      }
      if (!full && stepIds.length === 0) {
        return;
      }
      setStepsAreRunning(true);
      try {
        const token = await getInternalApiToken();
        const res = await runAccountSetup(
          token,
          tenantId,
          {
            location: accountInfo.location,
            users,
            defenderOnboardingPayload: defenderOnboardingPayload ?? undefined,
          },
          !full ? stepIds : undefined
        );
        if (res.success) {
          toastSuccess(toast, "Successfully ran account setup steps");
        } else {
          toastError(
            toast,
            "Errors in account setup. See Account setup result section."
          );
        }
        setRunResult(res);
      } catch (error) {
        console.error(error);
        setRunError(
          (error as Error).message ||
            "An unknown error occurred, see the console for more info"
        );
      }
      refetchInfo();
      refetchStatuses();
      setStepsAreRunning(false);
    },
    [
      accountInfo?.location,
      defenderOnboardingPayload,
      getInternalApiToken,
      refetchInfo,
      refetchStatuses,
      tenantId,
      toast,
      users,
    ]
  );

  const contextValue = useMemo(() => {
    return {
      accountInfo,
      setAccountInfo,
      users,
      setUsers,
      stepsAreRunning,
      setStepsAreRunning,
      runResult,
      setRunResult,
      runError,
      setRunError,
      runSteps,
      defenderOnboardingPayload,
      setDefenderOnboardingPayload,
    };
  }, [
    accountInfo,
    setAccountInfo,
    users,
    setUsers,
    stepsAreRunning,
    setStepsAreRunning,
    runResult,
    setRunResult,
    runError,
    setRunError,
    runSteps,
    defenderOnboardingPayload,
    setDefenderOnboardingPayload,
  ]);

  return (
    <AccountSetupContext.Provider value={contextValue}>
      {children}
    </AccountSetupContext.Provider>
  );
}
