import { Tabs, TabList, Tab, TabPanel, TabPanels } from "@chakra-ui/react";
import { useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { UserTableContextProvider } from "../UserTableContext";
import GroupMgmtSection from "./GroupMgmtSection";
import { GroupManagementContextProvider } from "./GroupMgmtSection/GroupMgmtContext";
import UsersTable from "./UserTable";

interface IUsersSectionProps {
  tenantId: string;
}

export default function UsersSection({
  tenantId,
}: Readonly<IUsersSectionProps>) {
  const { role } = useContext(AuthContext);
  return (
    <UserTableContextProvider>
      {role === "companyAdmin" ? (
        <UsersTable tenantId={tenantId} />
      ) : (
        <Tabs variant="enclosed" colorScheme="blue" isLazy>
          <TabList>
            <Tab>Company Users</Tab>
            <Tab>Atomus Users</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <UsersTable tenantId={tenantId} />
            </TabPanel>
            <TabPanel>
              <GroupManagementContextProvider>
                <GroupMgmtSection tenantId={tenantId} />
              </GroupManagementContextProvider>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </UserTableContextProvider>
  );
}
