import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { SortParams } from "../types/utils.types";
import { sortObjectsByConverter, sortObjectsByKey } from "../utils/sorting";

/**
 * @description helps with managing filtering and sorting of data (generally table
 * data)
 * @param data the data (generally the `data` property from a react-query query)
 * @param converters converters can be specified for keys who's values to not
 * natively sort using `toString` (i.e. objects where they would all be [object Object]
 * when `toString` was called). They should output a string that will be used to apply
 * the sort
 * @returns object containing:
 *
 * `activeItems` - the items returned from `data` once the current
 * sorting has been applied
 *
 * `sortDir` - the current sort direction
 *
 * `sortKey` - the current sort key
 *
 * `handleSort` - function to sort on a new sort key or update the sort direction
 * if the current sort key is supplied for `newKey`
 */
export function useDataSorting<T extends Record<string, any>>(
  data: T[] | undefined,
  converters: Partial<Record<keyof T, (item: T) => string>> = {}
) {
  const [activeItems, setActiveItems] = useState<T[]>(data ? [...data] : []);
  const [sortParams, setSortParams] = useState<SortParams<T>>({
    sortDir: "asc",
    sortKey: null,
  });

  useEffect(() => {
    setSortParams({ sortDir: "asc", sortKey: null });
    setActiveItems(data ? [...data] : []);
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const { sortDir, sortKey } = sortParams;
    if (sortKey) {
      const converter = converters[sortKey];
      if (converter) {
        setActiveItems(sortObjectsByConverter(data, sortDir, converter));
      } else {
        setActiveItems(sortObjectsByKey(data, sortKey, sortDir));
      }
    }
  }, [data, sortParams, converters]);

  const handleSort = useCallback(
    (newKey: keyof T) => {
      const newSortDir =
        newKey === sortParams.sortKey && sortParams.sortDir === "asc"
          ? "desc"
          : "asc";
      setSortParams({ sortDir: newSortDir, sortKey: newKey });
    },
    [sortParams.sortDir, sortParams.sortKey]
  );

  return {
    activeItems,
    sortDir: sortParams.sortDir,
    sortKey: sortParams.sortKey,
    handleSort,
  } as const;
}

export function useTenantId(): string {
  const tenantId = useParams().tenantId;
  if (!tenantId) {
    throw new Error("unable to get tenant id from url params");
  }
  return tenantId;
}
