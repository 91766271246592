import { Tr, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { AegisUser } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import UserAzureInfo from "./UserAzureInfo";
import UserBasicInfo from "./UserBasicInfo";
import UserComplianceInfo from "./UserComplianceInfo";
import UserActionsMenu from "./UserActionsMenu/UserActionsMenu";
import UserDeviceMfaInfo from "./UserDeviceMfaInfo";
import UserEmailInfo from "./UserEmailInfo";
import { UserTypes } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import UserNoAzureInfo from "./UserNoAzureInfo";

export function UserRow({
  aegisUser,
  tenantId,
}: Readonly<{
  aegisUser: AegisUser;
  tenantId: string;
}>) {
  const [azureInfoError, setAzureInfoError] = useState<Error | null>(null);

  const toast = useToast();

  return (
    <Tr key={aegisUser.id}>
      <UserBasicInfo
        aegisUser={aegisUser}
        error={azureInfoError}
        toast={toast}
      />
      {aegisUser.active && aegisUser.userType !== UserTypes.OFFLINE ? (
        <UserAzureInfo
          cloudId={aegisUser.id}
          setError={setAzureInfoError}
          tenantId={tenantId}
        />
      ) : (
        <UserNoAzureInfo />
      )}
      <UserComplianceInfo aegisUser={aegisUser} />
      <UserDeviceMfaInfo tenantId={tenantId} username={aegisUser.username} />
      <UserEmailInfo aegisUser={aegisUser} toast={toast} tenantId={tenantId} />
      <UserActionsMenu user={aegisUser} tenantId={tenantId} />
    </Tr>
  );
}
