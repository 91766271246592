import { AzureCloudInstance, Configuration } from "@azure/msal-browser";

const REDIRECT_URI =
  process.env.REACT_APP_VERCEL_REDIRECT_URI ||
  "http://localhost:3000/msal-redirect";

const CACHE_CONFIG = {
  cacheLocation: "sessionStorage", // This configures where your cache will be stored
  storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
};

export const AegisClientIds = {
  commercial: {
    app: "3532456c-c2b8-493a-bda0-a39630b13b42",
    api: "b2efaee4-0a98-4643-b207-f3d48fd0e7d8",
  },
  gov: {
    app: "592d9165-5a49-4672-ab0e-170a54ab6a8f",
    api: "f20360f2-ba74-46b5-9f3c-03420432d1fb",
  },
} as const;

const SharepointClientId = "b54cf9e1-2771-4894-9904-7a100e7a334f";
export const SharePointAuthConfig: Configuration = {
  auth: {
    clientId: SharepointClientId,
    authority: "https://login.microsoftonline.us/common/",
    redirectUri: REDIRECT_URI,
    azureCloudOptions: {
      azureCloudInstance: AzureCloudInstance.AzureUsGovernment,
    },
  },
  cache: CACHE_CONFIG,
};

export const MsalAuthConfigs: Record<"commercial" | "gov", Configuration> = {
  commercial: {
    auth: {
      clientId: AegisClientIds.commercial.app,
      authority: "https://login.microsoftonline.com/organizations",
      redirectUri: REDIRECT_URI,
      azureCloudOptions: { azureCloudInstance: AzureCloudInstance.AzurePublic },
    },
    cache: CACHE_CONFIG,
  },
  gov: {
    auth: {
      clientId: AegisClientIds.gov.app,
      authority: "https://login.microsoftonline.us/organizations",
      redirectUri: REDIRECT_URI,
      azureCloudOptions: {
        azureCloudInstance: AzureCloudInstance.AzureUsGovernment,
      },
    },
    cache: CACHE_CONFIG,
  },
} as const;

export const MsalEnvironments = {
  COMMERCIAL: "login.windows.net",
  GOV: "login.microsoftonline.us",
} as const;
