import { Flex, HStack, Stat, StatLabel, Tag } from "@chakra-ui/react";
import InfoField from "../../../../Components/InfoField";
import { Device } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";

interface IDetailsHeaderProps {
  device: Device;
}

export default function StatSection({ device }: Readonly<IDetailsHeaderProps>) {
  return (
    <Flex flexDir="column" gap="12px">
      <HStack alignItems="flex-start">
        <Stat>
          <StatLabel>Operating System</StatLabel>
          <InfoField data={device.operatingSystem} />
        </Stat>
        {device.operatingSystem !== "Linux" && (
          <>
            <Stat>
              <StatLabel>Compliant</StatLabel>
              <Tag
                colorScheme={device.isCompliant ? "green" : "red"}
              >{`${device.isCompliant}`}</Tag>
            </Stat>

            <Stat>
              <StatLabel>Enrollment Type</StatLabel>
              <InfoField data={device.enrollmentType} />
            </Stat>

            <Stat>
              <StatLabel>Management Type</StatLabel>
              <InfoField data={device.managementType} />
            </Stat>
          </>
        )}
      </HStack>
    </Flex>
  );
}
