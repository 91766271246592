import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Center, IconButton, Text } from "@chakra-ui/react";
import { useCallback } from "react";

interface IPageSelectorProps {
  logCount: number;
  numResults: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  queriesLoading: boolean;
}

export default function PageSelector({
  currentPage,
  setCurrentPage,
  logCount,
  numResults,
  queriesLoading,
}: IPageSelectorProps) {
  const changePage = useCallback(
    (amount: number) => setCurrentPage(currentPage + amount),
    [setCurrentPage, currentPage]
  );

  return (
    <Center gap={3}>
      <IconButton
        size="sm"
        aria-label="previous-page"
        icon={<ChevronLeftIcon />}
        colorScheme="blue"
        isDisabled={queriesLoading || currentPage === 1}
        onClick={() => changePage(-1)}
      />
      <Text>
        Page {currentPage} of {Math.ceil(logCount / numResults)}
      </Text>
      <IconButton
        size="sm"
        aria-label="previous-page"
        icon={<ChevronRightIcon />}
        colorScheme="blue"
        isDisabled={queriesLoading || currentPage * numResults >= logCount}
        onClick={() => changePage(1)}
      />
    </Center>
  );
}
