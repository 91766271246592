import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import MultiOperationForm from "./MultiOperationForm";
import SingleOperationForm from "./SingleOperationForm";

interface IRegKeyOperationModalProps {
  isBulk: boolean;
  isOpen: boolean;
  onClose: () => void;
  tenantId: string
}

export default function RegKeyOperationModal({
  isBulk,
  isOpen,
  onClose,
  tenantId
}: IRegKeyOperationModalProps) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isBulk ? "Add key multiple key operations" : "Add key operation"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!isBulk ? (
              <SingleOperationForm tenantId={tenantId} onClose={onClose} />
            ) : (
              <MultiOperationForm tenantId={tenantId} onClose={onClose} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
