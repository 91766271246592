import { Box, Checkbox, Tooltip } from "@chakra-ui/react";
import { OnboardingCheckResult } from "../../../../../../../types/api.types";
import { StateSetter } from "../../../../../../../types/types";
import { OnboardingOption } from "./types";
import { getDisabledReasons } from "./utils";

export default function OnboardingOptionCheckbox({
  selectedOptions,
  setSelectedOptions,
  onboardingOption,
  description,
  onboardingCheckResult,
}: Readonly<{
  selectedOptions: Record<OnboardingOption, boolean>;
  setSelectedOptions: StateSetter<Record<OnboardingOption, boolean>>;
  onboardingOption: OnboardingOption;
  description: string;
  onboardingCheckResult: OnboardingCheckResult;
}>) {
  const disabledReasons = getDisabledReasons(
    onboardingOption,
    onboardingCheckResult
  );
  return (
    <Tooltip isDisabled={!disabledReasons} label={disabledReasons}>
      <Box>
        <Checkbox
          disabled={Boolean(disabledReasons)}
          isChecked={selectedOptions[onboardingOption]}
          onChange={() =>
            setSelectedOptions((prev) => ({
              ...prev,
              [onboardingOption]: !prev[onboardingOption],
            }))
          }
        >
          {description}
        </Checkbox>
      </Box>
    </Tooltip>
  );
}
