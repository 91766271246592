import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { useOptionalFeatureStatus } from "../../../../hooks/tenants.hooks";
import { useTenantId } from "../../../../hooks/utils.hooks";
import BackupSection from "./BackupSection";
import VpnSection from "./VpnSection";

export default function PreferencesModal({
  disclosure: { isOpen, onClose },
}: Readonly<{
  disclosure: UseDisclosureReturn;
}>) {
  const tenantId = useTenantId();
  const { data: backupSettingsAvailability } = useOptionalFeatureStatus(
    "backup:settings",
    tenantId
  );
  const { data: vpnSettingsAvailability } = useOptionalFeatureStatus(
    "vpn:settings",
    tenantId
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent padding="12px">
        <ModalCloseButton />
        <ModalBody>
          <Heading size="lg" fontWeight="semibold">
            Company preferences
          </Heading>
          <Flex flexDir="column" gap="12px" marginY="12px">
            {backupSettingsAvailability?.availableForTenant && (
              <BackupSection />
            )}
            {vpnSettingsAvailability?.availableForTenant && <VpnSection />}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
