import { useMsal } from "@azure/msal-react";
import { Box, Button, Center, Text, VStack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import useMicrosoftToken from "../../hooks/tokens.hooks";
import { getDashboardRole } from "../../services/atomus-internal-apis/atomus-internal-apis.service";
import { identifyUser } from "../../services/atomus-internal-apis/logging/logging.service";

export default function LoginPage() {
  const [switchInProgress, setSwitchInProgress] = useState(false);
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { isGov, setIsGov, setRole } = useContext(AuthContext);
  const { getInternalApiToken } = useMicrosoftToken();

  const { instance } = useMsal();
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoginInProgress(true);
    setErrorMsg("");
    try {
      const res = await instance.loginPopup();
      if (!res.account?.username) {
        throw new Error("Unable to get account from authentication result");
      }

      const userRole = await getDashboardRole(res.account.username);
      setRole(userRole);

      if (userRole === "none") {
        instance.setActiveAccount(null);
        setErrorMsg("User does not have access.");
        instance.setActiveAccount(null);
      } else if (userRole === "companyAdmin") {
        instance.setActiveAccount(res.account);
        const { tenantId, username } = res.account;
        void getInternalApiToken()
          .then((token) => identifyUser(token, tenantId, username))
          .catch();
        navigate(`/tenants/${res.account.tenantId}`);
      } else {
        instance.setActiveAccount(res.account);
        navigate("/tenants");
      }
    } catch (error) {
      instance.setActiveAccount(null);
      setErrorMsg((error as Error).message);
    }
    setLoginInProgress(false);
  };

  const handleSwitchEnv = () => {
    setSwitchInProgress(true);
    setIsGov(!isGov);
  };

  useEffect(() => {
    if (switchInProgress) {
      setSwitchInProgress(false);
    }
  }, [instance, switchInProgress]);

  useEffect(() => {
    instance.setActiveAccount(null);
  }, [instance]);

  return (
    <Box height="100vh">
      <Center marginY={4} marginTop="30vh">
        <VStack spacing={4} align="stretch">
          <Text fontSize="xl">Login to Atomus Security Dashboard</Text>
          <Button
            onClick={handleSwitchEnv}
            colorScheme="blue"
            isDisabled={switchInProgress}
            isLoading={loginInProgress}
          >
            Switch environment
          </Button>
          <Button
            onClick={handleLogin}
            colorScheme="blue"
            isDisabled={switchInProgress}
            isLoading={loginInProgress}
          >
            Login to {isGov ? "gov" : "commercial"}
          </Button>
        </VStack>
      </Center>
      {errorMsg && (
        <Center>
          <Text color="red">{errorMsg}</Text>
        </Center>
      )}
    </Box>
  );
}
