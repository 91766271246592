import { Skeleton, Tag, Td, Tr } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import TooltipTag from "../../../../Components/TooltipTag/TooltipTag";
import { useDeviceHealthcheck } from "../../../../hooks/devices.hooks";
import { useTracking } from "../../../../hooks/tracking.hooks";
import { NOT_APPLICABLE_STR } from "../../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import { Device } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import AegisVersionValue from "./AegisVersionValue";
import AppRecommendationsValue from "./AppRecommendationsValue";
import LastBackupValue from "./LastBackupValue";

interface IDeviceRowProps {
  device: Device;
  tenantId: string;
  modalOnOpen: () => void;
  setModalDeviceId: React.Dispatch<React.SetStateAction<string | null>>;
  mobileSection?: boolean;
  defenderIsActive?: boolean;
  showBackupColumn: boolean;
}
export default function DeviceRow({
  device,
  tenantId,
  modalOnOpen,
  setModalDeviceId,
  mobileSection,
  defenderIsActive,
  showBackupColumn,
}: Readonly<IDeviceRowProps>) {
  const healthcheckQuery = useDeviceHealthcheck(
    tenantId,
    device.id,
    device.displayName
  );

  const [healthcheckStr, setHealthcheckStr] = useState("0/0");
  const [healthcheckSuccess, setHealthcheckSuccess] = useState(false);

  const { trackDeviceClick } = useTracking();

  useEffect(() => {
    if (healthcheckQuery.data) {
      let numSuccess = 0;
      let total = 0;
      for (const script of healthcheckQuery.data) {
        if (script.status_s === "info") {
          continue;
        } else if (script.status_s === "success") {
          numSuccess++;
        }
        total++;
      }
      setHealthcheckStr(total > 0 ? `${numSuccess}/${total}` : "Not found");
      setHealthcheckSuccess(total > 0 && numSuccess === total);
    } else {
      setHealthcheckStr("Not found");
      setHealthcheckSuccess(false);
    }
  }, [healthcheckQuery.data]);

  const handleRowClick = useCallback(() => {
    setModalDeviceId(device.id);
    modalOnOpen();
    let deviceUsers = "";
    if (device.registeredUsers)
      deviceUsers = device.registeredUsers
        .map((user) => user.userPrincipalName)
        .join(", ");

    trackDeviceClick(device.displayName, deviceUsers);
  }, [device, modalOnOpen, setModalDeviceId, trackDeviceClick]);

  return (
    <Tr
      _hover={{ cursor: "pointer", background: "blackAlpha.200" }}
      onClick={handleRowClick}
    >
      <Td>{device.displayName}</Td>
      <Td>
        {device.registeredUsers
          ? device.registeredUsers
              .map((user) => user.userPrincipalName.toLowerCase())
              .join(", ")
              .toString()
          : ""}
      </Td>
      <Td>
        <Tag
          colorScheme={
            device.enrollmentType !== null
              ? device.enrollmentType === NOT_APPLICABLE_STR
                ? "gray"
                : "green"
              : "red"
          }
        >
          {`${device.enrollmentType}`}
        </Tag>
      </Td>
      {!mobileSection && (
        <Td>
          <AegisVersionValue
            deviceId={device.id}
            deviceName={device.displayName}
            platform={device.operatingSystem}
            tenantId={tenantId}
          />
        </Td>
      )}
      {!mobileSection && (
        <Td>
          <Skeleton isLoaded={!healthcheckQuery.isLoading}>
            <Tag
              colorScheme={
                healthcheckQuery.isError || !healthcheckSuccess
                  ? "red"
                  : "green"
              }
            >
              {healthcheckQuery.isError ? "Error" : healthcheckStr}
            </Tag>
          </Skeleton>
        </Td>
      )}
      {!mobileSection && showBackupColumn && (
        <Td>
          <LastBackupValue
            deviceId={device.id}
            deviceName={device.displayName}
            tenantId={tenantId}
          />
        </Td>
      )}
      {!mobileSection &&
        defenderIsActive &&
        (device.deviceId ? (
          <AppRecommendationsValue
            tenantId={tenantId}
            aadDeviceId={device.deviceId ?? null}
          />
        ) : (
          <Td>
            <TooltipTag
              tagText="Not applicable"
              tooltipText="Device is not joined to Entra AD"
              tagColorScheme="gray"
            />
          </Td>
        ))}
    </Tr>
  );
}
