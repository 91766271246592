import { InfoIcon } from "@chakra-ui/icons";
import {
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Progress,
  Center,
  Button,
  useToast,
  Icon,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";

import {
  useAtomusGroupUsers,
  useGroupRoles,
} from "../../../../../hooks/groups.hooks";
import useMicrosoftToken from "../../../../../hooks/tokens.hooks";
import { toastError } from "../../../../../Providers/ToastProvider";
import { removeGroupUser } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { ExternalAzureUserWithId } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { GroupMgmtContext } from "../GroupMgmtContext";

interface IGroupSectionProps {
  name: string;
  tenantId: string;
  groupId: string;
  assignedUsers: ExternalAzureUserWithId[];
  setAssignedUsers: React.Dispatch<
    React.SetStateAction<ExternalAzureUserWithId[]>
  >;
}

export default function GroupSection({
  name,
  tenantId,
  groupId,
  assignedUsers,
  setAssignedUsers,
}: IGroupSectionProps) {
  const { buttonsLoading, setButtonsLoading, setUnassignedUsers } =
    useContext(GroupMgmtContext);
  const usersQueryResult = useAtomusGroupUsers(tenantId, groupId);
  const rolesQueryResult = useGroupRoles(tenantId, groupId);
  const { getInternalApiToken } = useMicrosoftToken();
  const toast = useToast();

  useEffect(() => {
    if (usersQueryResult.data && usersQueryResult.isFetchedAfterMount) {
      setAssignedUsers(usersQueryResult.data);
    }
  }, [
    usersQueryResult.data,
    usersQueryResult.isFetchedAfterMount,
    setAssignedUsers,
  ]);

  const handleRemoveUser = async (user: ExternalAzureUserWithId) => {
    try {
      setButtonsLoading(true);
      const token = await getInternalApiToken();
      await removeGroupUser(token, tenantId, groupId, user.id);
      setAssignedUsers((prevUsers) =>
        prevUsers.filter((unassignedUser) => unassignedUser.id !== user.id)
      );
      setUnassignedUsers((prevUsers) => [...prevUsers, user]);
      setButtonsLoading(false);
    } catch (err) {
      setButtonsLoading(false);
      toastError(toast, err);
    }
  };

  if (usersQueryResult?.isFetching) {
    return <Progress margin={2} isIndeterminate borderRadius="md" />;
  }

  if (usersQueryResult?.isError) {
    return <Center>{usersQueryResult.error.message}</Center>;
  }

  return (
    <>
      <Flex flexDir="row">
        <Text
          fontSize="xl"
          fontWeight="semibold"
          marginTop={5}
          marginBottom={4}
        >
          {name}
        </Text>
        <Flex
          margin={5}
          alignItems="center"
          cursor={!rolesQueryResult.isLoading ? "pointer" : undefined}
          alignSelf="center"
        >
          {" "}
          <Tooltip
            as={Text}
            isDisabled={rolesQueryResult.isLoading}
            label={`AAD Roles: ${rolesQueryResult.data?.aadRoles.join(", ")}.
            ARM Roles: ${rolesQueryResult.data?.armRoles.join(", ")}.`}
          >
            <Icon
              as={InfoIcon}
              color={rolesQueryResult.isLoading ? "gray" : "blue.400"}
              marginTop={2}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Table
        variant="simple"
        marginBottom={6}
        __css={{ "table-layout": "fixed", width: "100%" }}
      >
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>UPN</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {assignedUsers.map((user) => (
            <Tr key={user.id}>
              <Td>{user.displayName}</Td>
              <Td>
                <Text
                  as="span"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  display="inline-block"
                  maxWidth="400px"
                >
                  {user.userPrincipalName}
                </Text>
              </Td>
              <Td>
                <Button
                  width={20}
                  colorScheme="red"
                  variant="ghost"
                  size="sm"
                  onClick={() => handleRemoveUser(user)}
                  isLoading={buttonsLoading}
                  isDisabled={buttonsLoading}
                >
                  Remove
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}
