import { createContext, ReactNode, useState, useMemo } from "react";
import { ExternalAzureUserWithId } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../../../types/types";

export interface IGroupMgmtContext {
  adminGroupUsers: ExternalAzureUserWithId[];
  setAdminGroupUsers: StateSetter<ExternalAzureUserWithId[]>;
  readerGroupUsers: ExternalAzureUserWithId[];
  setReaderGroupUsers: StateSetter<ExternalAzureUserWithId[]>;
  unassignedUsers: ExternalAzureUserWithId[];
  setUnassignedUsers: StateSetter<ExternalAzureUserWithId[]>;
  buttonsLoading: boolean;
  setButtonsLoading: StateSetter<boolean>;
}

const defaultError = () => {
  throw new Error(
    "you must wrap your component in an GroupManagementContextProvider"
  );
};

const defaultValue: IGroupMgmtContext = {
  adminGroupUsers: [],
  setAdminGroupUsers: defaultError,
  readerGroupUsers: [],
  setReaderGroupUsers: defaultError,
  unassignedUsers: [],
  setUnassignedUsers: defaultError,
  buttonsLoading: false,
  setButtonsLoading: defaultError,
};

export const GroupMgmtContext = createContext(defaultValue);

export const GroupManagementContextProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [adminGroupUsers, setAdminGroupUsers] = useState<
    ExternalAzureUserWithId[]
  >([]);
  const [readerGroupUsers, setReaderGroupUsers] = useState<
    ExternalAzureUserWithId[]
  >([]);
  const [unassignedUsers, setUnassignedUsers] = useState<
    ExternalAzureUserWithId[]
  >([]);
  const [buttonsLoading, setButtonsLoading] = useState<boolean>(false);

  const contextValue = useMemo(() => {
    return {
      adminGroupUsers,
      setAdminGroupUsers,
      readerGroupUsers,
      setReaderGroupUsers,
      unassignedUsers,
      setUnassignedUsers,
      buttonsLoading,
      setButtonsLoading,
    };
  }, [
    adminGroupUsers,
    setAdminGroupUsers,
    readerGroupUsers,
    setReaderGroupUsers,
    unassignedUsers,
    setUnassignedUsers,
    buttonsLoading,
    setButtonsLoading,
  ]);

  return (
    <GroupMgmtContext.Provider value={contextValue}>
      {children}
    </GroupMgmtContext.Provider>
  );
};
