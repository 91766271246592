import { Box, Link, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { AegisClientIds } from "../../../constants/auth.constants";
import { MsBaseUrls } from "../../../constants/microsoft.constants";
import AuthContext from "../../../contexts/AuthContext";

interface IAdminConsentSectionProps {
  tenantId: string;
}

const openLink = (link: string) => {
  window.open(link, "_blank");
};

export default function AdminConsentSection({
  tenantId,
}: IAdminConsentSectionProps) {
  const { isGov } = useContext(AuthContext);
  const baseUrl = isGov ? MsBaseUrls.gov.LOGIN : MsBaseUrls.commercial.LOGIN;
  const clientIds = isGov ? AegisClientIds.gov : AegisClientIds.commercial;
  const consentLinks = [
    {
      app: "API",
      link: `${baseUrl}/${tenantId}/adminconsent?client_id=${clientIds.api}`,
    },
    {
      app: "Webapp",
      link: `${baseUrl}/${tenantId}/adminconsent?client_id=${clientIds.app}`,
    },
  ];
  return (
    <>
      <Text fontSize="large" color="red">
        Admin consent required:
      </Text>
      <Box>
        {consentLinks.map((linkSpec) => (
          <>
            <Link onClick={() => openLink(linkSpec.link)} key={linkSpec.app}>
              Grant for {linkSpec.app}
            </Link>
            <br />
          </>
        ))}
      </Box>
    </>
  );
}
