import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { DeviceAction } from "./device-header.types";

function descriptionText(action: DeviceAction): string {
  switch (action) {
    case "delete":
      return "Deleting the device will completely remove it from Microsoft and prevent users from signing in.";
    case "disable":
      return "Disabling the device will prevent users from accessing the device.";
    case "enable":
      return "Enabling the device will allow users access the device.";
  }
}

export default function ConfirmationModal({
  action,
  disclosure: { isOpen, onClose },
  onConfirm,
}: Readonly<{
  action: DeviceAction | null;
  onConfirm: (action: DeviceAction) => Promise<void>;
  disclosure: UseDisclosureReturn;
}>) {
  if (!action) {
    onClose();
    return <></>;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody padding="8px">
          <Flex flexDir="column" gap="16px">
            <Heading size="md" fontWeight="semibold">
              Are you sure?
            </Heading>
            <Text>{descriptionText(action)}</Text>
            <Flex flexDir="row-reverse" gap="12px">
              <Button
                size="sm"
                colorScheme="red"
                variant="outline"
                leftIcon={<BsCheckLg />}
                onClick={() => {
                  onConfirm(action);
                  onClose();
                }}
              >
                Confirm
              </Button>
              <Button
                size="sm"
                variant="outline"
                colorScheme="blue"
                leftIcon={<BsXLg />}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
