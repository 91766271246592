import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { useState } from "react";
import { useParseUsersCsv } from "../../../hooks/account-setup.hooks";
import { AccountSetupUser } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../../types/types";

export default function UserImportModal({
  disclosure: { isOpen, onClose },
  msftDomain,
  setUsers,
}: Readonly<{
  disclosure: UseDisclosureReturn;
  msftDomain: string;
  setUsers: StateSetter<AccountSetupUser[]>;
}>) {
  const [skipTempPass, setSkipTempPass] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const { parseUsers } = useParseUsersCsv(msftDomain, skipTempPass);

  const handleClose = () => {
    setFile(null);
    setSkipTempPass(false);
    onClose();
  };

  const handleSubmit = async () => {
    if (!file) {
      // should never happen
      return;
    }
    try {
      const newUsers = await parseUsers(file);
      setUsers((prev) => [...prev, ...newUsers]);
      handleClose();
    } catch (error) {
      setFile(null);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent padding="8px">
        <ModalHeader>Import Users CSV</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <input
              type="file"
              accept=".csv"
              onChange={(e) => setFile(e.target.files?.item(0) ?? null)}
            />
          </Box>
          <Flex alignItems="center" gap="8px" marginTop="12px">
            <Checkbox
              checked={skipTempPass}
              onChange={() => setSkipTempPass((prev) => !prev)}
            />
            <Text>Don't create temporary passwords</Text>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup gap="8px">
            <Button colorScheme="gray" variant="ghost" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isDisabled={!file}
            >
              Submit
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
